import { createAction } from 'redux-actions';
import { getReq } from '../api';

export namespace OpenIssuesActions {
  export enum Type {
    CHECK_OPEN_ISSUES = 'CHECK_OPEN_ISSUES',
    GET_OPEN_ISSUES = 'GET_OPEN_ISSUES',
    LOAD_ISSUES = 'LOAD_ISSUES',
  }
  
  const checkOpenIssuesAction = createAction(Type.CHECK_OPEN_ISSUES, (data: any) => ({data}));
  const loadOpenIssuesAction = createAction(Type.LOAD_ISSUES, (data:any)=>({data}));
  const getOpenIssuesAction = createAction(Type.GET_OPEN_ISSUES, (data: any) => ({data}));

  export const checkOpenIssues = (application: string) => async(dispatch, getState, api) => {
    dispatch(loadOpenIssuesAction(false));

    try {

      let result = await getReq(`openIssues/checkOpenIssues/${application}`, '');
      dispatch(checkOpenIssuesAction(result));
      return result;

    } catch (err) {

      console.log("Error occurred", err);
      return false;
    }
  }

  export const fetchOpenIssues = (application: string) => async(dispatch: Function) => {
    dispatch(loadOpenIssuesAction(false));
    try {
      let res = await getReq(`openIssues/fetchOpenIssues/${application}`, '');
      dispatch(getOpenIssuesAction(res))
      return res;
    } catch (err) {
      console.log("Error occurred", err);
      return [];
    }
  }
}

export type OpenIssuesActions = Omit<typeof OpenIssuesActions, 'Type'>;
