import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Menu, Dropdown, Image, Icon } from 'semantic-ui-react';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../reducers';
import { UserActions, AlertActions } from '../../actions';
import { omit } from '../../utils';
import { DefaultSetupModal } from './defaultSetupModal';
import '../../styles/css/style.css';


import { FeedbackModal } from '../../components/Header/FeedbackModal';
import Loading from '../../components/Loading';
const moment = require('moment');

export namespace Header {
  export interface Props extends RouteComponentProps<void> {
    auth: RootState.AuthState;
    actions: UserActions;
    usages: RootState.UsageState;
    feedbackModal: boolean;
    hideFeedbackModal: any
  }

  export interface State {
    defaultSetupModal: boolean,
    practice: [],
    region: [],
    department: [],
    brand:[],
    trigged: boolean,
    feedBackForm : any,
    mycdp_uri: any,
    loading: boolean
  }
}

@(connect(
  (state: RootState, ownProps): Pick<Header.Props, any> => {
    return { auth: state.auth, usages: state.usages };
  },
  (dispatch: Dispatch): Pick<Header.Props, 'actions'> => {
    return ({
      actions: bindActionCreators(omit({ ...UserActions, ...AlertActions }, 'Type'), dispatch)
    });
  }
) as any)

export class Header extends React.Component<Header.Props, Header.State> {
  static defaultProps: Partial<Header.Props> = {};

  constructor(props: Header.Props, context?: any) {
    super(props, context);
    this.state = {
      defaultSetupModal: false,
      region:[],
      practice: [],
      department: [],
      brand:[],
      trigged: false,
      feedBackForm : {} as any,
      mycdp_uri: '',
      loading: false
    };
  }

  componentDidMount = async () => {
      let filters: any = {};
      if(!this.props.auth) return;
      const res:any = await this.props.actions.getDentalPartners(this.props.auth.id);
      if(res && res.partners_uri){
        this.setState({mycdp_uri:res.partners_uri})
      }
      if (this.props.auth && Object.keys(this.props.auth).length && this.props.auth.AlertSetup && this.props.auth.AlertSetup.id) {
        if(!this.props.auth.AlertSetup.regions) {
          this.props.auth.AlertSetup.regions = 'all'
        }
        filters = {
          practice: this.props.auth.AlertSetup.practices.split(','),
        //  region: this.props.auth.AlertSetup.regions.split(','),
          department: this.props.auth.AlertSetup.departments.split(',').map(Number),
          brand: this.props.auth.AlertSetup.brands.split(','),
          trigged: this.props.auth.AlertSetup.triggedAlert
        };
        this.setState(filters);
      }

    }


  hideDefaultSetupModal = () => {
    this.setState({ defaultSetupModal: false });
  };

  openDefaultSetup = () => {
    this.setState({ defaultSetupModal: true });
  };

  onChangeFeedback = (feedBackForm: any) => {
    this.setState({ feedBackForm });
  };


  onClickFeedbackPage = async () => {
    const { auth } = this.props;
    const { feedBackForm } = this.state;
    const feedback = {
      subject: feedBackForm.subject,
      message: feedBackForm.message,
      firstName: auth.firstname,
      lastName: auth.lastname,
      email: auth.email,
      practice: feedBackForm.practice,
      file: feedBackForm.file,
      dateTime: moment().format('MM/DD/YYYY hh:mm A')
    };
    if (feedBackForm.subject && feedBackForm.message) {
      this.props.hideFeedbackModal();
      await this.props.actions.sendFeedback(feedback);
      this.hideDefaultSetupModal();
      alert('Feedback form submitted successfully.');
    } else {
      alert('Please enter subject and message.');
    }
  };

  onChangeDefaultSetup = (e, { value, name, checked }) => {

    let brandFiltered: any = [{key:0, text:'All', value:'All'}];
    
    let state: any = this.state;
    if (name == 'trigged') {
      state[name] = checked;
    } else {
      state[name] = value;
      if ((state[name][0] === 'all' || state[name][0] === 'All' || state[name][0] === 0) && state[name].length > 1) {
        state[name].splice(0, 1);
        if(state[name]==='practice'){
          this.props.usages.brands.brands = this.props.usages.brands.brandFilterData;
        }else if(state[name]==='brand'){
          this.props.usages.practices.practices  = this.props.usages.practices.practiceFilterData;
        }
      } else if (state[name].length > 1 && (state[name][state[name].length - 1] === 'all' || state[name][state[name].length - 1] === 'All'|| state[name][state[name].length - 1] === 0)) {
        state[name].splice(0, state[name].length - 1);
        if(state[name]==='practice'){
          this.props.usages.brands.brands = this.props.usages.brands.brandFilterData;
        }else if(state[name]==='brand'){
          this.props.usages.practices.practices  = this.props.usages.practices.practiceFilterData;
        }
      } else if (state[name].length === 0) {
        if (name === 'practice') {
          state[name].push('all');
          this.props.usages.brands.brands = this.props.usages.brands.brandFilterData; 
        } else if(name === 'brand'){
          state[name].push('All');
          this.props.usages.practices.practices  = this.props.usages.practices.practiceFilterData;
        } else {
          state[name].push(0);
        }
      }
    }

    if(name == 'brand' && !state[name].includes('All')){

      this.props.usages.practices.practices  = this.props.usages.practices.practiceFilterData;

      this.props.usages.practices.practices  = this.props.usages.practices.practices.filter(function (element:any) {        
        return state[name].includes(element.brand); 
      });

    }

    if(name == 'region' && !state[name].includes('all')){

      this.props.usages.practices.practices  = this.props.usages.practices.practiceFilterData;

      this.props.usages.practices.practices  = this.props.usages.practices.practices.filter(function (element:any) {        
        return state[name].includes(element.region); 
      });

    }

    if(name == 'practice' && !state[name].includes('all')) {

      this.props.usages.brands.brands  = this.props.usages.brands.brandFilterData;    

      this.props.usages.practices.practices.forEach(element=>{

        state[name].forEach(filter=>{
          if(filter == element.value && !this.checkExistsBrand(brandFiltered,element.value)) {
              brandFiltered.push({
                key  :  element.brand,
                text : element.brand,
                value: element.brand
              });   

            this.props.usages.brands.brands = brandFiltered;

          }

        })
      })
    }

    if(!this.checkExistsPractice(this.props.usages.practices.practices,'all')){
      this.props.usages.practices.practices.push({'key':0,'text': 'All' ,'value': 'all'});
    }
    this.setState(state);
  };

  checkExistsBrand = (filteredBrands: any,value:any) =>{
    let exists = false;
    filteredBrands.forEach(bfElement => {
      if(bfElement.value == value){
        exists = true;
      }
      
    });
    return exists;

  }

  checkExistsPractice = (filteredPractices: any,value:any) =>{
    let exists = false;
    filteredPractices.forEach(bfElement => {
      if(bfElement.value == value){
        exists = true;
      }
      
    });
    return exists;

  }

  onClickDefaultSetup = () => {
    const { region ,practice, department, brand,trigged } = this.state;
    const { auth } = this.props;

      let setup = {
        regions: region.join(','),
        practices: practice.join(','),
        departments: department.join(','),
        brands: brand.join(','),
        triggedAlert: trigged,
        userId: auth.id
      };

      (this.props.actions.saveAlertSetup(setup) as any).then((data: any) => {
          this.hideDefaultSetupModal();
          this.props.actions.loadUser();

      });

  };

  handleLogOut = () => {
    const { history } = this.props;
    this.props.actions.logOutUser();
    history.push('/');
    window.location.reload();
  };

  render() {
    const { defaultSetupModal } = this.state;
    const { auth, history } = this.props;
    const { groups } = this.props.usages.groups;
    if (auth && !auth.id) {
      history.push('/');
    }
    const { practices } = this.props.usages.practices;
    const { brands }    = this.props.usages.brands;
    const { regions }   = this.props.usages.regions;
    const helpUrl       = this.state.mycdp_uri.replace('?token', `manage-help?token`);
    return (

      <Menu secondary>
        {defaultSetupModal &&
        <DefaultSetupModal hideDefaultSetupModal={this.hideDefaultSetupModal}
                           onChangeDefaultSetup={this.onChangeDefaultSetup}
                           onClickDefaultSetup={this.onClickDefaultSetup}
                           selectedPractice={this.state.practice ? this.state.practice :auth.AlertSetup.practice}
                           selectedRegion={this.state.region ? this.state.region :auth.AlertSetup.region}
                           selectedDepartment={this.state.department ? this.state.department :auth.AlertSetup.department}
                           selectedBrand={this.state.brand ? this.state.brand :auth.AlertSetup.brand}
                           checkedTrigger={this.state.trigged ? this.state.trigged :auth.AlertSetup.triggedAlert}
                           practices={practices} regions={regions} groups={groups} alertSetup={auth.AlertSetup} brands={brands}/>
        }
        { this.props.feedbackModal &&
        <FeedbackModal hideFeedbackModal = {this.props.hideFeedbackModal}
                       onChangeFeedback = {this.onChangeFeedback}
                       onClickFeedbackPage = {this.onClickFeedbackPage}/>
        }
        {this.state.loading && <Loading />}
        <Menu.Menu position='right'>
          <Menu.Item>
            <Image className="menuPic" src={auth.profilePicture}/>{auth.firstname + ' ' + auth.lastname}
            <Dropdown className='setting inverted item mr-0' icon='ellipsis vertical'>
              <Dropdown.Menu>
                <Dropdown.Item text='Setup' onClick={this.openDefaultSetup}/>
                {
                  auth && auth.isAdmin &&
                  <Dropdown.Item>
                    <a className='p0 textBlack' target='_blank' href={helpUrl}>Manage Help</a>
                  </Dropdown.Item>
                }
                <Dropdown.Item onClick={this.handleLogOut}>
                  Logout
                  <Icon className="mr0 float-right" name="sign-out"/>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    );
  }
}
