import { getReq } from '../api';

export namespace PracticeActions {
  export enum Type {
  }

  export const fetchPractices = () => async (dispatch: Function) => {
    try {
      return await getReq(`account/practiceList`, '');
    } catch (err) {
      console.log('Error occurred', err);
      return err;
    }
  };
}
export type PracticeActions = Omit<typeof PracticeActions, 'Type'>;
