import { createAction } from 'redux-actions';
import { getReq, postReq ,getReqCallCentreData ,getReqTriggeredCallCentreData} from '../api';

export namespace AlertActions {
  interface Result {
    message?: string;
    count?: number;
  }
  export enum Type {
    GET_ALERTS = 'GET_ALERTS',
    TEST_ALERTS = 'TEST_ALERTS',
    LOAD_ALERTS = 'LOAD_ALERTS',
    LOAD_CALL_CENTRE_DATA = 'LOAD_CALL_CENTER_ALERT_DATA',
    GET_TRIGGERED_ALERTS = 'GET_TRIGGERED_ALERTS',
    LOAD_ALERTS_HISTORY = 'LOAD_ALERTS_HISTORY',
    GET_CALL_CENTER_ALERT_DATA = 'GET_CALL_CENTER_ALERT_DATA',
    GET_PATIENT_VALIDATION_STATUS = 'GET_PATIENT_VALIDATION_STATUS',
    LOAD_PATIENT_VALIDATION_STATUS = 'LOAD_PATIENT_VALIDATION_STATUS',
    LOAD_LIST = 'LOAD_LIST',
    LOAD_LIST_SUCCESS = 'LOAD_LIST_SUCCESS',
    LOAD_LIST_FAIL = 'LOAD_LIST_FAIL',
  }

  const getAlertsAction = createAction(Type.GET_ALERTS, (data: any) => ({data}));
  const getCallCentreDataAction = createAction(Type.GET_CALL_CENTER_ALERT_DATA, (data: any,data2: any) => ({data,data2}));
  const getTriggeredAlertsAction = createAction(Type.GET_TRIGGERED_ALERTS, (data: any) => ({data}));
  const testAlertsAction = createAction(Type.TEST_ALERTS, (data: any) => ({data}));
  const loadAlertsAction = createAction(Type.LOAD_ALERTS, (data:any)=>({data}));
  const loadCallCentreDataAction = createAction(Type.LOAD_CALL_CENTRE_DATA, (data:any,data2:any)=>({data ,data2}));
  const loadAlertHistoryAction = createAction(Type.LOAD_ALERTS_HISTORY, (data:any)=>({data}));
  const getPatientValidationStatusAction  = createAction(Type.GET_PATIENT_VALIDATION_STATUS, (data: any) => ({data}));
  const loadPatientValidationStatusAction = createAction(Type.LOAD_PATIENT_VALIDATION_STATUS, (data:any)=>({data}));
  const loadListAction = createAction(Type.LOAD_LIST, (data:any)=>({data}));
  const loadListSuccessAction = createAction(Type.LOAD_LIST_SUCCESS, (items: any, count: any) => ({items, count}));
  const loadListFailAction = createAction(Type.LOAD_LIST_FAIL, (error: string) => ({error}));


  export const getAlerts = (active:boolean)=> async(dispatch: Function) => {
    dispatch(loadAlertsAction(false));
    try {
      const alerts = await getReq(`alert/getAlerts/${active}`, '');
      dispatch(getAlertsAction(alerts));
      return alerts;
    } catch (err) {
      console.log("Error occurred", err);
      return [];
    }
  };


  export const getCallCentreData = ()=> async(dispatch: Function) => {
    dispatch(loadCallCentreDataAction(false,false));
    try {
      const users    = await getReqTriggeredCallCentreData({username:'Mohit',password:'mohit123'});
      const duplicates = await getReqCallCentreData({username:'Mohit',password:'mohit123'});
      dispatch(getCallCentreDataAction(users,duplicates));
      return users;
    } catch (err) {
      console.log("Error occurred", err);
      return [];
    }
  };

  export const getPdfFile = (params: object) => async () => {
    let stringifyParams;
    if(params){
      stringifyParams = '/'+JSON.stringify(params)
    }
    try {
      const resp =  await getReq("alert/getPdf"+stringifyParams, '');
      return resp;
    } catch (err) {
      return;
    }
  };




  export const fetchAlertsHistory = (id)=> async(dispatch: Function) => {
    dispatch(loadAlertsAction(false));
    try {
      const alertHistory = await getReq(`alert/getAlertHistory/${id}`, '');
      dispatch(loadAlertHistoryAction(alertHistory));
      return alertHistory;
    } catch (err) {
      console.log("Error occurred", err);
      return err;
    }
  };

  export const saveAlertForm = (alert) => async (dispatch: Function) => {
    dispatch(loadAlertsAction(false));
    try {
      return await postReq("alert/saveAlert", alert)
    } catch (error) {
      console.log("Error occurred", error);
      return error;
    }
  };

  export const updateAlertForm = (alert) => async(dispatch: Function) => {
    dispatch(loadAlertsAction(false));
    try {
      return await postReq("alert/updateAlert", alert)
    } catch (error) {
      console.log("Error occurred", error);
      return error;
    }
  };

  export const deleteAlertForm = (alert ) => async (dispatch: Function) => {
    dispatch(loadAlertsAction(false));
    try{
    return await postReq("alert/deleteAlert", alert);
      }catch (error) {
      console.log("Error occurred", error);
      return error;
    }
  };

  export const testAlertQuery = (alert) => async(dispatch: Function) => {
    dispatch(loadAlertsAction(false));
    try{
      const testAlert = await postReq("alert/testAlert", alert);
        dispatch(testAlertsAction(testAlert));
        return testAlert;
      } catch (error){
      console.log("Error occurred", error);
      return error;
    }
  };

  export const getTriggeredAlerts = ()=> async(dispatch: Function) => {
    dispatch(loadAlertsAction(false));
    try {
      const triggeredAlerts = await getReq(`alert/getTriggeredAlerts`, '');
          dispatch(getTriggeredAlertsAction(triggeredAlerts));
          return triggeredAlerts;
        } catch(err) {
          console.log("Error occurred", err);
          return [];
        }
  };

  export const sendEmail = (alertId) => async(dispatch: Function) => {
    try {
      return await postReq("alert/sendEmail", alertId);
    } catch (error) {
      console.log("Error occurred", error);
      return error;
    }
  }
  export const getPatientValidationStatus = () => async (dispatch: Function, ) => {
    dispatch(loadPatientValidationStatusAction(false));
    try {
      const resp =  await getReq(`account/patientValidationStatus`, '');
      dispatch(getPatientValidationStatusAction(resp));
      return resp;
    } catch (error) {
      console.log("Error occurred", error);
      return error;
    }
  };
  export const loadList = (bucket: string, prefix: string) => async (dispatch: Function, ) => {
    dispatch(loadListAction(false));
    try {
      const result:Result|null =  await getReq(`aws-bucket/${bucket}/${encodeURIComponent(prefix)}`,'') as Result | null;
      if (!result) {
        dispatch(loadListFailAction( 'Error on fetching S3 bucket'));
        return null;

      }
      const count = result&&result.count ? result.count : 0;
      dispatch(loadListSuccessAction(result, count));
      return result;
    } catch (error) {
      if (error instanceof Error) {
        console.log(error.message);
        return error.message;
      }
      return 'An unknown error occurred';
    }
  };


}

export type AlertActions = Omit<typeof AlertActions, 'Type'>;
