import * as React from 'react';
import { Modal } from 'semantic-ui-react';

export namespace ViewPDFModal {
  export interface Props{
    pdfModal: '' | any,
    pdfLinksNotFound: '' | any,
    pdfLinks: '' | any,
    closeModal:()=>void
  }
}

export class ViewPDFModal extends React.Component<ViewPDFModal.Props, any> {
  static defaultProps: Partial<ViewPDFModal.Props> = {

  };
  constructor(props: ViewPDFModal.Props, context?: any) {
    super(props, context);

  }

  render() {
    const {closeModal, pdfModal, pdfLinksNotFound, pdfLinks} = this.props;
    return (
        <Modal
            open={pdfModal}
            onClose={closeModal}
            aria-labelledby="ModalHeader"
            centered
            closeIcon
            size={'large'}
        >
          <Modal.Content scrolling>
              {pdfLinksNotFound ?
                <h4>No files found for specific member on this day. Please click on RUN button to generate files and then view it after execution.  </h4>
                :
                <iframe
                  title="PDF files"
                  src={pdfLinks}
                  height={'500px'}
                  width={'100%'}
                />}
            </Modal.Content>
        </Modal>

    );
  }
}
