import { createAction } from 'redux-actions';
import { postReq, getReq } from '../api';

export namespace UserActions {
  export enum Type {
    SIGNIN = 'SIGNIN',
    LOGOUT_USER = 'LOGOUT_USER',
    LOAD_USER = 'LOAD_USER'
  }

  const signInAction = createAction(Type.SIGNIN, (data: any) => ({ data }));
  const loadUserAction = createAction(Type.LOAD_USER);
  export const logOutUser = createAction(Type.LOGOUT_USER);

  export const signIn = (profile: any) => {
    return (dispatch: Function) => {

      let obj = {
        email: profile.email,
        firstname: profile.firstname,
        profilePicture: profile.profilePicture
      };
      console.log('obj', obj);
      postReq("sessions?jwt=1", obj)
        .then((data:any) => {
          dispatch(signInAction(data));
          dispatch(loadUser());
          postReq("sessions/savePartners", { userId: data.user.id,partnerName :"ALERTS"}).then(()=>{
          })
          }
        ).catch(error => {
        console.log("Error occurred", error);
      });
    }
  };

  export const signInWithToken = (token: any) => {
    return (dispatch: Function) => {
      getReq(`sessions/cdpLogin/${token}`, '' )
        .then(data => {
          dispatch(signInAction(data));
          dispatch(loadUser());
        }).catch(error => {
        console.log("Error occurred", error);
      });
    }
  };



  export const loadUser = () => {

    return (dispatch: Function): void => {

      getReq("sessions", '')
        .then(data => {
          dispatch(loadUserAction(data));
        }).catch(error => {
        console.log("Error occurred", error);
      });
    }
  };

  export const saveAlertSetup = (setup: any) => (dispatch: Function) => new Promise((resolve, reject) => {
    postReq("alert/saveAlertSetup", setup)
      .then(data => {
        resolve(data);
      }).catch(error => {
      console.log("Error occurred", error);
      reject(error);
    });
  });

  export const sendFeedback = (feedback: any) => (dispatch: Function) => new Promise((resolve, reject) => {
    postReq("alert/sendFeedback", feedback)
      .then(data => {
        resolve(data);
      }).catch(error => {
      console.log("Error occurred", error);
      reject(error);
    });
  });

  export const getDentalPartners = (userId) => async (dispatch: Function) => {
    try {
      const resp = await getReq(`getPartnersList/${userId}`, {});
      return resp;
    } catch (err) {
      return err;
    }
  };
}

export type UserActions = Omit<typeof UserActions, 'Type'>;
