import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Sidebar, Menu, Icon, Accordion, List } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import {  bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../reducers';
import { UserActions } from '../../actions';
import { omit } from '../../utils';
import { menusArray } from '../../const';


export namespace MenuBar {
  export interface Props extends RouteComponentProps<void> {
    auth: RootState.AuthState,
    actions: UserActions,
    visible: boolean,
    handleToggle:()=>void
  }
}

export interface State {
  activeIndex : number,
  visible : boolean,
  mycdp_uri:string
}


@(connect(
  (state: RootState, ownProps): Pick<MenuBar.Props, 'auth'> => {
    return { auth: state.auth};
  },
  (dispatch: Dispatch): Pick<MenuBar.Props, 'actions'> => ({
    actions: bindActionCreators(omit(UserActions, 'Type'), dispatch)
  })
) as any)


export class MenuBar extends React.Component<MenuBar.Props, any> {
  static defaultProps: Partial<MenuBar.Props> = {

  };

  constructor(props: MenuBar.Props, context?: any) {
    super(props, context);
    this.state = {
      activeIndex : 0,
      mycdp_uri:""
    };

  }
  componentDidMount = async () => {
    const { auth } = this.props;
    const user:any = auth;
    const res:any = await this.props.actions.getDentalPartners(user.id);
    if(res && res.partners_uri){
      this.setState({mycdp_uri:res.partners_uri})
    }
  }
  toggleVisible=()=>{
    this.setState({visible:!this.state.visible});
  }


  handleClick = async (e) => {
    e.preventDefault();
    const { mycdp_uri } = this.state;
    if(mycdp_uri){
      window.location.href = mycdp_uri;
    }
  };
  render() {
    const { auth } = this.props;
    const user:any = auth;
    let userRoles:any = [];
    if(user && user.UdrUsersPermissions && user.UdrUsersPermissions.length) {
      user.UdrUsersPermissions.forEach( (item:any) => {
        if(item.UdrPermission)
          userRoles.push(item.UdrPermission.role);
      });
    }
    let basePath = window.location.pathname.split('/')[1];
    const basePathIndex = basePath
      ? menusArray.findIndex(i => i.name === basePath)
      : -1;


    const panels = menusArray.map((menu: any, i) => {

      // Check if role is assign for menu
      if(menu.role && menu.role.length) {
        let roleExist = false;
        menu.role.forEach( (role: string) => {
          if(userRoles.indexOf(role) >= 0) {
            roleExist = true;
          }
        });
        if(roleExist === false) {
          return {key: `panel-${i}`};
        }
      }

      const parentLink = '/'+menu.name;

      // Child Menu
      if (menu.subMenus.length > 0) {
        const childMenus = menu.subMenus.map((subMenu: any, j: number) => {
          // Check if role is assign for menu
          if(subMenu.role && subMenu.role.length) {
            let roleExist = false;
            subMenu.role.forEach( (role: any) => {
              if(userRoles.indexOf(role) >= 0) {
                roleExist = true;
              }
            });
            if(roleExist === false) {
              return false;
            }
          }
          let childPath = window.location.pathname.split('/')[2];
          const childLink = `${parentLink}/${subMenu.name}`;
          return (
            <List.Item
              key={j}
              className={(childPath === subMenu.name.toLowerCase()) ? 'active' : ''}
            >
              <Accordion.Title className={(childPath === subMenu.name.toLowerCase()) ? 'active' : ''}>
                <Link
                  to={ childLink }
                >
                  { subMenu.customIcon ? <i aria-hidden="true" className={`${subMenu.customIcon}  icon`}></i> : <Icon name={subMenu.icon}/> }
                  {subMenu.title}
                </Link>
              </Accordion.Title>
            </List.Item>
          );
        });
        const angle = 'angle down';
        return {
          key: `panel-${i}`,
          index : menu.index,
          title: (
            <Accordion.Title className={(basePath === menu.name) ? 'active' : ''}>
              <Link
                to="#"
              >
                { menu.customIcon ? <i aria-hidden="true" className={`${menu.customIcon}  icon`}></i> : <Icon name={menu.icon}/> }
                { menu.title }
                <Icon name={angle} />
              </Link>
            </Accordion.Title>),
          content: <Accordion.Content><List>{childMenus}</List></Accordion.Content>
        };
      }

      // Dont show in menu bar if menu hide is set
      if(menu.hide) {
        // return false;
        return {key: `panel-${i}`};
      }
      return {
        key: `panel-${i}`,
        index : menu.index,
        title: (
          <Accordion.Title className={(basePath === menu.name) ? 'active' : ''}>
            <Link
              to={ parentLink }
            >
              { menu.customIcon ? <i aria-hidden="true" className={`${menu.customIcon}  icon`}></i> : <Icon name={menu.icon}/> }
              { menu.title }
            </Link>
          </Accordion.Title>
        )
      };
    });
    // const panels = menusArray.map((menu: any, i) => {
    //
    //   // Check if role is assign for menu
    //   if(menu.role && menu.role.length) {
    //     let roleExist = false;
    //     menu.role.forEach( (role: string) => {
    //       if(userRoles.indexOf(role) >= 0) {
    //         roleExist = true;
    //       }
    //     });
    //     if(roleExist === false) {
    //       return {key: `panel-${i}`};
    //     }
    //   }
    //
    //   const parentLink = '/'+menu.name;
    //
    //   // Dont show in menu bar if menu hide is set
    //   if(menu.hide) {
    //     return {key: `panel-${i}`};
    //   }
    //   console.log('~!~!~!~!~!~!~', basePath, menu.name);
    //   return {
    //     key: `panel-${i}`,
    //     index : menu.index,
    //     title: (
    //       <Accordion.Title className={(basePath === menu.name) ? 'active' : ''}>
    //         <Link
    //           to={ parentLink }
    //         >
    //           { menu.customIcon ? <i aria-hidden="true" className={`${menu.customIcon}  icon`}></i> : <Icon name={menu.icon}/> }
    //           { menu.title }
    //         </Link>
    //       </Accordion.Title>
    //     )
    //   };
    // });

    return (
      <Sidebar
        as={Menu}
        animation='push'
        width='thin'
        visible={this.props.visible}
        icon='labeled'
        vertical
        inverted
      >
        <div className="toggleBtn">
          <Icon name="bars" onClick={this.props.handleToggle}/>
        </div>
        <div className="logoHolder">

          <div className="logo" onClick={this.handleClick}></div>
        </div>
        <Accordion className="leftMenu" panels={ panels } defaultActiveIndex={ basePathIndex } />
      </Sidebar>
    );
  }
}
