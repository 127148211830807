import { handleActions } from 'redux-actions';
import { RootState } from './state';
import { UserActions } from '../actions/user';
import { UserModel } from '../models';
const store = require('store2');

const initialState: Partial<RootState.AuthState> = {};

export const authReducer = handleActions<Partial<RootState.AuthState>, UserModel>(
  {
    [UserActions.Type.SIGNIN]: (state, action) => {
      if (action.payload && action.payload.data.accessToken) {
        store('authToken', action.payload.data.accessToken);
      }
      return ({ login: true });
    },
    [UserActions.Type.LOAD_USER]: (state, action:any) => {
      return action.payload;
    },
    [UserActions.Type.LOGOUT_USER]: (state, action) => {
      store.remove('authToken');
      return initialState;
    }
  },
  initialState
);
