import axios from 'axios';
import {apiHost} from '../config';
const store = require('store2');


export const getReq = ( url: any, params: any) => new Promise((resolve, reject) =>  {

  const token = store('authToken');
  if(token){
    axios.defaults.headers.common['Authorization'] = 'bearer '+token;
  }
        axios.get(apiHost+url, { params })
       .then(function (data: any) {
            resolve(data.data);
        }).catch(function (error: any) {
          if (axios.isAxiosError(error)) {
            // Handle Axios-specific errors
            console.error('Axios error:', error.response?.data || error.message);
           // alert('An error occurred while processing your request. Please try again.');
          } else {
            // Handle non-Axios errors
            console.error('Unexpected error:', error);
           // alert('An unexpected error occurred. Please try again.');
          }
      });
});

export const getReqTriggeredCallCentreData = (  params: any) => new Promise((resolve, reject) =>  {

    axios.get('https://dcrm.cdp.dental/api/triggeredCallCenterUsersForAlerts' )
       .then(function (data: any) {
            resolve(data);
        }).catch(function (error: any) {
          if (axios.isAxiosError(error)) {
            // Handle Axios-specific errors
            console.error('Axios error:', error.response?.data || error.message);
          //  alert('An error occurred while processing your request. Please try again.');
          } else {
            // Handle non-Axios errors
            console.error('Unexpected error:', error);
           // alert('An unexpected error occurred. Please try again.');
          }
      });
});

export const getReqCallCentreData = (  params: any) => new Promise((resolve, reject) =>  {

   axios.get('https://dcrm.cdp.dental/api/getAllCallCenterUsers' )
     .then(function (data: any) {
          resolve(data);
      }).catch(function (error: any) {
        if (axios.isAxiosError(error)) {
          // Handle Axios-specific errors
          console.error('Axios error:', error.response?.data || error.message);
         // alert('An error occurred while processing your request. Please try again.');
        } else {
          // Handle non-Axios errors
          console.error('Unexpected error:', error);
         // alert('An unexpected error occurred. Please try again.');
        }
    });
});

export const postReq = ( url: any, params: any) => new Promise((resolve, reject) =>  {
      axios.post(apiHost+url, params )
     .then(function (data: any) {
        resolve(data.data);
      }).catch(function (error: any) {
        if (axios.isAxiosError(error)) {
          // Handle Axios-specific errors
          console.error('Axios error:', error.response?.data || error.message);
         // alert('An error occurred while processing your request. Please try again.');
        } else {
          // Handle non-Axios errors
          console.error('Unexpected error:', error);
         // alert('An unexpected error occurred. Please try again.');
        }
    });
});

export const putReq = async ( url: any, params: any) => new Promise((resolve, reject) =>  {
  axios.put(apiHost+url, params )
    .then(function (data: any) {
      resolve(data.data);
    }).catch(function (error: any) {
      if (axios.isAxiosError(error)) {
        // Handle Axios-specific errors
        console.error('Axios error:', error.response?.data || error.message);
        alert('An error occurred while processing your request. Please try again.');
      } else {
        // Handle non-Axios errors
        console.error('Unexpected error:', error);
        //alert('An unexpected error occurred. Please try again.');
      }
  });
});
