import { handleActions } from 'redux-actions';
import { RootState } from './state';
import { UsageActions } from '../actions/usage';
import { UsagesModel } from '../models';

const initialState: RootState.UsageState = {
  isLoad: false,
  usages:[],
  usageUser:[],
  lastWorkDay:[],
  regions: {regions: [], regionList: [],regionFilterData:[]},
  practices: {practices: [], practiceList: [],practiceFilterData:[]},
  brands:{ brands: [] ,brandList: [] ,brandFilterData:[]},
  practicesOTD: {practicesOTD: [], practiceOTDList: []},
  groups: {groups: [], groupList: []},
  carrierList:[],
  carrierPrimaryList:[],
  patientValidationStatus:[],
};

export const usageReducer = handleActions<RootState.UsageState, UsagesModel>(
  {
    [UsageActions.Type.GET_USAGES]: (state:any, action:any) => {
      if(action.payload && action.payload.data) {
        return { ...state, isLoad: false, usages: action.payload.data };
      }
    },
    [UsageActions.Type.GET_USER]: (state:any, action:any) => {
      if(action.payload && action.payload.data){
        return { ...state, isLoad: false, usageUser: action.payload.data };
      }
    },
    [UsageActions.Type.GET_LAST_NEXT_WORKDAY]: (state:any, action:any) => {
      if(action.payload && action.payload.data){
        return { ...state, isLoad: false, lastWorkDay: action.payload.data };
      }
    },
    [UsageActions.Type.LOAD_ALERTS]: (state, action:any) => {
      return { ...state, isLoad: true};
    },

    [UsageActions.Type.GET_PRACTICES]: (state:any, action:any) => {
      if(action.payload && action.payload.data) {
        return { ...state, isLoad: false, practices: action.payload.data };
      }
    },
    [UsageActions.Type.GET_REGIONS]: (state:any, action:any) => {
      if(action.payload && action.payload.data) {
        return { ...state, isLoad: false, regions: action.payload.data };
      }
    },
    [UsageActions.Type.GET_BRANDS]: (state:any, action:any) => {
      if(action.payload && action.payload.data) {
        return { ...state, isLoad: false, brands: action.payload.data };
      }
    },
    [UsageActions.Type.LOAD_PRACTICES]: (state: any, action: any) => {
      return { ...state, isLoad: true};
    },

    [UsageActions.Type.GET_PRACTICESOTD]: (state:any, action:any) => {
      if(action.payload && action.payload.data) {
        return { ...state, isLoad: false, practicesOTD: action.payload.data };
      }
    },
    [UsageActions.Type.LOAD_PRACTICESOTD]: (state: any, action: any) => {
      return { ...state, isLoad: true};
    },

    [UsageActions.Type.GET_NOTIFICATION_GROUPS]: (state:any, action:any) => {
      if(action.payload && action.payload.data) {
        return { ...state, isLoad: false, groups: action.payload.data };
      }
    },

    [UsageActions.Type.LOAD_NOTIFICATION_GROUPS]: (state: any, action: any) => {
      return { ...state, isLoad: true};
    },

    [UsageActions.Type.GET_CARRIER_IDENTITY]: (state:any, action:any) => {
      if(action.payload && action.payload.data) {
        return { ...state, isLoad: false, carrierList: action.payload.data };
      }
    },
    [UsageActions.Type.LOAD_CARRIER_IDENTITY]: (state: any, action: any) => {
      return { ...state, isLoad: true};
    },
    [UsageActions.Type.GET_CARRIER_PRIMARY_IDENTITY]: (state:any, action:any) => {
      if(action.payload && action.payload.data) {
        return { ...state, isLoad: false, carrierPrimaryList: action.payload.data };
      }
    },
    [UsageActions.Type.LOAD_CARRIER_PRIMARY_IDENTITY]: (state: any, action: any) => {
      return { ...state, isLoad: true};
    },
    [UsageActions.Type.GET_PATIENT_VALIDATION_STATUS]: (state:any, action:any) => {
      if(action.payload && action.payload.data) {
        return { ...state, isLoad: false, patientValidationStatus: action.payload.data };
      }
    },
    [UsageActions.Type.LOAD_PATIENT_VALIDATION_STATUS]: (state: any, action: any) => {
      return { ...state, isLoad: true};
    },

  },
  initialState
);
