import { brands } from '../const';
import { createAction } from 'redux-actions';
import { getReq, postReq, putReq } from '../api';

export namespace UsageActions {
  export enum Type {
    GET_USAGES = 'GET_USAGES',
    GET_USER = 'GET_USER',
    LOAD_ALERTS = 'LOAD_ALERTS',
    GET_LAST_NEXT_WORKDAY = 'GET_LAST_NEXT_WORKDAY',
    GET_PRACTICES = 'GET_PRACTICES',
    GET_BRANDS = 'GET_BRANDS',
    LOAD_PRACTICES = 'LOAD_PRACTICES',
    LOAD_BRANDS = 'LOAD_BRANDS',
    GET_PRACTICESOTD = 'GET_PRACTICESOTD',
    LOAD_PRACTICESOTD = 'LOAD_PRACTICESOTD',
    GET_NOTIFICATION_GROUPS = 'GET_NOTIFICATION_GROUPS',
    LOAD_NOTIFICATION_GROUPS = 'LOAD_NOTIFICATION_GROUPS',
    GET_CARRIER_IDENTITY = 'GET_CARRIER_IDENTITY',
    LOAD_CARRIER_IDENTITY = 'LOAD_CARRIER_IDENTITY',
    GET_CARRIER_PRIMARY_IDENTITY = 'GET_CARRIER_PRIMARY_IDENTITY',
    LOAD_CARRIER_PRIMARY_IDENTITY = 'LOAD_CARRIER_PRIMARY_IDENTITY',
    GET_PATIENT_VALIDATION_STATUS = 'GET_PATIENT_VALIDATION_STATUS',
    LOAD_PATIENT_VALIDATION_STATUS = 'LOAD_PATIENT_VALIDATION_STATUS',
    GET_REGIONS = 'GET_REGIONS',
    LOAD_REGIONS = 'LOAD_REGIONS',

  }

  const getUsageAction = createAction(Type.GET_USAGES, (data: any) => ({ data }));
  const getUserAction = createAction(Type.GET_USER, (data: any) => ({ data }));
  const loadUsagesAction = createAction(Type.LOAD_ALERTS, (data:any)=>({data}));
  const getLastNextWorkDaysAction = createAction(Type.GET_LAST_NEXT_WORKDAY, (data: any) => ({ data }));

  const getBrandsAction  = createAction(Type.GET_BRANDS, (data: any) => ({data}));
  const loadBrandsAction = createAction(Type.LOAD_BRANDS, (data:any)=>({data}));

  const getPracticesAction  = createAction(Type.GET_PRACTICES, (data: any) => ({data}));
  const loadPracticesAction = createAction(Type.LOAD_PRACTICES, (data:any)=>({data}));

  const getRegionsAction  = createAction(Type.GET_REGIONS, (data: any) => ({data}));
  const loadRegionsAction = createAction(Type.LOAD_REGIONS, (data:any)=>({data}));

  const getPracticesOTDAction  = createAction(Type.GET_PRACTICESOTD, (data: any) => ({data}));
  const loadPracticesOTDAction = createAction(Type.LOAD_PRACTICESOTD, (data:any)=>({data}));

  const getNotificationGroupsAction  = createAction(Type.GET_NOTIFICATION_GROUPS, (data: any) => ({data}));
  const loadNotificationGroupsAction = createAction(Type.LOAD_NOTIFICATION_GROUPS, (data:any)=>({data}));


  const getCarrierIdentityAction  = createAction(Type.GET_CARRIER_IDENTITY, (data: any) => ({data}));
  const loadCarrierIdentityAction = createAction(Type.LOAD_CARRIER_IDENTITY, (data:any)=>({data}));

  const getCarrierPrimaryIdentityAction  = createAction(Type.GET_CARRIER_PRIMARY_IDENTITY, (data: any) => ({data}));
  const loadCarrierPrimaryIdentityAction = createAction(Type.LOAD_CARRIER_PRIMARY_IDENTITY, (data:any)=>({data}));

  const getPatientValidationStatusAction  = createAction(Type.GET_PATIENT_VALIDATION_STATUS, (data: any) => ({data}));
  const loadPatientValidationStatusAction = createAction(Type.LOAD_PATIENT_VALIDATION_STATUS, (data:any)=>({data}));


  export const saveUdaUsage = (module, loginVia, deviceOS)=> (dispatch: Function, getState: Function) => new Promise((resolve, reject) => {
    const user = getState().auth;
    if (user && !user.id) {
      setTimeout(function() {
        resolve(saveUdaUsageReport(module, loginVia, deviceOS, dispatch, getState));
      }, 1000);
    } else {
      resolve(saveUdaUsageReport(module, loginVia, deviceOS, dispatch, getState));
    }
  });

  export const getUsageData = (userId, role = 'Dentist') => (dispatch: Function) => new Promise((resolve, reject) => {
    if (!userId) {
      userId = 'All';
    }
    if (role) {
      role = '/' + role;
    } else {
      role = '/All';
    }
    dispatch(loadUsagesAction(false));
    getReq(`usage/getUsage/${userId}${role}`, '')
      .then(data => {
        dispatch(getUsageAction(data));
        resolve(data);
      })
      .catch(err => {
        console.log("Error occurred", err);
        reject(err);
      })
  });

  export const getUserList = () => (dispatch: Function) => new Promise((resolve, reject) => {
    getReq(`usage/getUser`, '')
      .then(data => {
        dispatch(getUserAction(data));
        resolve(data);
      })
      .catch(err => {
        console.log("Error occurred", err);
        reject(err);
      })
  });

  export const getLastNextWorkDays = () => (dispatch: Function) => new Promise((resolve, reject) => {
    getReq(`usage/getLastNextWorkDays`, '')
      .then(data => {
        dispatch(getLastNextWorkDaysAction(data));
        resolve(data);
      })
      .catch(err => {
        console.log("Error occurred", err);
        reject(err);
      })
  });

  export const flushUsageData = (obj: any) => (dispatch: Function) => new Promise((resolve, reject) => {
    putReq(`usage/flushUsageData`, { data: obj })
      .then(data => {
        resolve(data);
      }).catch(error => {
      console.log("Error occurred", error);
      reject(error);
    });
  });

  export const hideUsageReport = (obj: any) => (dispatch: Function) => new Promise((resolve, reject) => {
    putReq(`usage/hideUsageReport`, { data: obj })
      .then(data => {
        resolve(data);
      }).catch(error => {
      console.log("Error occurred", error);
      reject(error);
    });
  });

  export const saveUdaUsageReport = (module, loginVia, deviceOS, dispatch, getState) => new Promise((resolve, reject) => {
    const user = getState().auth;
    if (user && !user.id) {
      setTimeout(function() {
        saveUdaUsageReport(module, loginVia, deviceOS, dispatch, getState)
      }, 1000);
      resolve(false);
    }
    let udaRole:any = [];
    if (user && user.UdrUsersPermissions && user.UdrUsersPermissions.length) {
      user.UdrUsersPermissions.forEach((item:any) => {
        udaRole.push(item.UdrPermission.role);
      });
    }
    postReq('usage/saveUdaUsages', {
        userId: user.id,
        firstName: user.firstname,
        lastName: user.lastname,
        udaRole: udaRole.join(","),
        loginVia: loginVia,
        module: module,
        deviceOS: deviceOS
    })
      .then(data => {
        resolve(data);
      }).catch(error => {
      console.log("Error occurred", error);
      reject(error);
    });
  });

  export const fetchPractices = (status:boolean)=> async (dispatch: Function) => {
    dispatch(loadBrandsAction(false));
    dispatch(loadPracticesAction(false));

    try {
      const practicesData: any = await getReq(`account/practiceList/${status}`, '');
      const practices: any = [{key:0, text:'All Practices', value:'all'}];
      if (practicesData && practicesData.length) {
        practicesData.forEach((practiceDetail: any, index: number) => {
          practicesData[index].brand = practicesData[index].UdaPracticeInfo.brand;
          practicesData[index].region = practicesData[index].UdaPracticeInfo.Region !== null ? practicesData[index].UdaPracticeInfo.Region.name : '';
          practiceDetail.brand       = practiceDetail.UdaPracticeInfo.brand;
          practiceDetail.region      = practiceDetail.UdaPracticeInfo.Region !== null ? practicesData[index].UdaPracticeInfo.Region.name : '';

          practiceDetail.brand = practiceDetail.UdaPracticeInfo? practiceDetail.UdaPracticeInfo.brand : '';
          practices.push({
            key:index+1,
            text: practiceDetail.practice,
            value: practiceDetail.practice,
            brand: practiceDetail.UdaPracticeInfo.brand,
            region: practiceDetail.UdaPracticeInfo.Region !== null ? practicesData[index].UdaPracticeInfo.Region.name : '',
            practiceDBName: practiceDetail.practiceDBName,
            practiceDBNameSame: practiceDetail.practiceDBNameSame,
            practice: practiceDetail.practice,
          })
        });
      }

      dispatch(getBrandsAction({brands, brandList: brands,brandFilterData:brands}));
      dispatch(getPracticesAction({practices, practiceList: practicesData ,practiceFilterData:practices}));
      return practicesData;
    } catch (err) {
      console.log("Error occurred", err);
      return err;
    }
  }

  export const fetchRegions = (status:boolean)=> async (dispatch: Function) => {
    dispatch(loadRegionsAction(false));
    try {
      const regionsData: any = await getReq(`account/regionList/${status}`, '');
      const regions: any = [{key:0, text:'All Regions', value:'all'}];
      if (regionsData && regionsData.length) {
        regionsData.forEach((regionDetail: any, index: number) => {

          regions.push({
            key:index+1,
            text: regionDetail.name,
            value: regionDetail.name
          })
        });
      }

      dispatch(getRegionsAction({regions, regionList: regionsData ,regionFilterData:regions}));
      return regionsData;
    } catch (err) {
      console.log("Error occurred", err);
      return err;
    }
  }

  export const fetchOTDPractices = (status:boolean)=> async (dispatch: Function) => {
    dispatch(loadPracticesOTDAction(false));
    try {
      const practicesData: any = await getReq(`account/practiceList/${status}`, '');
      let practicesOTD: any = [{key:0, text:'All Practices', value:'all'}];
      if (practicesData && practicesData.length) {
        practicesData.forEach((practiceDetail: any, index: number) => {
          practicesOTD.push({
            key:index+1,
            text: practiceDetail.practice,
            value: practiceDetail.practice,
            brand: practiceDetail.UdaPracticeInfo.brand,
            region: practiceDetail.UdaPracticeInfo.Region !== null ? practicesData[index].UdaPracticeInfo.Region.name : '',
            practiceDBName: practiceDetail.practiceDBName,
            practiceDBNameSame: practiceDetail.practiceDBNameSame,
            practice: practiceDetail.practice,
          })
        });
      }
      dispatch(getPracticesOTDAction({practicesOTD, practiceOTDList: practicesData}));
      return practicesData;
    } catch (err) {
      console.log("Error occurred", err);
      return err;
    }
  }

  export const getNotificationGroups = ()=> async(dispatch: Function) => {
    dispatch(loadNotificationGroupsAction(false));
    try {
      const groupsData: any = await getReq(`alert/getNotificationGroups`, '');
      let groups: any = [{key:0, text:'All Departments', value: 0}];
      let groupList: any = ['All'];
      if (groupsData && groupsData.length) {
        groupsData.forEach((groupDetail: any) => {
          groupList[groupDetail.id] = groupDetail.group
          groups.push({
            key:groupDetail.id,
            text: groupDetail.group,
            value: groupDetail.id
          })
        });
      }
      dispatch(getNotificationGroupsAction({groups, groupList: groupList}));
      return groups;
    } catch (err) {
      console.log("Error occurred", err);
      return err;
    }
  };
  export const getCarrierIdentity = () => async (dispatch: Function, ) => {
    dispatch(loadCarrierIdentityAction(false));
    try {
      const resp =  await getReq(`account/carrierIdentity`, '');
      dispatch(getCarrierIdentityAction(resp));
      return resp;
    } catch (error) {
      console.log("Error occurred", error);
      return error;
    }
  };
  export const getPrimaryCarrierIdentity = () => async (dispatch: Function, ) => {
    dispatch(loadCarrierPrimaryIdentityAction(false));
    try {
      const resp =  await getReq(`account/carrierPrimaryIdentity`, '');
      dispatch(getCarrierPrimaryIdentityAction(resp));
      return resp;
    } catch (error) {
      console.log("Error occurred", error);
      return error;
    }
  };
  export const getPatientValidationStatus = () => async (dispatch: Function, ) => {
    dispatch(loadPatientValidationStatusAction(false));
    try {
      const resp =  await getReq(`account/patientValidationStatus`, '');
      dispatch(getPatientValidationStatusAction(resp));
      return resp;
    } catch (error) {
      console.log("Error occurred", error);
      return error;
    }
  };
}
export type UsageActions = Omit<typeof UsageActions, 'Type'>;
