import * as React from 'react';
import { Grid, Modal, Table} from 'semantic-ui-react';
import { ViewFileModal } from '../../components/Setup/viewFormModal';
import { ViewNotesModal } from '../../components/Setup/ViewNotesModal';
import { ViewPDFModal } from '../../components/Setup/ViewPDFModal';
import {RouteComponentProps} from "react-router";
import {RootState} from "../../reducers";
import {AlertActions} from "../../actions";
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import {omit} from "../../utils";
import Loading from "../../components/Loading";

export namespace PopUpModalCallCentre {
  export interface Props extends RouteComponentProps<void> {
    actions: AlertActions;  }

  export interface Props{
    data: [] | any ,
    callCentreData: [] | any;
    filters:any,
    practiceList:any[];
    popUpHeader: string,
    closeModal:()=>void,
    sendEmail:()=>void,
    practices: any[],
    practicesOTD: any[],
    alertId:number,
    isCallCentreAlert:number,
    alertName:string,
    viewAll:()=>void,
    viewAllStatus:boolean,
    viewAllNoErrorsStatus:boolean,
    carrierList:any[],
    carrierPrimaryList:[],
    patientValidationStatus:[],
  }
}
@(connect(
  (state: RootState): Pick<PopUpModalCallCentre.Props, any> => {
    return { alerts: state.alerts, auth: state.auth, usages: state.usages };
  },
  (dispatch: Dispatch): Pick<PopUpModalCallCentre.Props, 'actions'> => ({
    actions: bindActionCreators(omit(AlertActions, 'Type'), dispatch)
  })
) as any)

export class PopUpModalCallCentre extends React.Component<PopUpModalCallCentre.Props, any> {
  static defaultProps: Partial<PopUpModalCallCentre.Props> = {

  };
  constructor(props: PopUpModalCallCentre.Props, context?: any) {
    super(props, context);
    this.state = {
      activeIndex: 0,
      openFileModal: false,
      openNotesModal:false,
      files:'',
      dataNotes:'',
      collapse: true,
      isCallCentreAlert:this.props.isCallCentreAlert,
      callCentreData:[],
      alertId:this.props.alertId,
      alertName:this.props.alertName,
      loader:false,
      pdfState:{pdfModal:false, pdfLinks:'', pdfLinksNotFound:false}
    }
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex })
  };

  openFilesModal = (files)=>{
    this.setState({openFileModal: true, files: files.split(',')});
  };
  openNotesModal = (data:any)=>{
    this.setState({openNotesModal: true, dataNotes: data});
  };

  closeFilesModal = () => {
    this.setState({ openFileModal: false, files: []});
  };
  closeNotesModal = () => {
    this.setState({ openNotesModal: false, dataNotes: ''});
  };
  closePdfModal = () => {
    this.setState({pdfModal:false, pdfLinks:'', pdfLinksNotFound:false});
  };

  collapseNoDataItems = ()=> {
    this.setState({collapse: !this.state.collapse});
  };

  getPDFfiles=(carrierName: any, subscriberIDNew: any, filterDate: any) => {

    let obj:any = {
      carrierName:carrierName,
      subscriberID:subscriberIDNew,
      date:filterDate
    }

    const pdfModal:any  = true;
    this.setState({loader:true});
    (this.props.actions.getPdfFile(obj) as any).then((res:any) => {

      if(res && res.url && res.url.Body && res.url.Body.data){

        const fileData = new Uint8Array (res.url.Body.data);
        const file = new Blob(
          [fileData],
          {type: 'application/pdf'});
        const pdfLinks = URL.createObjectURL(file);
        this.setState({pdfModal:pdfModal, pdfLinks:pdfLinks,loader:false });
      } else {

        this.setState({pdfModal:pdfModal, pdfLinksNotFound:true,loader:false });
      }
    })
  } 

  sendEmail=()=>{
    this.props.sendEmail();
  };
  viewAll=()=>{
    this.props.viewAll();
  };

  triggeredStatus=(data)=>{
    let status = false;
    for (const keys in data) {
      if(data.hasOwnProperty(keys) && data[keys].length && !data[keys].name) {
        status= true;
      }
    }
      return (status? <span className="red-text"> -- Triggered</span> : '');
  };

  render() {
    const {  closeModal, popUpHeader,callCentreData } = this.props;
    const {  openFileModal, openNotesModal,dataNotes, files, pdfModal, pdfLinks,
      pdfLinksNotFound,loader } = this.state;


    let item: JSX.Element[] = [];
    let itemEmpty: JSX.Element[] = [];
    let sendEmail;
    let viewAll:any = [];

    if(callCentreData.length>0) {
      item.push(
        <Table celled padded>
            <Table.Header>
            <Table.Row>
                <Table.HeaderCell>First Name</Table.HeaderCell>
                <Table.HeaderCell>Last Name</Table.HeaderCell>
                <Table.HeaderCell>OdUsername</Table.HeaderCell>
            </Table.Row>
            </Table.Header>

            <Table.Body>

            {
                callCentreData.map((user: any, key: number) => {
                    return (
                    <Table.Row key={key}>               
                        <Table.Cell> <div>{user.firstname}</div> </Table.Cell>
                        <Table.Cell><div>{user.lastname}</div></Table.Cell>
                        <Table.Cell> <div>{user.odUserName} {user.odUserName?  <span className="red-text">Duplicate</span> : <span className="red-text">Missing</span>}</div></Table.Cell>                 
                   </Table.Row>)
                })
            }

            
                
                </Table.Body>
            </Table>

      )
      
    } else {
      itemEmpty.push(<div><p>No Result Found...</p></div>);
    }
    return (
      <Modal
        open={true}
        onClose={closeModal}
        aria-labelledby="ModalHeader"
        centered
        closeIcon
        size="large"
        className="howToFix"
      >
        <Modal.Header>
          {popUpHeader} {sendEmail} {viewAll}
        </Modal.Header>
        <Modal.Content scrolling>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                {loader && <Loading />}
                {
                  item
                }
              </Grid.Column>
            </Grid.Row>
          </Grid>

        
        </Modal.Content>
        {openFileModal && <ViewFileModal closeModal={this.closeFilesModal} files={files}/>}
        {openNotesModal && <ViewNotesModal closeModal={this.closeNotesModal} dataNotes={dataNotes}/> }
        {pdfModal && <ViewPDFModal pdfModal={pdfModal} pdfLinksNotFound={pdfLinksNotFound}
                                   pdfLinks={pdfLinks} closeModal={this.closePdfModal} /> }
      </Modal>
    );
  }
}
