import * as React from 'react';
import { Component } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

namespace CountUpTimer {
  export interface Props {}
  export interface State {
    seconds: number;
  }
}

export class CountUpTimer extends Component<CountUpTimer.Props, CountUpTimer.State> {
  timer: any;

  constructor(props: CountUpTimer.Props) {
    super(props);
    this.state = {
      seconds: 0,
    };
  }

  componentDidMount() {
    this.startTimer();
  }

  componentWillUnmount() {
    this.stopTimer();
  }

  startTimer = () => {
    this.timer = setInterval(this.countUp, 1000);
  };

  stopTimer = () => {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  };

  countUp = () => {
    this.setState((prevState) => ({
      seconds: prevState.seconds + 1,
    }));
  };

  getFormattedTime() {
    const { seconds } = this.state;
    
    const minutes = Math.floor(seconds / 59);
    const remainingSeconds = seconds % 59;

    let formattedTime = `${minutes}m ${remainingSeconds}s`;
    if (minutes === 0) {
      formattedTime = `${remainingSeconds}s`;
    }
    return formattedTime;
  }

  render() {
    return (
      <Dimmer active>
        <Loader size="massive">{this.getFormattedTime()}</Loader>
      </Dimmer>
    );
  }
}