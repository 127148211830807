const moment = require('moment');

export const menusArray = [
  { index: 1, name: "alert", customIcon:'', icon: "bell", title: "ALERT", role: [], subMenus: [] },
  { index: 2, name: "setup",customIcon:'', icon: "settings", title: "SETUP", role: [], subMenus: [] },
  { index: 4, name: "usage",customIcon:'', icon: "pie chart", title: "USAGE", role: [], subMenus: [] }
];

export const frequency = [
  {key:1, text:'15 Min' , value:15},
  {key:2, text:'60 Min' , value:60}
];

export const momentUtc = (date) => {
  return moment(date).utc();
}
export const brands = [
  {text:'All Brands', value:'All', key:'All'},
  {text:'SMD', value:'SMD', key:'SMD'},
  {text:'FSD', value:'FSD', key:'FSD'},
  {text:'OTD', value:'OTD', key:'OTD'},
  {text:'3CD', value:'3CD', key:'3CD'},
  {text:'SDD', value:'SDD', key:'SDD'}
];

export const cdpDBAlerts = [
  'Provider ABBR First and Last Name' ,
  'Missing Values Greater of Contract',
  'Missing Values On Sum Of Contract',
  'Provider State License',
'Active Practices Missing Bcare and Pay',
  'Provider with Care on the same day in more than on',
  'Providers having more than one user id',
  ' Provider Scheduled In Two Or More Practices On Th',



]
export const primaryCategories = { 1: "PPO", 2: "Medicaid", 6:"Medicare" }

export const timeArray = [
  { value: 0, text: "12:00 AM" },
  { value: 300000, text: "12:05 AM" },
  { value: 600000, text: "12:10 AM" },
  { value: 900000, text: "12:15 AM" },
  { value: 1200000, text: "12:20 AM" },
  { value: 1500000, text: "12:25 AM" },
  { value: 1800000, text: "12:30 AM" },
  { value: 2100000, text: "12:35 AM" },
  { value: 2400000, text: "12:40 AM" },
  { value: 2700000, text: "12:45 AM" },
  { value: 3000000, text: "12:50 AM" },
  { value: 3300000, text: "12:55 AM" },
  { value: 3600000, text: "1:00 AM" },
  { value: 3900000, text: "1:05 AM" },
  { value: 4200000, text: "1:10 AM" },
  { value: 4500000, text: "1:15 AM" },
  { value: 4800000, text: "1:20 AM" },
  { value: 5100000, text: "1:25 AM" },
  { value: 5400000, text: "1:30 AM" },
  { value: 5700000, text: "1:35 AM" },
  { value: 6000000, text: "1:40 AM" },
  { value: 6300000, text: "1:45 AM" },
  { value: 6600000, text: "1:50 AM" },
  { value: 6900000, text: "1:55 AM" },
  { value: 7200000, text: "2:00 AM" },
  { value: 7500000, text: "2:05 AM" },
  { value: 7800000, text: "2:10 AM" },
  { value: 8100000, text: "2:15 AM" },
  { value: 8400000, text: "2:20 AM" },
  { value: 8700000, text: "2:25 AM" },
  { value: 9000000, text: "2:30 AM" },
  { value: 9300000, text: "2:35 AM" },
  { value: 9600000, text: "2:40 AM" },
  { value: 9900000, text: "2:45 AM" },
  { value: 10200000, text: "2:50 AM" },
  { value: 10500000, text: "2:55 AM" },
  { value: 10800000, text: "3:00 AM" },
  { value: 11100000, text: "3:05 AM" },
  { value: 11400000, text: "3:10 AM" },
  { value: 11700000, text: "3:15 AM" },
  { value: 12000000, text: "3:20 AM" },
  { value: 12300000, text: "3:25 AM" },
  { value: 12600000, text: "3:30 AM" },
  { value: 12900000, text: "3:35 AM" },
  { value: 13200000, text: "3:40 AM" },
  { value: 13500000, text: "3:45 AM" },
  { value: 13800000, text: "3:50 AM" },
  { value: 14100000, text: "3:55 AM" },
  { value: 14400000, text: "4:00 AM" },
  { value: 14700000, text: "4:05 AM" },
  { value: 15000000, text: "4:10 AM" },
  { value: 15300000, text: "4:15 AM" },
  { value: 15600000, text: "4:20 AM" },
  { value: 15900000, text: "4:25 AM" },
  { value: 16200000, text: "4:30 AM" },
  { value: 16500000, text: "4:35 AM" },
  { value: 16800000, text: "4:40 AM" },
  { value: 17100000, text: "4:45 AM" },
  { value: 17400000, text: "4:50 AM" },
  { value: 17700000, text: "4:55 AM" },
  { value: 18000000, text: "5:00 AM" },
  { value: 18300000, text: "5:05 AM" },
  { value: 18600000, text: "5:10 AM" },
  { value: 18900000, text: "5:15 AM" },
  { value: 19200000, text: "5:20 AM" },
  { value: 19500000, text: "5:25 AM" },
  { value: 19800000, text: "5:30 AM" },
  { value: 20100000, text: "5:35 AM" },
  { value: 20400000, text: "5:40 AM" },
  { value: 20700000, text: "5:45 AM" },
  { value: 21000000, text: "5:50 AM" },
  { value: 21300000, text: "5:55 AM" },
  { value: 21600000, text: "6:00 AM" },
  { value: 21900000, text: "6:05 AM" },
  { value: 22200000, text: "6:10 AM" },
  { value: 22500000, text: "6:15 AM" },
  { value: 22800000, text: "6:20 AM" },
  { value: 23100000, text: "6:25 AM" },
  { value: 23400000, text: "6:30 AM" },
  { value: 23700000, text: "6:35 AM" },
  { value: 24000000, text: "6:40 AM" },
  { value: 24300000, text: "6:45 AM" },
  { value: 24600000, text: "6:50 AM" },
  { value: 24900000, text: "6:55 AM" },
  { value: 25200000, text: "7:00 AM" },
  { value: 25500000, text: "7:05 AM" },
  { value: 25800000, text: "7:10 AM" },
  { value: 26100000, text: "7:15 AM" },
  { value: 26400000, text: "7:20 AM" },
  { value: 26700000, text: "7:25 AM" },
  { value: 27000000, text: "7:30 AM" },
  { value: 27300000, text: "7:35 AM" },
  { value: 27600000, text: "7:40 AM" },
  { value: 27900000, text: "7:45 AM" },
  { value: 28200000, text: "7:50 AM" },
  { value: 28500000, text: "7:55 AM" },
  { value: 28800000, text: "8:00 AM" },
  { value: 29100000, text: "8:05 AM" },
  { value: 29400000, text: "8:10 AM" },
  { value: 29700000, text: "8:15 AM" },
  { value: 30000000, text: "8:20 AM" },
  { value: 30300000, text: "8:25 AM" },
  { value: 30600000, text: "8:30 AM" },
  { value: 30900000, text: "8:35 AM" },
  { value: 31200000, text: "8:40 AM" },
  { value: 31500000, text: "8:45 AM" },
  { value: 31800000, text: "8:50 AM" },
  { value: 32100000, text: "8:55 AM" },
  { value: 32400000, text: "9:00 AM" },
  { value: 32700000, text: "9:05 AM" },
  { value: 33000000, text: "9:10 AM" },
  { value: 33300000, text: "9:15 AM" },
  { value: 33600000, text: "9:20 AM" },
  { value: 33900000, text: "9:25 AM" },
  { value: 34200000, text: "9:30 AM" },
  { value: 34500000, text: "9:35 AM" },
  { value: 34800000, text: "9:40 AM" },
  { value: 35100000, text: "9:45 AM" },
  { value: 35400000, text: "9:50 AM" },
  { value: 35700000, text: "9:55 AM" },
  { value: 36000000, text: "10:00 AM" },
  { value: 36300000, text: "10:05 AM" },
  { value: 36600000, text: "10:10 AM" },
  { value: 36900000, text: "10:15 AM" },
  { value: 37200000, text: "10:20 AM" },
  { value: 37500000, text: "10:25 AM" },
  { value: 37800000, text: "10:30 AM" },
  { value: 38100000, text: "10:35 AM" },
  { value: 38400000, text: "10:40 AM" },
  { value: 38700000, text: "10:45 AM" },
  { value: 39000000, text: "10:50 AM" },
  { value: 39300000, text: "10:55 AM" },
  { value: 39600000, text: "11:00 AM" },
  { value: 39900000, text: "11:05 AM" },
  { value: 40200000, text: "11:10 AM" },
  { value: 40500000, text: "11:15 AM" },
  { value: 40800000, text: "11:20 AM" },
  { value: 41100000, text: "11:25 AM" },
  { value: 41400000, text: "11:30 AM" },
  { value: 41700000, text: "11:35 AM" },
  { value: 42000000, text: "11:40 AM" },
  { value: 42300000, text: "11:45 AM" },
  { value: 42600000, text: "11:50 AM" },
  { value: 42900000, text: "11:55 AM" },
  { value: 43200000, text: "12:00 PM" },
  { value: 43500000, text: "12:05 PM" },
  { value: 43800000, text: "12:10 PM" },
  { value: 44100000, text: "12:15 PM" },
  { value: 44400000, text: "12:20 PM" },
  { value: 44700000, text: "12:25 PM" },
  { value: 45000000, text: "12:30 PM" },
  { value: 45300000, text: "12:35 PM" },
  { value: 45600000, text: "12:40 PM" },
  { value: 45900000, text: "12:45 PM" },
  { value: 46200000, text: "12:50 PM" },
  { value: 46500000, text: "12:55 PM" },
  { value: 46800000, text: "1:00 PM" },
  { value: 47100000, text: "1:05 PM" },
  { value: 47400000, text: "1:10 PM" },
  { value: 47700000, text: "1:15 PM" },
  { value: 48000000, text: "1:20 PM" },
  { value: 48300000, text: "1:25 PM" },
  { value: 48600000, text: "1:30 PM" },
  { value: 48900000, text: "1:35 PM" },
  { value: 49200000, text: "1:40 PM" },
  { value: 49500000, text: "1:45 PM" },
  { value: 49800000, text: "1:50 PM" },
  { value: 50100000, text: "1:55 PM" },
  { value: 50400000, text: "2:00 PM" },
  { value: 50700000, text: "2:05 PM" },
  { value: 51000000, text: "2:10 PM" },
  { value: 51300000, text: "2:15 PM" },
  { value: 51600000, text: "2:20 PM" },
  { value: 51900000, text: "2:25 PM" },
  { value: 52200000, text: "2:30 PM" },
  { value: 52500000, text: "2:35 PM" },
  { value: 52800000, text: "2:40 PM" },
  { value: 53100000, text: "2:45 PM" },
  { value: 53400000, text: "2:50 PM" },
  { value: 53700000, text: "2:55 PM" },
  { value: 54000000, text: "3:00 PM" },
  { value: 54300000, text: "3:05 PM" },
  { value: 54600000, text: "3:10 PM" },
  { value: 54900000, text: "3:15 PM" },
  { value: 55200000, text: "3:20 PM" },
  { value: 55500000, text: "3:25 PM" },
  { value: 55800000, text: "3:30 PM" },
  { value: 56100000, text: "3:35 PM" },
  { value: 56400000, text: "3:40 PM" },
  { value: 56700000, text: "3:45 PM" },
  { value: 57000000, text: "3:50 PM" },
  { value: 57300000, text: "3:55 PM" },
  { value: 57600000, text: "4:00 PM" },
  { value: 57900000, text: "4:05 PM" },
  { value: 58200000, text: "4:10 PM" },
  { value: 58500000, text: "4:15 PM" },
  { value: 58800000, text: "4:20 PM" },
  { value: 59100000, text: "4:25 PM" },
  { value: 59400000, text: "4:30 PM" },
  { value: 59700000, text: "4:35 PM" },
  { value: 60000000, text: "4:40 PM" },
  { value: 60300000, text: "4:45 PM" },
  { value: 60600000, text: "4:50 PM" },
  { value: 60900000, text: "4:55 PM" },
  { value: 61200000, text: "5:00 PM" },
  { value: 61500000, text: "5:05 PM" },
  { value: 61800000, text: "5:10 PM" },
  { value: 62100000, text: "5:15 PM" },
  { value: 62400000, text: "5:20 PM" },
  { value: 62700000, text: "5:25 PM" },
  { value: 63000000, text: "5:30 PM" },
  { value: 63300000, text: "5:35 PM" },
  { value: 63600000, text: "5:40 PM" },
  { value: 63900000, text: "5:45 PM" },
  { value: 64200000, text: "5:50 PM" },
  { value: 64500000, text: "5:55 PM" },
  { value: 64800000, text: "6:00 PM" },
  { value: 65100000, text: "6:05 PM" },
  { value: 65400000, text: "6:10 PM" },
  { value: 65700000, text: "6:15 PM" },
  { value: 66000000, text: "6:20 PM" },
  { value: 66300000, text: "6:25 PM" },
  { value: 66600000, text: "6:30 PM" },
  { value: 66900000, text: "6:35 PM" },
  { value: 67200000, text: "6:40 PM" },
  { value: 67500000, text: "6:45 PM" },
  { value: 67800000, text: "6:50 PM" },
  { value: 68100000, text: "6:55 PM" },
  { value: 68400000, text: "7:00 PM" },
  { value: 68700000, text: "7:05 PM" },
  { value: 69000000, text: "7:10 PM" },
  { value: 69300000, text: "7:15 PM" },
  { value: 69600000, text: "7:20 PM" },
  { value: 69900000, text: "7:25 PM" },
  { value: 70200000, text: "7:30 PM" },
  { value: 70500000, text: "7:35 PM" },
  { value: 70800000, text: "7:40 PM" },
  { value: 71100000, text: "7:45 PM" },
  { value: 71400000, text: "7:50 PM" },
  { value: 71700000, text: "7:55 PM" },
  { value: 72000000, text: "8:00 PM" },
  { value: 72300000, text: "8:05 PM" },
  { value: 72600000, text: "8:10 PM" },
  { value: 72900000, text: "8:15 PM" },
  { value: 73200000, text: "8:20 PM" },
  { value: 73500000, text: "8:25 PM" },
  { value: 73800000, text: "8:30 PM" },
  { value: 74100000, text: "8:35 PM" },
  { value: 74400000, text: "8:40 PM" },
  { value: 74700000, text: "8:45 PM" },
  { value: 75000000, text: "8:50 PM" },
  { value: 75300000, text: "8:55 PM" },
  { value: 75600000, text: "9:00 PM" },
  { value: 75900000, text: "9:05 PM" },
  { value: 76200000, text: "9:10 PM" },
  { value: 76500000, text: "9:15 PM" },
  { value: 76800000, text: "9:20 PM" },
  { value: 77100000, text: "9:25 PM" },
  { value: 77400000, text: "9:30 PM" },
  { value: 77700000, text: "9:35 PM" },
  { value: 78000000, text: "9:40 PM" },
  { value: 78300000, text: "9:45 PM" },
  { value: 78600000, text: "9:50 PM" },
  { value: 78900000, text: "9:55 PM" },
  { value: 79200000, text: "10:00 PM" },
  { value: 79500000, text: "10:05 PM" },
  { value: 79800000, text: "10:10 PM" },
  { value: 80100000, text: "10:15 PM" },
  { value: 80400000, text: "10:20 PM" },
  { value: 80700000, text: "10:25 PM" },
  { value: 81000000, text: "10:30 PM" },
  { value: 81300000, text: "10:35 PM" },
  { value: 81600000, text: "10:40 PM" },
  { value: 81900000, text: "10:45 PM" },
  { value: 82200000, text: "10:50 PM" },
  { value: 82500000, text: "10:55 PM" },
  { value: 82800000, text: "11:00 PM" },
  { value: 83100000, text: "11:05 PM" },
  { value: 83400000, text: "11:10 PM" },
  { value: 83700000, text: "11:15 PM" },
  { value: 84000000, text: "11:20 PM" },
  { value: 84300000, text: "11:25 PM" },
  { value: 84600000, text: "11:30 PM" },
  { value: 84900000, text: "11:35 PM" },
  { value: 85200000, text: "11:40 PM" },
  { value: 85500000, text: "11:45 PM" },
  { value: 85800000, text: "11:50 PM" },
  { value: 86100000, text: "11:55 PM" }
];

export const udrPermissions = [
  { text: "All", value: "All" , key: "All"},
  { text: "Admin", value: 1 , key: "Admin"},
  { text: "Dentist", value: 2, key: "Dentist" },
  { text: "Orthodontics", value: 3, key: "Orthodontics" },
  { text: "Oral Surgeon", value: 4, key: "Oral Surgeon" },
  { text: "Traveling Team", value: 5, key: "Traveling Team" },
  { text: "Credentialing", value: 6, key: "Credentialing" },
  { text: "View Only", value: 7, key: "View Only" },
  { text: "Regional Manager", value: 8, key: "Regional Manager" }
];

export const allowDeleteUserId = [ 163, 1710, 791, 1189 ];


export const HelpPages = {
  100: { name: "CDP Alerts - Alerts" },
  101: { name: "CDP Alerts - Setup" },
  102: { name: "CDP Alerts - Usage" }
}

export enum HELP_PAGE{
  CDP_ALERTS = 100,
  CDP_ALERTS_SETUP,
  CDP_ALERTS_USAGE
}
