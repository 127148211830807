import * as React from 'react';

export namespace Timer {
  export interface Props{
    refreshTimeLeft: number,
    refreshTimeLeftUp:number,
    refreshTimerEnd:()=>void,
    name: string,
    alertId: number
  }
  export interface State {
    time:{h:number, m:number, s: number},
    seconds: number
  }
}

export class Timer extends React.Component<Timer.Props, Timer.State> {
  constructor(props: Timer.Props, context?: any) {
    super(props, context);
    this.state = { time: {h:0,m:0,s:0}, seconds: 0 };
    this.timer = 0;
  }
    timer:any;
  secondsToTime=(secs)=>{
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      "h": hours,
      "m": minutes,
      "s": seconds
    };
    return obj;
  }

  componentDidMount() {
    const seconds = this.props.refreshTimeLeftUp;
    this.setState({seconds});
    let timeLeftVar = this.secondsToTime(seconds);
    this.setState({ time: timeLeftVar });
    this.startTimer();
  }

  startTimer=()=> {
    if (this.timer == 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown=()=>{
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    if(this.refs[`refreshTime-${this.props.alertId}`]) {
      this.setState({
        time: this.secondsToTime(seconds),
        seconds: seconds,
      });
    }
    // Check if we're at zero.
    if (seconds == 0) {
      clearInterval(this.timer);
      this.props.refreshTimerEnd();
    }
  }

  componentWillUnmount(): void {
    clearInterval(this.timer);
  }

  render() {
    const { time } = this.state;
   // console.log('time: ', time);
    return (
        <div ref={`refreshTime-${this.props.alertId}`}>
          <strong><span>{time.h}:{time.m}:{time.s}</span></strong>
        </div>
    );
  }
}
