import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Template } from '../../components/Template';
import { Card, Table, Button, Input } from 'semantic-ui-react';
import Loading from '../Loading';
import { allowDeleteUserId, HELP_PAGE, udrPermissions } from '../../const';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import { bindActionCreators, Dispatch } from 'redux';
import { UsageActions } from '../../actions';
import { omit } from '../../utils';
import { sortBy } from 'lodash';
import { HelpMark } from '../../components/Help/helpMark';


export namespace Usage {
  export interface Props extends RouteComponentProps<void> {
    usages: RootState.UsageState;
    auth: RootState.AuthState;
    actions: UsageActions;
  }
  export interface State {
    userId: string,
    role: number,
    column: any,
    oldData: [],
    searchKeyword: string,
    data: any,
    order: boolean,
    showHidden: boolean,
    showInactive: boolean,
    allowDelete: boolean
  }
}

@(connect(
  (state: RootState, ownProps): Pick<Usage.Props, 'usages'> => {
    return { usages: state.usages };
  },
  (dispatch: Dispatch): Pick<Usage.Props, 'actions'> => {
    return {
      actions: bindActionCreators(omit(UsageActions, 'Type'), dispatch)
    }
  }
) as any)
export class Usage extends React.Component<Usage.Props, Usage.State> {
  static defaultProps: Partial<Usage.Props> = {

  };

  constructor(props: Usage.Props, context?: any) {
    super(props, context);
    this.state={
      userId: '',
      role: 2,
      column: null,
      oldData: [],
      data: null,
      order: true,
      showHidden: false,
      showInactive: false,
      searchKeyword:'',
      allowDelete: (props.auth && allowDeleteUserId.indexOf(props.auth.id) >= 0),
    };

    if (props.auth && props.auth.id) {
      props.history.push('/usage');
    }
    localStorage.setItem("prevPath",'/usage');
  }

  componentDidMount = () => {
    this.fetchUsageData();
  };

  fetchUsageData = (id?, role?) => {
    role = udrPermissions[role] ? udrPermissions[role].text : 'All';
    (this.props.actions.getUsageData(id, role) as any)
      .then(res => {
        let tableData = this.setTableData(res);
        tableData = sortBy(tableData, ["date"]);
        tableData.reverse();
        this.setState({oldData: tableData, data: tableData});
      })
      .catch(err => {
        console.log('Found error on get billingData', err);
      });
  };

  setTableData = (data) => {
    let dataArray:any = [];
    if (Object.keys(data).length) {
      Object.keys(data).forEach((key) => {
        const item = data[key];
        let flag = true;
        if (this.state.userId && this.state.userId !== 'All' && item.value !== this.state.userId) {
          flag = false;
        }
        if (flag && item.text !== 'All') {
          dataArray.push({
            firstName: item.firstName,
            lastName: item.lastName,
            userId: item.userId,
            hideUsage: item.hideUsage,
            date: item.Date,
            total: item.total,
            Cell: item.Cell,
            Desktop: item.Desktop,
            deviceOS: item.deviceOS
          });
        }
      });
    }
    return dataArray;
  };

  search = (e) => {
    const keyword = e.target.value.toLowerCase();
    let data:any = this.state.oldData;
    if (keyword && keyword.length) {
      data = data.filter(function (element:any) {
        return (element.firstName.toLowerCase().indexOf(keyword) >= 0 || element.lastName.toLowerCase().indexOf(keyword) >= 0) ? true : false;
      });
    }
    let sortData = sortBy(data, [this.state.column]);
    if (!this.state.order)
      sortData = sortData.reverse();
    this.setState({searchKeyword: keyword, data: sortData});
  };

  handleSort = clickedColumn => () => {
    const {column, data, order} = this.state;
    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: sortBy(data, [clickedColumn]),
        order: true,
      });

      return
    }

    this.setState({
      data: data.reverse(),
      order: !order
    })
  };

  onchangeUser = (e, {value}) => {
    const {role} = this.state;
    this.setState({userId: value});
    this.fetchUsageData(value, role);
  };

  flushData = () => {
    if (confirm("Are you sure you want to clear all usage data? Action will not undo.")) {
      const {userId, role} = this.state;
      (this.props.actions.flushUsageData({userId, role}) as any)
        .then((res) => {
          if (res) this.fetchUsageData(userId, role);
        });
    }
  };


  onChangeHide = (e, id) => {
    let obj = {
      hideUsage: (e.target.value === 'true'),
      userId: id
    };
    (this.props.actions.hideUsageReport(obj) as any)
      .then((res) => {
        if (res) this.fetchUsageData();
      });
  };

  showHiddenReports = (e) => {
    this.setState({showHidden: (e.target.value === 'true')});
  };
  render() {
    const { isLoad } = this.props.usages;
    const { data, column, order, allowDelete, showHidden } = this.state;
    const direction = order ? 'down' : 'up';
    return (
        <Template activeLink="usage" history={this.props.history}>
          <Card className="pageTitle">
            <Card.Content className="pb0">
              <h2 className="float-left">Usage
                <HelpMark helpPage={HELP_PAGE.CDP_ALERTS_USAGE}  application='Alerts-Usage' />
              </h2>
              <div className="topFilters">
                {allowDelete &&
                <span>Show Hidden Reports <input className='mt10 mr10' type="checkbox" name="showHidden"
                                                 defaultChecked={(showHidden)}
                                                 onChange={(e) => this.showHiddenReports(e)}
                                                 defaultValue={(!showHidden)? 'true': 'false'}/></span>}
                {allowDelete && <Button primary onClick={this.flushData}>Flush Data</Button>}
                <Input className='mb10' icon='search' placeholder="Search" onChange={this.search}/>
                {isLoad && <Loading/>}
              </div>
            </Card.Content>
          </Card>
          <div className="ui card">
            <div className="content">
              <div className="table-responsive">
                <Table basic='very' selectable unstackable className="customTable">
                  <Table.Header>
                    <Table.Row>
                      {allowDelete && <Table.HeaderCell>
                        <div>Hide</div>
                      </Table.HeaderCell>}
                      <Table.HeaderCell
                        className={`cursorPointer ${column === 'lastName' ? 'active' : ''}`}
                        onClick={this.handleSort('lastName')}>
                        <div>
                          Name
                          <i className={`sort amount ${direction} icon ml10`} />
                        </div>
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        className={`cursorPointer ${column === 'date' ? 'active' : ''}`}
                        onClick={this.handleSort('date')}>
                        Last Login date
                        <i className={`sort amount ${direction} icon ml10`} />
                      </Table.HeaderCell>
                      <Table.HeaderCell className={`cursorPointer`}>Total Access</Table.HeaderCell>
                      <Table.HeaderCell className={`cursorPointer`}>Mobile Count</Table.HeaderCell>
                      <Table.HeaderCell className={`cursorPointer`}>Desktop Count</Table.HeaderCell>
                      <Table.HeaderCell className={`cursorPointer ${column === 'deviceOS' ? 'active' : ''}`}
                                        onClick={this.handleSort('deviceOS')}
                      >
                        <div>
                          Recent Mobile OS
                          <i className={`sort amount ${direction} icon ml10`} />
                        </div>
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  {(data && data.length) ?
                    <Table.Body>
                      {data.map((item, key) => {
                          if (item.hideUsage && !showHidden) {
                            return true;
                          }
                          return (<Table.Row key={key}>
                            {allowDelete &&
                            <Table.Cell><input key={item.userId} className='mt10 ml20'
                                               type="checkbox" name={`hideUsage[${item.userId}]`}
                                               defaultChecked={(item.hideUsage)}
                                               onChange={(e) => this.onChangeHide(e, item.userId)}
                                               defaultValue={(!item.hideUsage ? 'true': 'false')}/></Table.Cell>}
                            <Table.Cell>{item.firstName} {item.lastName}</Table.Cell>
                            <Table.Cell>{item.date}</Table.Cell>
                            <Table.Cell>{item.total}</Table.Cell>
                            <Table.Cell>{item.Cell}</Table.Cell>
                            <Table.Cell>{item.Desktop}</Table.Cell>
                            <Table.Cell>{item.deviceOS}</Table.Cell>
                          </Table.Row>)
                        }
                      )}
                    </Table.Body>
                    :
                    <Table.Body>
                      <Table.Row><Table.Cell>No record found</Table.Cell></Table.Row></Table.Body>
                  }
                </Table>
              </div>
            </div>
          </div>
      </Template>
    );
  }
}
