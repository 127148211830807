import { createAction } from 'redux-actions';
import { getReq, postReq, putReq } from '../api';

export namespace HelpActions {
  export enum Type {
    GET_HELP_SECTIONS = 'GET_HELP_SECTIONS'
  }

  const getHelpSectionAction = createAction(Type.GET_HELP_SECTIONS);

  export const getSections = (params?) => async (dispatch: Function) => {
    try {
      const res = await getReq('help/sections', params);
      if (res) {
        dispatch(getHelpSectionAction(res));
        return res;
      }
    } catch (error) {
      return error;
    }
  };

  export const saveSection = (obj) => async (dispatch) => {
    try {
      return await postReq(`help/sections`, obj);
    } catch (err) {
      console.error(err);
      return err;
    }
  };

  export const updateSection = (obj) => async (dispatch) => {
    try {
      return await putReq(`help/sections`, obj);
    } catch (err) {
      console.error(err);
      return err;
    }
  };
}

export type HelpActions = Omit<typeof HelpActions, 'Type'>;
