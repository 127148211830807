import * as React from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';
import { RouteComponentProps } from 'react-router';


export namespace CookieModal {
export interface Props extends RouteComponentProps<void> {
    handleClose : Function
    }
}

 export class CookieModal extends React.Component<CookieModal.Props, any> {
  static defaultProps: Partial<CookieModal.Props> = {};

  constructor(props: CookieModal.Props, context?: any) {
        super(props, context);
     }


  render(){
        return (
            <Modal className={'modalCenter'}
            onClose={() => this.props.handleClose()}
               open={true}
        >
          <Modal.Header>Cookies are disabled in browser window.</Modal.Header>
          <Modal.Content >
            <Modal.Description>
              <p>
                it seems like Cookies are disable on your browser. Please enable by clicking on the
                EYE <Icon name={"eye slash"}></Icon> on the top right corner of the browser window OR by going into the settings.
              </p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              content="OK"
              primary={true}
              onClick={() => this.props.handleClose()}
            />
          </Modal.Actions>
        </Modal>
      );
    }
  }
