import * as React from 'react';
import { Grid, Modal } from 'semantic-ui-react';

export namespace ViewNotesModal {
  export interface Props{
    dataNotes: '' | any ,
    closeModal:()=>void
  }
}

export class ViewNotesModal extends React.Component<ViewNotesModal.Props, any> {
  static defaultProps: Partial<ViewNotesModal.Props> = {

  };
  constructor(props: ViewNotesModal.Props, context?: any) {
    super(props, context);

  }

  render() {
    const {dataNotes, closeModal} = this.props;
    return (
      <Modal
        open={true}
        onClose={closeModal}
        aria-labelledby="ModalHeader"
        centered
        closeIcon
        size="large"
      >
        <Modal.Header>
         Notes
        </Modal.Header>
        <Modal.Content scrolling>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                {
                    <div>{dataNotes}</div>
                }
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}
