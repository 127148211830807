import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Grid } from 'semantic-ui-react';
import { bindActionCreators, Dispatch } from 'redux';
import { UserActions } from '../../actions';
import { omit } from '../../utils';
import { RootState } from '../../reducers';
import { GoogleLogin, CredentialResponse } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode'; 

import 'app/styles/css/style.css';
import { CookieModal } from '../CookieModal';
const logoLogin = require('../../styles/images/logoMain.png');

export namespace SignIn {
  export interface Props extends RouteComponentProps<void> {
    auth: RootState.AuthState;
    actions: UserActions;
  }
}

@(connect(
  (state: RootState): Pick<SignIn.Props, 'auth'> => {
    return { auth: state.auth };
  },
  (dispatch: Dispatch): Pick<SignIn.Props, 'actions'> => ({
    actions: bindActionCreators(omit(UserActions, 'Type'), dispatch)
  })
) as any)
export class SignIn extends React.Component<SignIn.Props, any> {
  static defaultProps: Partial<SignIn.Props> = {};

  constructor(props: SignIn.Props, context?: any) {
    super(props, context);
    this.state = {
      cookieModal: false
    };
  }

  componentWillMount = () => {
    const { pathname } = window.location;
    const parts = pathname.split('/');
    const accessToken = parts[parts.length - 1];
    if (accessToken.length > 0) {
      this.props.actions.signInWithToken(accessToken);
    }
  };

  componentDidMount = () => {
    this.props.actions.loadUser();
  };

  doLogin = (credentialResponse: CredentialResponse) => {
    const { credential } = credentialResponse;
    if (!credential) {
      console.error('No credential returned from Google Login');
      return;
    }
    const decoded: any = jwtDecode(credential);
    const profileObj = {
      email: decoded.email,
      firstname: decoded.name,
      profilePicture: decoded.picture,
    };
    if (credential) {
     // console.log('profileObj', profileObj);
      this.props.actions.signIn(profileObj);
    } else {
      console.error('No credential returned from Google Login');
    }
  };

  onFailure = () => {
    this.setState({ cookieModal: true });
  };

  closeCookieModal = () => {
    this.setState({ cookieModal: false });
  };

  render() {
    const { auth, history } = this.props;
    const { cookieModal } = this.state;

    if (auth && auth.id) {
      const prevPath = localStorage.getItem('prevPath');
      if (prevPath) {
        history.push(prevPath);
      } else {
        history.push('/alert');
      }
    }

    return (
      <div className="signin">
        <div className="bgImage">
          <Grid verticalAlign='middle' centered style={{ height: '100vh' }}>
            <Grid.Row>
              <Grid.Column textAlign='center'>
                <img className="logoSignIn" src={logoLogin}/>
                <div className="clearfix"></div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <GoogleLogin
                    onSuccess={this.doLogin}
                    onError={this.onFailure}
                    useOneTap
                  />
                </div>
                {cookieModal && <CookieModal handleClose={this.closeCookieModal}/>}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}
