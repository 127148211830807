import { getReq } from '../api';

export namespace RegionActions {
  export enum Type {
  }

  export const fetchRegions = () => async (dispatch: Function) => {
    try {
      return await getReq(`account/regionList`, '');
    } catch (err) {
      console.log('Error occurred', err);
      return err;
    }
  };
}
export type RegionActions = Omit<typeof RegionActions, 'Type'>;
