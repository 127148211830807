import * as React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { SignIn } from './components/SignIn';
import { Usage } from './components/Usage';
import { Alert } from './components/Alert';
import { Setup } from './components/Setup';

export const App = () => (
  <GoogleOAuthProvider clientId="534451231648-d2a526h66llkn1e781gv6fgjvofsph2c.apps.googleusercontent.com">
  <BrowserRouter>
    <Switch>
      <Route exact path="/" render={() => <Redirect to="/signin" />} />
      <Route path="/signin" component={SignIn} />
      <Route path="/setup" component={Setup} />
      <Route path="/usage" component={Usage} />
      <Route path="/alert" component={Alert} />
      <Route path="/:token" component={SignIn} />
      </Switch>
    </BrowserRouter>
  </GoogleOAuthProvider>
);

export default App;