import * as React from 'react';
import { Button, Modal, Header, Form, Input, Dropdown, TextArea } from 'semantic-ui-react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { PracticeActions } from '../../actions';
import { RegionActions } from '../../actions';
import { omit } from '../../utils';
import { bindActionCreators, Dispatch } from 'redux';
import { ChangeEvent } from 'react';


export namespace FeedbackModal {
  export interface Props extends RouteComponentProps<void> {
    practicesActions: PracticeActions,
    regionsActions: RegionActions,
    hideFeedbackModal: any,
    onClickFeedbackPage: any,
    onChangeFeedback: any
  }
}

@(connect(
  (): Pick<FeedbackModal.Props, any> => {
    return {};
  },
  (dispatch: Dispatch): Pick<FeedbackModal.Props, 'practicesActions'> => ({
    practicesActions: bindActionCreators(omit(PracticeActions, 'Type'), dispatch)
  })
) as any)
export class FeedbackModal extends React.Component<FeedbackModal.Props, any> {

  async componentDidMount() {
    await this.getPractices();
  }

  static defaultProps: Partial<FeedbackModal.Props> = {};

  constructor(props: FeedbackModal.Props, context?: any) {
    super(props, context);
  }

  state = {
    practices: [] as any,
    regions: [] as any,
    filteredPractices: [] as any,
    loading: false,
    practiceOptions: [],
    formData: {} as any
  };

  async getPractices() {
    const { practicesActions } = this.props;
    const practices: any = await practicesActions.fetchPractices();
    if(!Array.isArray(practices))
      return;
    this.loadPractices(practices);
  }

  async getRegions() {
    const { regionsActions } = this.props;
    const regions: any = await regionsActions.fetchRegions();
    if(!Array.isArray(regions))
      return;
    this.loadRegions(regions);
  }


  loadPractices = (practices: any[]) => {
    let practiceOptions = practices.map((practice, key) => ({
      text: practice.practice,
      value: practice.practice,
      key
    }));
    practiceOptions = [
      { text: 'Support', value: 'Support', key: practiceOptions.length + 1 },
      ...practiceOptions
    ];
    this.setState({ practices, practiceOptions });
  }

  loadRegions = (regions: any[]) => {
    let regionOptions = regions.map((region, key) => ({
      text: region.name,
      value: region.name,
      key
    }));
    regionOptions = [
      { text: 'Support', value: 'Support', key: regionOptions.length + 1 },
      ...regionOptions
    ];
    this.setState({ regions, regionOptions });
  }


  onChange = (e: any, data: any) => {
    let formData = this.state.formData;
    formData[data.name] = data.value;
    this.setState({ ...this.state, formData });
    this.props.onChangeFeedback(formData);
  };

  onFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    e.persist();
    if (!e.target || !e.target.files) return;
    const value = await this.fileToBase64(e.target.files[0]);
    this.onChange(null, { name: 'file', value });
  };

  fileToBase64 = async (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (e) => reject(e);
    });


  render() {
    return (
      <Modal
        open={true}
        onClose={this.props.hideFeedbackModal}
        centered={false}
        size='tiny'
        closeIcon
      >
        <Header content='Feedback' />
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>Practice/Support</label>
              <Dropdown
                search={true}
                name='practice'
                placeholder='Practice'
                selection={true}
                options={this.state.practiceOptions}
                onChange={this.onChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Subject</label>
              <Input fluid={true} name='subject' placeholder='Subject' onChange={this.onChange} />
            </Form.Field>
            <Form.Field>
              <label>Message</label>
              <TextArea rows={6} fluid={true} name='message' onChange={this.onChange} />
            </Form.Field>
            <Form.Field>
              <label>Attachments</label>
              <input
                name='file'
                type='file'
                onChange={this.onFileChange}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button secondary onClick={this.props.hideFeedbackModal}>
            Cancel
          </Button>
          <Button className='ui primary button' onClick={this.props.onClickFeedbackPage}>
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
