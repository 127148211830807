import { combineReducers } from 'redux';
import { RootState } from './state';
import { authReducer } from './auth';
import { alertReducer } from './alerts';
import { usageReducer } from './usages';

// Change export to export type
export type { RootState };

export const rootReducer = combineReducers<RootState>({
  auth: authReducer as any,
  alerts: alertReducer as any,
  usages: usageReducer as any
});
