import * as React from 'react';
import {Button, Modal, Dropdown, Header, Grid, Form, Checkbox} from "semantic-ui-react";

export namespace DefaultSetupModal {
  export interface Props{
    hideDefaultSetupModal:()=>void,
    onChangeDefaultSetup:(e:any, val:any)=>void,
    onClickDefaultSetup:()=>void,
    practices: any,
    regions: any,
    brands:any,
    groups : any,
    alertSetup : any,
    selectedRegion: any,
    selectedPractice:any,
    selectedDepartment:any,
    selectedBrand:any,
    checkedTrigger:any
  }
}
export class DefaultSetupModal extends React.Component<DefaultSetupModal.Props, any> {
  static defaultProps: Partial<DefaultSetupModal.Props> = {

  };
  constructor(props: DefaultSetupModal.Props, context?: any) {
    super(props, context);
  }

    render() {
      const { regions, practices, departments,brands, triggedAlert,selectedRegion, selectedPractice,selectedDepartment, selectedBrand,checkedTrigger } = this.props.alertSetup;
      const practiceSelected  =  selectedPractice ? selectedPractice && selectedPractice.split(',') :practices && practices.split(',');
      const regionSelected  =  selectedRegion ? selectedRegion && selectedRegion.split(',') :regions && regions.split(',');
      const departmentSelected  =  selectedDepartment ? selectedDepartment && selectedDepartment.split(',').map(Number) :departments && departments.split(',').map(Number);
      const brandSelected  =  selectedBrand ? selectedBrand && selectedBrand.split(',') :brands && brands.split(',');



        return (
            <Modal open={true} onClose={this.props.hideDefaultSetupModal} size='mini' centered  closeIcon >
              <Header content='Default Setup for Alerts'/>
              <Modal.Content>
                <Form className="formStyle mt10">
                  <Grid>
                  <Grid.Row>
                      <Grid.Column>
                        <label className="mr10">Select Default Region</label>
                        <Dropdown className='mr10' name='region' fluid placeholder='Select Region' multiple search selection options={this.props.regions}
                                  defaultValue={regionSelected}
                                  onChange={this.props.onChangeDefaultSetup}/>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <label className="mr10">Select Default Practices</label>
                        <Dropdown className='mr10' name='practice' fluid placeholder='Select Practice' multiple search selection options={this.props.practices}
                                  defaultValue={practiceSelected}
                                  onChange={this.props.onChangeDefaultSetup}/>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <label className="mr10">Select Default Departments</label>
                        <Dropdown className='mr10' name='department' fluid placeholder='Select Departments' multiple search selection options={this.props.groups}
                                  defaultValue={departmentSelected}
                                  onChange={this.props.onChangeDefaultSetup}/>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <label className="mr10">Select Default Brands</label>
                        <Dropdown className='mr10' name='brand' fluid placeholder='Select Brands' multiple selection options={this.props.brands}
                                  defaultValue={brandSelected}
                                  onChange={this.props.onChangeDefaultSetup}/>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <label className="mr10">Trigged Alert Only</label>
                        <Checkbox name='trigged' onChange={this.props.onChangeDefaultSetup} required defaultChecked={checkedTrigger? checkedTrigger : triggedAlert} />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button className="ui primary button" onClick={this.props.onClickDefaultSetup}>
                  Save
                </Button>
              </Modal.Actions>
            </Modal>
        );
    }
}
