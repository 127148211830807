import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import { omit } from '../../utils';
import { bindActionCreators, Dispatch } from 'redux';
import Loading from '../Loading';
import { HelpActions } from '../../actions';
import { Icon, Modal } from 'semantic-ui-react';
import { HELP_PAGE, HelpPages } from '../../const';
import { ViewFileModal } from '../../components/Help/viewFileModal';


export namespace HelpPageModal {
  export interface Props extends RouteComponentProps<void> {
    auth: RootState.AuthState,
    actions: HelpActions,
    hideHelpPageModal: any,
    helpPage: HELP_PAGE
  }
}

@(connect(
  (state: RootState): Pick<HelpPageModal.Props, any> => ({
    auth: state.auth
  }),
  (dispatch: Dispatch): Pick<HelpPageModal.Props, 'actions'> => ({
    actions: bindActionCreators(omit(HelpActions, 'Type'), dispatch)
  })
) as any)
export class HelpPageModal extends React.Component<HelpPageModal.Props, any> {

  static defaultProps: Partial<HelpPageModal.Props> = {};

  constructor(props: HelpPageModal.Props, context?: any) {
    super(props, context);
    this.state = { openFileModal: false, files: null };
  }


  componentWillMount = async () => {
    await this.fetchData();
  };

  fetchData = async () => {
    const { actions, helpPage } = this.props;
    const res: any = await actions.getSections({ id: helpPage });
    if (res && res.length) {
      res.forEach(item => {
        item.page = (
          (item.parentPageId && HelpPages[item.parentPageId] && HelpPages[item.helpPage]) ?
            `${HelpPages[item.parentPageId].name} > ${HelpPages[item.helpPage].name}` :
            HelpPages[item.helpPage] ? HelpPages[item.helpPage].name : ''
        );
      });
    }
    this.setState({ data: res });
  };

  openFileModal = (type, files) => {
    this.setState({ openFileModal: type, files: files });
  };

  closeFilesModal = () => {
    this.setState({ openFileModal: false, files: null });
  };

  render() {
    const { hideHelpPageModal } = this.props;
    const { data, openFileModal, files, loading } = this.state;
    return (
      <Modal
        open={true}
        onClose={hideHelpPageModal}
        centered={false}
        size='large'
        closeIcon
      >
        {loading && <Loading />}
        <Modal.Content>
          <h3 className='text-center mb20'>HELP</h3>
          <ul className='helpList mb-0'>
            {(data && data.length) ?
              data.map((item, key) => {
                return (
                  <li key={key} className='mb10 mt10'>
                    <strong>{item.heading}</strong>
                    <div className='mb10'>
                      {item.description && <p className='mb-2'>{item.description}</p>}
                      {(item.fileLinks && item.fileLinks.toLowerCase().indexOf('.mp4') > 0) &&
                      <span onClick={() => this.openFileModal('Video', item.fileLinks)}
                            className='cursorPointer'><Icon className='primaryColor mr-2'
                                                            name='play circle' /><span
                        className='linkText'>Watch Video</span></span>}
                      {(item.fileLinks && item.fileLinks.toLowerCase().indexOf('.pdf') > 0) &&
                      <span onClick={() => this.openFileModal('PDF', item.fileLinks)}
                            className='cursorPointer'><Icon className='primaryColor mr-2'
                                                            name='file pdf' /><span
                        className='linkText'>View PDF</span></span>}
                    </div>
                    <br/>
                  </li>
                );
              })
              :
              <div>No record found.</div>
            }
          </ul>
        </Modal.Content>
        {openFileModal && <ViewFileModal closeModal={this.closeFilesModal} files={files} type={openFileModal} />}
      </Modal>
    );
  }
}

