import { handleActions } from 'redux-actions';
import { RootState } from './state';
import { AlertActions } from '../actions/alerts';
import { AlertsModel } from '../models';
import { UsageActions } from '../actions';

const initialState: RootState.AlertState = {
  isLoad: false,
  alerts:[],
  testAlert:{},
  alertHistory:{},
  triggeredAlerts:{},
  id:0,
  patientValidationStatus:[],
};

export const alertReducer = handleActions<RootState.AlertState, AlertsModel>(
  {
    [AlertActions.Type.GET_ALERTS]: (state:any, action:any) => {
      if(action.payload && action.payload.data) {
        return { ...state, isLoad: false, alerts: action.payload.data };
      }
    },
    [AlertActions.Type.TEST_ALERTS]: (state:any, action:any) => {
      if(action.payload && action.payload.data){
        return { ...state, isLoad: false, testAlert: action.payload.data };
      }
    },
    [AlertActions.Type.LOAD_ALERTS_HISTORY]: (state:any, action:any) => {
      if(action.payload && action.payload.data){
        return { ...state, isLoad: false, alertHistory: action.payload.data };
      }
    },
    [AlertActions.Type.LOAD_ALERTS]: (state, action) => {
      return { ...state, isLoad: true};
    },
    [AlertActions.Type.GET_TRIGGERED_ALERTS]: (state:any, action:any) => {
      if(action.payload && action.payload.data) {
        return { ...state, isLoad: false, triggeredAlerts: action.payload.data };
      }
    },
    [UsageActions.Type.GET_PATIENT_VALIDATION_STATUS]: (state:any, action:any) => {
      if(action.payload && action.payload.data) {
        return { ...state, isLoad: false, patientValidationStatus: action.payload.data };
      }
    },
    [UsageActions.Type.LOAD_PATIENT_VALIDATION_STATUS]: (state: any, action: any) => {
      return { ...state, isLoad: true};
    },
  },
  initialState
);
