import * as React from 'react';
import { Grid, Modal, Button } from 'semantic-ui-react';

export namespace DeleteModal {
  export interface Props{
    hideDeleteModal:()=>void
    deleteAlertHandle:()=>void,
    popupHeader: string|null|undefined
  }
}

export class DeleteModal extends React.Component<DeleteModal.Props, any> {
  static defaultProps: Partial<DeleteModal.Props> = {

  };
  constructor(props: DeleteModal.Props, context?: any) {
    super(props, context);
  }


  render() {
    const { hideDeleteModal, deleteAlertHandle, popupHeader } = this.props;
    return (
      <Modal
        open={true}
        onClose={hideDeleteModal}
        aria-labelledby="ModalHeader"
        centered
        closeIcon
        size="mini"
      >
        <Modal.Header>
          {popupHeader && popupHeader.length > 0 ? popupHeader : 'Delete Alert'}
        </Modal.Header>
        <Modal.Content scrolling>
          <Grid>
            <label>Warning, this cannot be undone!</label>
            <Grid.Row>
              <Grid.Column>
                Are you sure you want to delete?
              </Grid.Column>
          </Grid.Row>
        </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button secondary onClick={hideDeleteModal}>Cancel</Button>
          <Button negative onClick={deleteAlertHandle}>Delete</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
