import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Card, Icon, Table } from 'semantic-ui-react';
import {PopUpModal} from '../Setup/popUpModal';
import {DeleteModal} from '../Setup/deleteModal';
const moment = require('moment');


export module AlertList {
  export interface Props extends RouteComponentProps<void> {
    alerts: any,
    order:boolean,
    column:string,
    groupList : any,
    openAlertForm: (val: any) => void,
    deleteRecord: (val: number) => void,
    handleSort: (val: string) => void,
    carrierList:any
  }

  export interface State {
    showPopUp: boolean,
    showDeleteModal: boolean,
    alertId: number,
    popUpData: any,
    popUpHeader: string
  }
}


export class AlertList extends React.Component<AlertList.Props, AlertList.State> {
  static defaultProps: Partial<AlertList.Props> = {};

  constructor(props: AlertList.Props, context?: any) {
    super(props, context);
    this.state={
      showPopUp: false,
      showDeleteModal: false,
      alertId: 0,
      popUpData:'',
      popUpHeader:''
    }
  }

  closePopUpModal =()=>{
    this.setState({ showPopUp: false });
  };

  hideDeleteModal =()=>{
    this.setState({ showDeleteModal: false });
  };

  deleteAlertHandle =(id:number)=>{
    this.setState({ showDeleteModal: true, alertId:id });
  };

  deleteRecord =()=>{
    const {alertId} = this.state;
    this.props.deleteRecord(alertId);
    this.setState({ showDeleteModal: false, alertId:0 });
  };

  openPopUpModal=(e:any, data:any, popUpHeader)=>{
    e.preventDefault();
    this.setState({ showPopUp: true, popUpData:data, popUpHeader });
  };

  render() {
    const { openAlertForm, alerts, order, column, groupList, carrierList } = this.props;
    const {showPopUp, popUpData, popUpHeader, showDeleteModal} = this.state;
    const direction = order ? 'down' : 'up';

    return (
      <div className='AlertList'>
        <Card>
          <Card.Content>
            <div className="table-responsive FreezeTable">
              <Table className="customTable" unstackable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell><div style={{minWidth: '55px'}}>Action</div></Table.HeaderCell>
                    <Table.HeaderCell className={`cursorPointer ${column === 'name' ? 'active' : ''}`} onClick={()=>this.props.handleSort('name')}>
                      <div>Name <i className={`sort amount icon ml10 ${direction}`}/></div></Table.HeaderCell>
                    <Table.HeaderCell className={`cursorPointer ${column === 'practice' ? 'active' : ''}`} onClick={()=>this.props.handleSort('practice')}><div>Triggered Practice<i className={`sort amount icon ml10 ${direction}`}/></div></Table.HeaderCell>
                    <Table.HeaderCell className={`cursorPointer ${column === 'practice' ? 'active' : ''}`} onClick={()=>this.props.handleSort('alertStatus')}><div>Triggered Status<i className={`sort amount icon ml10 ${direction}`}/></div></Table.HeaderCell>
                    <Table.HeaderCell><div>Alert Status</div></Table.HeaderCell>
                    <Table.HeaderCell><div>IsActive</div></Table.HeaderCell>
                    <Table.HeaderCell><div>View PrevMonth</div></Table.HeaderCell>
                    <Table.HeaderCell><div>Filter Column</div></Table.HeaderCell>
                    <Table.HeaderCell><div>Description</div></Table.HeaderCell>
                    <Table.HeaderCell className={`cursorPointer ${column === 'howToFix' ? 'active' : ''}`} onClick={()=>this.props.handleSort('howToFix')}><div>How to Fix <i className={`sort amount icon ml10 ${direction}`}/></div></Table.HeaderCell>
                    <Table.HeaderCell><div>Notification</div></Table.HeaderCell>
                    <Table.HeaderCell><div>Query</div></Table.HeaderCell>
                    <Table.HeaderCell className={`cursorPointer ${column === 'startTime' ? 'active' : ''}`} onClick={()=>this.props.handleSort('startTime')}><div>Start Time<i className={`sort amount icon ml10 ${direction}`}/></div></Table.HeaderCell>
                    <Table.HeaderCell className={`cursorPointer ${column === 'endTime' ? 'active' : ''}`} onClick={()=>this.props.handleSort('endTime')}><div>End Time<i className={`sort amount icon ml10 ${direction}`}/></div></Table.HeaderCell>
                    <Table.HeaderCell className={`cursorPointer ${column === 'frequency' ? 'active' : ''}`} onClick={()=>this.props.handleSort('frequency')}><div>Frequency<i className={`sort amount icon ml10 ${direction}`}/></div></Table.HeaderCell>
                    <Table.HeaderCell className={`cursorPointer ${column === 'department' ? 'active' : ''}`} onClick={()=>this.props.handleSort('department')}><div>Department<i className={`sort amount icon ml10 ${direction}`}/></div></Table.HeaderCell>
                    <Table.HeaderCell><div>Failure</div></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {
                    alerts && alerts.length > 0 && alerts.map((alert: any, key: number) => {
                     const department = alert.department && alert.department.split(',');
                     let departmentSelected = '';
                      department.forEach((val, index) => {
                        if(index !==0 && index !== department.length ){
                          departmentSelected += ', ';
                        }
                        departmentSelected += groupList[val];
                      })
                      return (
                        <Table.Row key={key}>
                          <Table.Cell><Icon className="mr10 cursorPointer" onClick={() => openAlertForm(key)} name="edit"/>
                            <Icon className="mr10 cursorPointer" onClick={() => this.deleteAlertHandle(alert.id)} name="trash alternate"/></Table.Cell>
                          <Table.Cell>{alert.name}</Table.Cell>
                          <Table.Cell>{alert.practice}</Table.Cell>
                          <Table.Cell>{alert.alertStatus? <span className='red-backgroud'/> : <span className='green-backgroud'/>}</Table.Cell>
                          <Table.Cell>{alert.alertStatus? 'YES' : 'NO'}</Table.Cell>
                          <Table.Cell>{alert.isActive? 'YES' : 'NO'}</Table.Cell>
                          <Table.Cell>{alert.prevMonth?'YES' : 'NO'}</Table.Cell>
                          <Table.Cell>{alert.prevMonthNameFilter}</Table.Cell>
                          <Table.Cell><a href ='#' onClick={(e)=>{this.openPopUpModal(e, alert.description, 'Description')}}> Click here</a></Table.Cell>
                          <Table.Cell><a href ='#' onClick={(e)=>{this.openPopUpModal(e, {howToFix: alert.howToFix, fileLinks: alert.fileLinks}, 'How to Fix')}}> Click here</a></Table.Cell>
                          <Table.Cell>{alert.notification}</Table.Cell>
                          <Table.Cell><a href ='#' onClick={(e)=>{this.openPopUpModal(e, alert.query, 'Query')}}> Click here</a></Table.Cell>
                          <Table.Cell>{alert.startTime ? moment(alert.startTime).utc().format('hh:mm A') : ''}</Table.Cell>
                          <Table.Cell>{alert.endTime ? moment(alert.endTime).utc().format('hh:mm A') : ''}</Table.Cell>
                          <Table.Cell>{alert.frequency} Min</Table.Cell>
                          <Table.Cell>{departmentSelected}</Table.Cell>
                          <Table.Cell>{alert.failure}</Table.Cell>
                        </Table.Row>
                      );
                    })
                  }
                </Table.Body>
              </Table>
            </div>
          </Card.Content>
        </Card>
        {showPopUp && <PopUpModal closeModal={this.closePopUpModal} data={popUpData} popUpHeader={popUpHeader} carrierList={carrierList}/>}
        {showDeleteModal && <DeleteModal hideDeleteModal={this.hideDeleteModal} deleteAlertHandle={this.deleteRecord}/>}
      </div>
    );
  }
}
