import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { AlertActions } from '../../actions';
import { omit } from '../../utils';
import { Template } from '../Template';
import { RootState } from '../../reducers';
import { AlertList } from '../AlertList';
import {AlertFormModal} from './alertFormModal';
import { Card, Button, Dropdown, Input, Checkbox } from 'semantic-ui-react';
import { sortBy } from 'lodash';
import { HELP_PAGE } from '../../const';
import { HelpMark } from '../Help/helpMark';
import { CountUpTimer } from '../CountUpTimer';


export namespace Setup {
  export interface Props extends RouteComponentProps<void> {
    alerts: RootState.AlertState;
    usages: RootState.UsageState;
    actions: AlertActions;
    auth: RootState.AuthState;
  }

  export interface State {
    filters:{practice:[], department:[],isActive: boolean},
    data: any,
    openAlertForm: boolean,
    action:string,
    addAlert: any,
    editAlert: any,
    searchKeyword: string,
    column: string,
    order: boolean,
    denominatorCount: number,
    count: number,

  }
}



@(connect(
  (state: RootState, ownProps): Pick<Setup.Props, any> => {
    return { alerts: state.alerts, usages: state.usages };
  },
  (dispatch: Dispatch): Pick<Setup.Props, 'actions'> => {
    return {
      actions: bindActionCreators(omit(AlertActions, 'Type'), dispatch)
    }
  }
) as any)

export class Setup extends React.Component<Setup.Props, Setup.State> {
  static defaultProps: Partial<Setup.Props> = {

  };

  constructor(props: Setup.Props, context?: any) {
    super(props, context);
    this.state ={
      filters:{practice:[], department:[],isActive: false},
      openAlertForm: false,
      action:'',
      addAlert: {},
      editAlert: {},
      data: [],
      searchKeyword: '',
      column:'',
      order: true,
      denominatorCount: 0,
      count: 0,
    }

    if (props.auth && props.auth.id) {
      props.history.push('/setup');
    }
    localStorage.setItem("prevPath",'/setup');

  }

 componentDidMount = () => {
   this.fetchAlerts();
  };

  fetchAlerts = ()=>{
    (this.props.actions.getAlerts(false) as any).then((data:any)=>{
      let count = 0;
      if(data.length){
        data.forEach((item:any)=>{
          if(item.alertStatus){
            count++;
          }

        })

      }
      this.setState({data:data , count:count});
    });
  };

  openAlertForm = (key:any) => {
    let stateobj:any = { action: 'addAlert', openAlertForm: true };
    if(key !== 'addAlert') {
      stateobj.action = 'editAlert';
      let {editAlert, data} = this.state;
      if(data && data[key] && editAlert['id'] !== data[key].id) {
        stateobj['editAlert'] = Object.assign({},data[key]);
        // Interchange values for drop down
        if(stateobj['editAlert']['fileLinks'] && stateobj['editAlert']['fileLinks'] != '') {
          stateobj['editAlert']['fileLinks'] = stateobj['editAlert']['fileLinks'].split(",");
        }
      }
    }
    this.setState(stateobj);
  };

  hideAlertForm = () => {
    this.setState({ openAlertForm: false });
  };

  saveAlert = (obj:any) => {
    obj.department = obj.department ? obj.department.toString() : '';
    (this.props.actions.saveAlertForm(obj) as any).then((data)=>{
      this.fetchAlerts();
      this.setState({ openAlertForm: false, addAlert: {} });
    });
  };

  updateAlert = (obj : any) => {
    delete obj.AlertHistory;
    obj.department = obj.department ? obj.department.toString() : '';
    (this.props.actions.updateAlertForm(obj)as any).then((data)=>{
      this.fetchAlerts();
      this.setState({ openAlertForm: false, editAlert: {} });
    });
  };

  search = (e:any) => {
    const keyword = e.target.value;
    let data = this.props.alerts.alerts;
    if(keyword && keyword.length) {
      data = data.filter(function(element:any) {
        return (element.name.toLowerCase().indexOf(keyword.toLowerCase()) >= 0 || element.description.toLowerCase().indexOf(keyword.toLowerCase()) >= 0) ? true : false;
      });
    }
    this.setState({searchKeyword: keyword, data: data});
  };

  onChangeFilter = (value:string |any , name:string) => {
    let filters:any = this.state.filters;
    filters[name] = value;
    if ((filters[name][0] === 'all' || filters[name][0] === 0 ) && filters[name].length > 1) {
      filters[name].splice(0, 1);
    } else if (filters[name].length > 1 && (filters[name][filters[name].length - 1] === 'all' || filters[name][filters[name].length - 1] === 0)) {
      filters[name].splice(0, filters[name].length - 1);
    } else if (filters[name].length === 0) {
      if (name === 'practice') {
        filters[name].push("all");
      } else {
        filters[name].push(0);
      }
    }
    this.applyFilter(filters);
  };

  applyFilter = (filters:any) => {
    const {alerts} = this.props.alerts;
    let practice:string[] =['all'];
    let data=JSON.parse(JSON.stringify(alerts));
    const {searchKeyword} = this.state;

    function IsJsonString(str) {
      try {
          JSON.parse(str);
      } catch (e) {
          return false;
      }
      return true;
    }

    if(filters && Object.keys(filters).length) {
      Object.keys(filters).forEach( filter => {
        if(filters[filter][0]!== 'all' && filters[filter][0]!== 0 && filters[filter]) {
          data = data.filter(function(element:any) {
            let flag = true;
            // Apply Practice Filter
            if (filter === 'practice' && filters[filter] && filters[filter].length) {
              flag = (filters[filter].indexOf(element.practice)!==-1 || element.practice ==='all');
              practice= filters[filter];
              if (element.AlertHistory && element.AlertHistory.length) {
                let history = JSON.parse(element.AlertHistory[0].history);
                // let dbPractice: any = Object.keys(practicesDBName).filter(dbPrac=>practice.indexOf(practicesDBName[dbPrac])!==-1);
                let  dbPractice: string[] = [];
                practice.forEach((item: any) => {
                  let practiceName = item.replace(/\s/g, '').toLowerCase();
                  if (practiceName === 'epzaragoza')
                    practiceName = 'epzaragosa';
                  dbPractice.push(practiceName)
                });
                let obj: any = {};
                let flag = false;
                dbPractice.forEach((practiceName:string)=>{
                  obj[practiceName] = history[practiceName];
                  if(!flag && !!(history[practiceName] && history[practiceName].length))
                    flag =  true;
                });
                element.alertStatus= flag;
                element.AlertHistory[0].history = JSON.stringify(obj);
              }
            }

            return flag;
          });
        }
      });
      // Apply Department Filter
      if(filters['department'] && filters['department'].length) {
        filters['department'].forEach(item => {
          if( item === 0 ){
            return;
          }
          data  = data.filter(function(element:any) {
            return (element.department.indexOf(item) >= 0 || element.department.indexOf(0) >=0) // indexof(0) is for ALL departments
          });
        })
      }
      // Apply IsActive  Filter
      if(filters['isActive']) {

          data  = data.filter(function(element:any) {
            return (element.isActive === filters['isActive'])

        })
      }

      if(filters['searchKeyword'] && filters['searchKeyword'].length) {
        data = data.filter(function(element:any) {
          return (element.name.toLowerCase().indexOf(searchKeyword.toLowerCase()) >= 0 || element.description.toLowerCase().indexOf(searchKeyword.toLowerCase()) >= 0);
        });
      }
    }


    data.forEach(function callback(value, index) {


      //check count for each alert

      if(value.AlertHistory.length && IsJsonString(value.AlertHistory.history)) {
        let alertHistory = value.AlertHistory[0].history;
        let total = 0;

        let triggedPractice=(alertHistory:any)=>{
          let practiceName:string[] =[];
          if(alertHistory && typeof alertHistory === 'object' && Object.keys(alertHistory).length) {
              Object.keys(alertHistory).forEach((keys:string) => {
                if(alertHistory[keys] && alertHistory[keys].length>0) {
                   if(filters['practice'] && filters['practice'].includes(keys)){
                    practiceName.push(keys);
                    total = total+1;
                   }
                }
              });
              return practiceName.join(', ');
            } else {
              return '';
            }
        };

        if(IsJsonString(alertHistory) && triggedPractice(JSON.parse(alertHistory)).split(',').length<1){
          data[index].alertStatus = false;
          if(filters['practice'] && !filters['practice'].includes('all')){
            if(triggedPractice(JSON.parse(alertHistory))){
              data[index].alertStatus = true;
            }else{
              data[index].alertStatus = false;
            }
          }

          if(filters['region'] && !filters['region'].includes('all')){
            if(triggedPractice(JSON.parse(alertHistory))){
              data[index].alertStatus = true;
            }else{
              data[index].alertStatus = false;
            }
          }
        }

        if(value.AlertHistory && typeof value.AlertHistory === 'object' && Object.keys(value.AlertHistory).length<1) {
           data[index].alertStatus = false;
           Object.keys(JSON.parse(alertHistory)).forEach((keys:string) => {
            if(alertHistory[keys] && alertHistory[keys].length) {
              data[index].alertStatus = false;
            }
          });
        }else if (value.AlertHistory && Array.isArray(value.AlertHistory) && value.AlertHistory.length<1){
          Object.keys(JSON.parse(alertHistory)).forEach((keys:string) => {
            if(alertHistory[keys] && alertHistory[keys].length>0) {
              if(filters['practice'] && !filters['practice'].includes('all')){
                data[index].alertStatus = false;
              }
            }
          });
        }
      }
    });


    this.setState({filters:filters, data: data, column:'', order:true});
    let count = 0;
    let denominatorCount
    if( data.length > 0) {

      data.forEach((alert)=>{
        if(alert.alertStatus){
          count++;
        }

      });
      denominatorCount = filters['department'][0]!==0?this.state.denominatorCount:this.state.data.length;

    } else {
      denominatorCount = data.length;
      data.forEach((alert)=>{
        if(alert.alertStatus){
          count++;
        }

      });

    }
    this.setState({count: count , denominatorCount:denominatorCount});
  };


  testAlertQuery=(obj:object)=> {
    this.props.actions.testAlertQuery(obj);
  };

  deleteRecord=(id:number)=> {
    (this.props.actions.deleteAlertForm({id:id}) as any).then(()=>{
      this.fetchAlerts();
     });
  };

  handleSort = (clickedColumn:string) => {
    const {column, data, order} = this.state;
    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: sortBy(data, [clickedColumn]),
        order: true,
      });
      return
    }

    this.setState({
      data: data.reverse(),
      order: !order
    })
  };

  render() {
    const { isLoad,testAlert } = this.props.alerts;
    const {data, openAlertForm, action, addAlert, editAlert, searchKeyword, order, column, filters,count} = this.state;
    const {practices, practiceList} = this.props.usages.practices;

    const {practiceOTDList} = this.props.usages.practicesOTD;
    const { groups, groupList } = this.props.usages.groups;
    const carrierList = this.props.usages.carrierList;
    const patientValidationStatus = this.props.usages.patientValidationStatus;
    const carrierPrimaryList = this.props.usages.carrierPrimaryList;
    return (
      <Template activeLink="setup" history={this.props.history}>
        { isLoad && <CountUpTimer />}
        { openAlertForm &&
        <AlertFormModal
          action={action}
          hideForm={this.hideAlertForm}
          addAlert={addAlert}
          editAlert={editAlert}
          saveAlert={this.saveAlert}
          updateAlert={this.updateAlert}
          testAlertQuery = {this.testAlertQuery}
          isLoad={isLoad}
          testAlert = {testAlert}
          practices={practices}
          practiceList={practiceList}
          practiceOTDList={practiceOTDList}
          notificationGroups = {groups}
          carrierList = {carrierList}
          patientValidationStatus={patientValidationStatus}
          carrierPrimaryList={carrierPrimaryList}
        />
        }
        <Card className="pageTitle">
          <Card.Content className="pb0">
            <h2 className="float-left">Setup
              <span className='ml10'> <span className='red-text'>{count}</span>/{data.length}</span>
              <HelpMark helpPage={HELP_PAGE.CDP_ALERTS_SETUP} application='Alerts-Setup' />
            </h2>

            <div className="topFilters">
              <Checkbox className="mr10 mb10" label ='Active Alerts' checked={filters.isActive} onChange={(e, value)=>{this.onChangeFilter( value.checked, 'isActive')}}/>
              <Button primary className="mr10 mb10" onClick={()=>{this.openAlertForm('addAlert')}}> Add Alert</Button>
              <Dropdown selection
                        multiple
                        search
                        onChange={ (e, a) => this.onChangeFilter(a.value, 'practice') }
                        placeholder='Select Practice'
                        required
                        className="mr10 mb10"
                        value={filters.practice}
                        options={practices} />
              <Dropdown selection
                        multiple
                        search
                        onChange={ (e, a) => this.onChangeFilter(a.value, 'department') }
                        placeholder='Select Department'
                        name='department'
                        className="mr10 mb10"
                        value={filters.department}
                        options={groups}/>
              <Input icon='search' className="mb10" name="search" placeholder="Search" value={searchKeyword} onChange={this.search} />
            </div>
          </Card.Content>
        </Card>
        { (data && data.length) &&
          <AlertList groupList={groupList} alerts={data} openAlertForm={this.openAlertForm} deleteRecord={this.deleteRecord} handleSort={this.handleSort} order={order} column={column}/>
        }
    </Template>
    );
  }
}
