import * as React from 'react';
import { Loader, Dimmer } from 'semantic-ui-react';

const Loading = () => (
  <Dimmer active>
    <Loader size='massive' content="Loading..." />
  </Dimmer>
);

export default Loading;
