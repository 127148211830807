import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { HELP_PAGE } from '../../const';
import { HelpPageModal } from '../../components/Help/helpPageModal';

import { OpenIssuesModal } from './openIssueModal';
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import {omit} from "../../utils";
import {OpenIssuesActions} from "../../actions";
import {RootState} from "../../reducers";
const helpIcon = require('../../styles/images/help-icon.png');
const downtimeIcon = require('../../styles/images/downtime-icon.svg');

export namespace HelpMark {
  export interface Props extends RouteComponentProps<void> {
    application?: string
    helpPage?: HELP_PAGE,
    actions: OpenIssuesActions,
  }
}

@(connect(
  (state: RootState): Pick<OpenIssuesModal.Props, any> => ({
    auth: state.auth
  }),
  (dispatch: Dispatch): Pick<OpenIssuesModal.Props, 'actions'> => ({
    actions: bindActionCreators(omit(OpenIssuesActions, 'Type'), dispatch)
  })
) as any)

export class HelpMark extends React.Component<HelpMark.Props, any> {

  static defaultProps: Partial<HelpMark.Props> = {};

  constructor(props: HelpMark.Props, context?: any) {
    super(props, context);
    this.state = { helpPageModal: false, helpPageId: null, appStatusTrackerModal: false, isThereOpenIssues: false, loader: false, openIssues: [] };
  }

  componentWillMount = async () => {
    await this.fetchOpenIssues();
  };

  fetchOpenIssues = async () => {
    const { actions, application } = this.props;

    if(application != undefined) {

      this.setState({loader: true})
      const res = await actions.fetchOpenIssues(application);
      this.setState({ 
        loader: false, 
        openIssues: res, 
        isThereOpenIssues: res 
      });
    }
  };

  openHelpPage = (pageId) => {
    this.setState({ helpPageModal: true, helpPageId: pageId });
  };

  hideHelpPageModal = () => {
    this.setState({ helpPageModal: false });
  };

  hideOpenIssuesModal = () => {
    this.setState({ appStatusTrackerModal: false });
  }
  openOpenIssuesModal = () => {
    this.setState({ appStatusTrackerModal: true });
  }

  render() {
    const { helpPage } = this.props;
    const { helpPageModal, appStatusTrackerModal, isThereOpenIssues, openIssues } = this.state;
    return (
      <div className='helpMark'>
        <span className='mr10' onClick={() => this.openHelpPage(helpPage)} >
          <img alt="help" className="cursorPointer" src={helpIcon} />
        </span>
        { isThereOpenIssues &&
          <span onClick={() => this.openOpenIssuesModal()}>
            <img className="cursorPointer" src={downtimeIcon} alt="Downtime Icon"/>
          </span>
        }
        { appStatusTrackerModal &&
          <OpenIssuesModal hideOpenIssuesModal={this.hideOpenIssuesModal} openIssues={openIssues} />
        }
        {
          helpPageModal &&
          <HelpPageModal hideHelpPageModal={this.hideHelpPageModal} helpPage={helpPage} />
        }
      </div>
    );
  }
}

