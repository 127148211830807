import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Template } from '../../components/Template';
import { Button, Card, Checkbox, Dropdown, Grid, Icon, Input, Popup } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { AlertActions,UsageActions } from '../../actions';
import { RootState } from '../../reducers';
import { omit } from '../../utils';
import { PopUpModal } from '../Setup/popUpModal';
import { Timer } from './Timer';
import { HelpMark } from '../../components/Help/helpMark';
import { cdpDBAlerts, HELP_PAGE,momentUtc } from '../../const';
import * as _ from 'lodash';
import { PopUpModalCallCentre } from '../Setup/popUpModalCallCentre';
import { CountUpTimer } from '../CountUpTimer';
const moment = require('moment');


export namespace Alert {
  export interface Props extends RouteComponentProps<void> {
    alerts: RootState.AlertState;
    usages: RootState.UsageState;
    auth: RootState.AuthState;
    actions: AlertActions;
    usageActions: UsageActions;
  }
  export interface State {
    filters:{region: any ,practice:any[], department:[], brand: any[] ,alertStatus: boolean,prevMonth:boolean},
    data: any,
    originalData: any,
    userCallCentreDuplicates :any,
    userCallCentreTriggered: any,
    count: number,
    searchKeyword: any,
    showPopUp:boolean,
    showPopUpCallCentre:boolean;
    popUpHeader:string,
    popUpData: object | string,
    refreshTimerEnd:number,
    alertId:number,
    isCallCentreAlert:number,
    isCdpDbAlert : number,
    alertName:string,
    mainId:number,
    viewAllStatus:boolean,
    viewAllNoErrorsStatus:boolean,
    triggered: any[],
    alertStatus: boolean,
    prevMonth:boolean,
    prevMonthAlert:any[],
    patientValidationStatus:any[],
    denominatorCount:number,
  }
}


@(connect(
  (state: RootState): Pick<Alert.Props, any> => {
    return { alerts: state.alerts, auth: state.auth, usages: state.usages };
  },
  (dispatch: Dispatch): Pick<Alert.Props, 'actions'> => ({
    actions: bindActionCreators(omit(AlertActions, 'Type'), dispatch),
  })
) as any)

export class Alert extends React.Component<Alert.Props, Alert.State> {
  static defaultProps: Partial<Alert.Props> = {

  };

  constructor(props: Alert.Props, context?: any) {
    super(props, context);
    this.state ={
      searchKeyword: '',
      data: props.alerts.alerts ? this.props.alerts.alerts : [],
      originalData: props.alerts.alerts ? this.props.alerts.alerts : [],
      userCallCentreDuplicates : [],
      userCallCentreTriggered: [],
      showPopUp:false,
      showPopUpCallCentre:false,
      popUpData:'',
      popUpHeader:'',
      filters:{region: [] ,practice:[], department:[], brand:[], alertStatus:false,prevMonth:false},
      refreshTimerEnd:0,
      alertId:0,
      count:0,
      isCallCentreAlert:0,
      isCdpDbAlert : 0,
      alertName:'',
      viewAllStatus:true,
      viewAllNoErrorsStatus:false,
      mainId:0,
      triggered: [],
      alertStatus: false,
      patientValidationStatus:[],
      prevMonth:false,
      prevMonthAlert:[],
      denominatorCount:0,

    };

    if (typeof window !== "undefined") {
      let savedUrlParams = localStorage.getItem("UrlSearchParam");
      if(savedUrlParams && this.props.auth.AlertSetup) {
        this.props.history.push('/alert?'+savedUrlParams);
        let filterArr = savedUrlParams.split('&');
        filterArr.forEach(value=>{
         let filter = value.split('=');
         switch (filter[0]) {
          case "practice":
            this.state.filters.practice = filter[1].split(',');
            this.props.auth.AlertSetup.practices = filter[1];
            break;
          case "region":
            this.state.filters.region = filter[1].split(',');
            this.props.auth.AlertSetup.regions = filter[1];
            break;
          case "brand":
            this.state.filters.brand = filter[1].split(',');
            this.props.auth.AlertSetup.brands = filter[1];
            break;
          case "department":
           this.props.auth.AlertSetup.departments = filter[1];
            break;
          case "alertStatus":
            this.state.filters.alertStatus = Boolean(filter[1]);
            this.props.auth.AlertSetup.triggedAlert = Boolean(filter[1]);
            break;

          default:
            break;
         } })
      }

      localStorage.setItem("prevPath",'/alert?'+savedUrlParams);

    }
  }
   componentDidUpdate(prevProps) {
    if (prevProps.auth !== this.props.auth) {
      let filters: any = {};
      if (this.props.auth && Object.keys(this.props.auth).length && this.props.auth.AlertSetup && this.props.auth.AlertSetup.id) {
        let all = 'all';
        filters = {
          practice: this.props.auth.AlertSetup.practices.split(','),
          region:this.props.auth.AlertSetup.regions? this.props.auth.AlertSetup.regions.split(','): all.split(','),
          brand: this.props.auth && this.props.auth.AlertSetup && this.props.auth.AlertSetup.brands ? this.props.auth.AlertSetup.brands.split(',') : [],
          department: this.props.auth.AlertSetup.departments.split(',').map(Number),
          alertStatus: this.props.auth.AlertSetup.triggedAlert
        };
        this.setState({filters});
      }

      if((Array.isArray(filters['brand'])&&!filters['brand'].includes('All')) || (Array.isArray(filters['brand'])&&!filters['brand'].includes('all')) ){
        this.props.usages.practices.practices  = this.props.usages.practices.practices.filter(function (element:any) {
          return filters['brand'].includes(element.brand);
        });
      }

      if(!filters['region'].includes('all') || !filters['region'].includes('All') ){
        this.props.usages.practices.practices  = this.props.usages.practices.practices.filter(function (element:any) {
          return filters['region'].includes(element.region);
        });
      }

      this.applyFilter(filters ,this.state.searchKeyword);
    }
  }

  componentDidMount = () => {
    this.fetchAlerts();
  };

  refreshTimerEnd=(alert: any)=>{
    this.setState({data:[], originalData:[]});
    let obj = {
      query:alert.query,
      queryTab32:alert.queryTab32,
      practice:alert.practice,
      alertId: alert.id,
      isCallCentreAlert:alert.isCallCentreAlert,
      isCdpDbAlert:alert.isCdpDbAlert,
      failure: alert.failure,
      name: alert.name,
      department:alert.department,
      notification: alert.notification,
      emailToRegionalManager: alert.emailToRegionalManager,
      emailToPracticeManager: alert.emailToPracticeManager,
      isEmailSent: alert.isEmailSent,
      isManualTriggered: false,
      alertStatus: alert.alertStatus,
      prevMonth: alert.prevMonth,
    };
    (this.props.actions.testAlertQuery(obj) as any).then((data:any)=> {
      this.fetchAlerts();
    });
  };
  onSearch = (e:any) => {
       const keyword = e.target.value;
       let data = this.props.alerts.alerts;
        if(keyword && keyword.length) {
            data = data.filter(function(element:any) {
                return (element.name.toLowerCase().indexOf(keyword.toLowerCase()) >= 0 || element.description.toLowerCase().indexOf(keyword.toLowerCase()) >= 0) ? true : false;
              });
          }
        this.setState({searchKeyword: keyword, data: data});
  };



  IsJsonString = (str)  =>{
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}


  fetchAlerts = ()=>{
    const {auth} = this.props;
    if (auth && auth.id) {
      let filters: any = {};
      (this.props.actions.getTriggeredAlerts() as any).then((res: any) => { });
      (this.props.actions.getAlerts(true) as any).then((data: any) => {
        this.setState({data: data, originalData: data});
        if (auth && Object.keys(auth).length && auth.AlertSetup && auth.AlertSetup.id) {
          let all = 'all';
          filters = {
            practice: auth.AlertSetup.practices.split(','),
            region: auth.AlertSetup.regions?auth.AlertSetup.regions.split(','):all.split(','),
            brand: auth && auth.AlertSetup && auth.AlertSetup.brands ? auth.AlertSetup.brands.split(',') : [],
            department: auth.AlertSetup.departments.split(',').map(Number),
            alertStatus: auth.AlertSetup.triggedAlert
          };

          (this.props.actions.getCallCentreData() as any).then((data: any ,data2: any) => {
            this.setState({ userCallCentreTriggered: data.data});
            this.setState({userCallCentreDuplicates: data2});
            this.applyFilter(filters ,this.state.searchKeyword);
          });
          this.applyFilter(filters ,this.state.searchKeyword);
        }

        this.applyFilter(filters ,this.state.searchKeyword);
      });
    }
  };

  search = (e:any) => {
    const keyword = e.target.value;
    let data = this.props.alerts.alerts;
    if(keyword && keyword.length) {
      data = data.filter(function(element:any) {
        return (element.name.toLowerCase().indexOf(keyword.toLowerCase()) >= 0 || element.description.toLowerCase().indexOf(keyword.toLowerCase()) >= 0);
      });
    }
    this.setState({searchKeyword: keyword, data: data});
  };

  onChangeFilter = (value:string |any , name:string) => {

    let filters:any  = this.state.filters;
    let brandFiltered: any = [{key:0, text:'All Brands', value:'All'}];

    if(name!='search') {
     filters[name] = value;
    if ((filters[name][0] === 'all'|| filters[name][0] === 'All' || filters[name][0] === 0 ) && filters[name].length > 1) {
      filters[name].splice(0, 1);
      if(filters[name]==='practice'){
        this.props.usages.practices.practices  = this.props.usages.practices.practiceFilterData;
      }else if(filters[name]==='region'){
        this.props.usages.brands.brands = this.props.usages.brands.brandFilterData;
      }else if(filters[name]==='brand'){
        this.props.usages.practices.practices  = this.props.usages.practices.practiceFilterData;
      }
    } else if (filters[name].length > 1 && (filters[name][filters[name].length - 1] === 'All' || filters[name][filters[name].length - 1] === 'all' || filters[name][filters[name].length - 1] === 0)) {
      filters[name].splice(0, filters[name].length - 1);

      if(filters[name]==='region'){
        this.props.usages.practices.practices  = this.props.usages.practices.practiceFilterData;
      } else if(filters[name]==='practice'){
        this.props.usages.brands.brands = this.props.usages.brands.brandFilterData;
      }else if(filters[name]==='brand'){
        this.props.usages.practices.practices  = this.props.usages.practices.practiceFilterData;
      }
    }
    else if (filters[name].length === 0) {

      if (name === 'region') {
        filters[name].push("all");
        this.props.usages.practices.practices  = this.props.usages.practices.practiceFilterData;

      }else if (name === 'practice') {
        filters[name].push("all");
        this.props.usages.brands.brands = this.props.usages.brands.brandFilterData;

      } else if(name==='brand'){
        filters[name].push("All");
        this.props.usages.practices.practices  = this.props.usages.practices.practiceFilterData;

      }
      else
      {
        filters[name].push(0);
      }
    }
      if(name == 'department' && filters[name][0]!== 0){
        if(value.length > 0 && value[0] !== 0){
          filters[name] = [value[value.length-1]];
        }
      }
      if(name == 'prevMonth'){
        filters[name] = value;
      }


      if(name == 'region' && !filters[name].includes('all')){
      this.props.usages.practices.practices  = this.props.usages.practices.practiceFilterData;
      this.props.usages.practices.practices  = this.props.usages.practices.practices.filter(function (element:any) {
        return filters[name].includes(element.region);
      });
        filters['practice'] = []
        this.props.usages.practices.practices.forEach(practice=>{

          if(practice.value!=="")
          filters['practice'].push(practice.value)
        })
    }


    if(name == 'brand' && !filters[name].includes('All')){
      this.props.usages.practices.practices  = this.props.usages.practices.practiceFilterData;
      this.props.usages.practices.practices  = this.props.usages.practices.practices.filter(function (element:any) {
        return filters[name].includes(element.brand);
      });
    }

    if(name == 'practice' && !filters[name].includes('all')) {
      this.props.usages.brands.brands  = this.props.usages.brands.brandFilterData;
      this.props.usages.practices.practices.forEach(element=>{
        filters[name].forEach(filter=>{
          if(filter == element.value && !this.checkExistsBrand(brandFiltered,element.value)) {
              brandFiltered.push({
                key  :  element.brand,
                text : element.brand,
                value: element.brand
              });
            this.props.usages.brands.brands = brandFiltered;
          }
        })
      })
    }

  }else if(name =='search') {
      this.setState({searchKeyword: value});
    }


    this.applyFilter(filters,value);
  };


  checkExistsBrand = (filteredBrands: any,value:any) =>{
    let exists = false;
    filteredBrands.forEach(bfElement => {
      if(bfElement.value == value){
        exists = true;
      }
    });
    return exists;
  }

  checkExistsPractice = (filteredPractices: any,value:any) =>{
    let exists = false;
    filteredPractices.forEach(bfElement => {
      if(bfElement.value == value){
        exists = true;
      }
    });
    return exists;
  }

  updateCount=()=>{
    const {data} = this.state;
    let count:number=0;
    data && data.map(alert=>{
      if(alert.alertStatus){
        count++;
      }
    });
    this.setState({count:count});
  };


  getPracticeAbbr = (value: string | any)=>{
    const { practiceList} = this.props.usages.practices;
   return   practiceList.find(p => p.practice == value).practiceabbr.substring(0,3);
  }

  applyFilterAlertHistory(data: any,filters:any,practiceList:any) {
    if(filters && Object.keys(filters).length) {
      Object.keys(filters).forEach( filter => {
        if(filters[filter][0]!== 'all' && filters[filter][0]!== 0 && filters[filter]) {
           data = data.filter(function(element:any) {
            // Apply Practice Filter
            if (filter === 'practice' && filters[filter] && filters[filter].length) {
                return (filters[filter].includes(element) || element ==='all');
            }
            if (filter === 'brand' && filters[filter] && filters[filter].length) {
              filters[filter].split(",").forEach(element => {
                  return filters[filter].indexOf(practiceList.find(p => p.brand == element))!=-1 ;
               });
            }
          });
        }
      });
    }
  }

  applyFilter = (filters:any,value: any) => {

    this.setState({ triggered : []})

    function IsJsonString(str) {
      try {
          JSON.parse(str);
      } catch (e) {
          return false;
      }
      return true;
    }

    if(filters && Object.keys(filters).length) {
      let UrlSearchParam  = '';
      Object.keys(filters).forEach( filter => {
        switch (filter) {
          case 'region':
            UrlSearchParam += '&region='+filters[filter].toString();
            break;
          case 'brand':
            UrlSearchParam += '&brand='+filters[filter].toString();;
              break;
          case 'practice':
            UrlSearchParam += 'practice='+filters[filter].toString();;
            break;
          case 'department':
            UrlSearchParam += '&department='+filters[filter].toString();
              break;
          default:
            break;
        }
      });

      this.props.history.push({
        pathname: '/alert',
        search: UrlSearchParam
      })

      localStorage.setItem("UrlSearchParam",UrlSearchParam);
    }

    const {alerts} = this.props.alerts;
    const {userCallCentreTriggered} = this.state;
    // let practice:string[] =['all'];
    let data =JSON.parse(JSON.stringify(alerts));

    if(filters && Object.keys(filters).length) {
      Object.keys(filters).forEach( filter => {
        if(filters[filter][0]!== 'all' && filters[filter][0]!== 0 && filters[filter]) {
           data = data.filter(function(element:any) {
            let flag = true;

            if( flag && filter === 'alertStatus' && filters[filter])
              flag = (element.alertStatus === filters[filter]);
            return flag;
          });
        }
      });
      // Apply Department Filter
      if(filters['department'] && filters['department'].length) {
        filters['department'].forEach(item => {
          if( item === 0 ){
            return;
          }
          data  = data.filter(function(element:any) {
            let flag =(element.department.toLowerCase().indexOf(item.toString().toLowerCase()) >= 0 || element.department.toLowerCase().indexOf(0) >=0);
            return flag; // indexof(0) is for ALL departments

          });
        })
      }
    }

     data.forEach(function callback(value, index) {
      //set trigger for call centre alert
        if(value.name && value.name.includes('Call')){
          if(userCallCentreTriggered.length>0){
            data[index].alertStatus = true;
          }else{
            data[index].alertStatus = false;
          }
        }

      //check count for each alert

      if(value.AlertHistory.length && IsJsonString(value.AlertHistory.history)) {
        let alertHistory = value.AlertHistory[0].history;

        let triggedPractice = (alertHistory: any) => {
          let practiceName: string[] = [];


          if (alertHistory && typeof alertHistory === 'object' && Object.keys(alertHistory).length) {
            for (let key in alertHistory) {
              if (alertHistory[key] && alertHistory[key].length > 0 && filters['practice'] && filters['practice'].includes(key)) {
                practiceName.push(key);
              }
            }
          }

          return practiceName.join(', ');
        };

        if(IsJsonString(alertHistory) && triggedPractice(JSON.parse(alertHistory)).split(',').length<1){
          if(!value.isCdpDbAlert) {
            data[index].alertStatus = false;
            if (filters['practice'] && !filters['practice'].includes('all')) {
              if (triggedPractice(JSON.parse(alertHistory))) {
                data[index].alertStatus = true;
              } else {
                data[index].alertStatus = false;
              }
            }


            if (filters['region'] && !filters['region'].includes('all')) {
              if (triggedPractice(JSON.parse(alertHistory))) {
                data[index].alertStatus = true;
              } else {
                data[index].alertStatus = false;
              }
            }
          }
        }

        if(value.AlertHistory && typeof value.AlertHistory === 'object' && Object.keys(value.AlertHistory).length<1) {
           data[index].alertStatus = false;
           Object.keys(JSON.parse(alertHistory)).forEach((keys:string) => {
            if(alertHistory[keys] && alertHistory[keys].length) {
              data[index].alertStatus = false;
            }
          });
        }else if (value.AlertHistory && Array.isArray(value.AlertHistory) && value.AlertHistory.length<1){
          Object.keys(JSON.parse(alertHistory)).forEach((keys:string) => {
            if(alertHistory[keys] && alertHistory[keys].length>0) {
              if(filters['practice'] && !filters['practice'].includes('all')){
                data[index].alertStatus = false;
              }
            }
          });
        }
      }
    });

    Object.keys(filters).forEach( filter => {
      data = data.filter(function(element:any) {
      let flag = true;
        if (flag && filter === 'alertStatus' && filters[filter])
          flag = (element.alertStatus === filters[filter]);
        return flag;
        });
      });

     this.props.usages.brands.brands = this.props.usages.brands.brands.reduce((unique, o) => {
        if(!unique.some(obj => obj.key === o.key && obj.value === o.value)) {
          unique.push(o);
        }
        return unique;
    },[]);

    if((filters['brand'] && !filters['brand'].includes('All')) || (filters['brand'] && !filters['brand'].includes('all') )){
      this.props.usages.practices.practices  = this.props.usages.practices.practices.filter(function (element:any) {
        return filters['brand'].includes(element.brand);
      });
    }else{
      this.props.usages.practices.practices  = this.props.usages.practices.practiceList;
    }

    if(!this.checkExistsPractice(this.props.usages.practices.practices,'all')) {
      this.props.usages.practices.practices.push({'key':0,'text': 'All Practices' ,'value': 'all'});
    }

    if(this.props.usages.practices.practices.length == 1 && this.checkExistsPractice( this.props.usages.practices.practices,'all')){
      this.props.usages.practices.practices  = this.props.usages.practices.practiceFilterData;
    }
    let  IsParsable = (data) => {
      try {
        JSON.parse(data);
      } catch (e) {
        return false;
      }
      return true;
    }
    function isWithinRange(startMonthDate, endMonthDate, checkDate) {
      const start = new Date(startMonthDate);
      const end = new Date(endMonthDate);
      end.setDate(end.getDate());
      const date = new Date(checkDate);
      return date >= start && date < end;
    }

     let filterBasedOnPrevMonth = (alertHistory, filterValues) => {
      console.log(alertHistory['alertId'])
      let finalData = {};
      if (Object.keys(alertHistory).length) {

        if(alertHistory['alertId']){
          let checkId  = filterValues.filter(function(filterItem) {
            return filterItem.alertId === alertHistory['alertId'];
          });
          if(checkId.length>0){
            let history  = alertHistory['history'] ? alertHistory['history']: {};
            let parsedHistory =  IsParsable (history) ?  JSON.parse(history) : false

            if(parsedHistory){
              Object.keys(parsedHistory).forEach((practiceName) => {
                finalData[practiceName] = parsedHistory[practiceName].filter((record) =>{
                  let flag  = false;
                  checkId.map((item) => {
                    Reflect.deleteProperty(item, 'alertId');
                    for ( const prop in item){

                      let endDate = moment(item[prop]).endOf('month').utc().format('YYYY-MM-DD');
                      flag =  isWithinRange(item[prop], endDate, record[prop]);


                    }
                    return flag;

                  })
                  return flag;
                })

              })

            }else {

              finalData  = {};
            }


          }else{
            finalData  = {};
          }


        }else{
          finalData  = {};
        }



       }else{
        finalData  =  {};
      }
       return finalData;
     }

    data.forEach((value, index) => {

      let alertHistory = value && value.AlertHistory && value.AlertHistory[0] && value.AlertHistory[0].history ? value.AlertHistory[0].history : {};
      let alertHistoryPrev = value && value.AlertHistory && value.AlertHistory[0];



      if(filters['prevMonth']&&value.prevMonth){
        let prevMonthDate = moment().utc().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
        let prevMonthAlertFilters:any = []
        alerts.forEach((alert:any) => {
          if(alert.prevMonth===true) {

            if(alert.prevMonthFilters!==null ){
              let obj  = {};
              obj['alertId'] = alert.id;
              obj[alert.prevMonthNameFilter] =prevMonthDate;
              prevMonthAlertFilters.push(obj);
            }

          }

        });
        const checkIfAlertIsCDPDB = (str) => {
          return cdpDBAlerts.includes(str);
        };
        if(Object.keys(alertHistory).length&&!checkIfAlertIsCDPDB(value.name)){
          let alertHistory2 = filterBasedOnPrevMonth(alertHistoryPrev, prevMonthAlertFilters);
          if(alertHistory2){
             data[index].AlertHistory[0].history = JSON.stringify(alertHistory2);
          }else{
            data[index].AlertHistory[0].history = {};
          }
        }


      }

      let  practiceArray = (practices: any) =>{
        let arr:any = [];
        practices.forEach(prElement => {
          arr.push(prElement.value);
        });
        return arr;
      }

      let  IsParsable = (data) => {
        try {
              JSON.parse(data);
             } catch (e) {
              return false;
            }
         return true;
      }

      let alertHistoryFilter = (data,filters,practiceList)=> {
        if(filters && Object.keys(filters).length ) {
          Object.keys(filters).forEach( filter => {
            switch (filter) {
              case 'practice':
                  if(filters[filter][0]!== 'all' && filters[filter][0]!== 0 && filters[filter]) {
                    data = Object.keys(data)
                    .filter(key => filters[filter].includes(key))
                    .reduce((obj, key) => {
                      obj[key] = data[key];
                      return obj;
                    }, {});
                  }
                break;
              case 'brand':
                   data = Object.keys(data)
                    .filter(key => practiceArray(practiceList).includes(key))
                    .reduce((obj, key) => {
                      obj[key] = data[key];
                      return obj;
                    }, {});

                  break;

              default:
                break;
            }
          });

        }
        const sortedData = {};
        Object.keys(data)
          .sort()
          .forEach(key => {
            sortedData[key] = data[key];
          });
        return sortedData;
      }

    let historyFiltered=  alertHistoryFilter(data[index].AlertHistory[0]&&IsParsable (data[index].AlertHistory[0].history ) ?  JSON.parse(data[index].AlertHistory[0].history ) : false,filters,this.props.usages.practices.practices);
      let prevMonthDate = moment().utc().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
      let triggered:string[] = [];
      let prevMonthAlert:string[] = [];
      let prevMonthAlertFilters:any = []
      const practiceNames = Object.keys(historyFiltered);


      const hasNonEmptyArray = practiceNames.some(practiceName => {
        return historyFiltered[practiceName].length > 0;
      });
      const checkIfAlertIsCDPDB = (str) => {
        return cdpDBAlerts.includes(str);
      };


      let cdpAlerts:any = {};
      alerts.forEach((alert:any) => {
        if(alert.query.toLowerCase().includes('cdp')||alert.isCdpDbAlert){
          cdpAlerts[alert.name] = alert;
        }
      })

      if(!historyFiltered&&!checkIfAlertIsCDPDB(data[index]['name'])&&!data[index]['isCdpDbAlert']){
       data[index]['alertStatus'] = false;
      }else if(historyFiltered && !hasNonEmptyArray&&!checkIfAlertIsCDPDB(data[index]['name'])&&!data[index]['isCdpDbAlert']){
        data[index]['alertStatus'] = false;
      }
      if(!alertHistoryPrev&& filters['prevMonth']===true&&!checkIfAlertIsCDPDB(data[index]['name'])&&!data[index]['isCdpDbAlert']) {
        data[index]['alertStatus'] = false;
      }

      alerts.forEach((alert:any) => {
        if(alert.alertStatus) {
          triggered.push(alert.name);
        }
        if(alert.prevMonth===true) {
          prevMonthAlert.push(alert.name);
          if(alert.prevMonthFilters!==null ){
            let obj  = {};
            obj['name'] = alert.name;
            obj[alert.prevMonthNameFilter] =prevMonthDate;
            prevMonthAlertFilters.push(obj);
          }

        }

      });

      if(triggered.length > 0)
        this.setState({triggered:triggered})
      if(prevMonthAlert.length > 0)
        this.setState({prevMonthAlert:prevMonthAlert})
    });

    let triggeredrray = filters['prevMonth']?_.uniq(this.state.prevMonthAlert) :  _.uniq(this.state.triggered);
    let filteredData = data.filter(function (element:any) {
      if (filters['alertStatus'] && element.alertStatus&&!filters['prevMonth']) {
        return element.alertStatus
      } else if(filters['prevMonth']){

        return triggeredrray.includes(element.name) && element.alertStatus;

      }else if(!filters['alertStatus']){
        return true
      }
    });


    this.setState({filters:filters, data: filteredData,denominatorCount:filteredData.length });
    let count = 0;
    let denominatorCount
    if(filters['alertStatus'] == true && filteredData.length > 0) {

      filteredData.forEach((alert)=>{
        if(alert.alertStatus){
          count++;
        }

      });
      denominatorCount = filters['department'][0]!==0?this.state.denominatorCount:this.state.originalData.length;
      this.setState({alertStatus: true});
    } else {
      denominatorCount = filteredData.length;
      filteredData.forEach((alert)=>{
        if(alert.alertStatus){
          count++;
        }

      });

      this.setState({alertStatus: false});
    }
    this.setState({count: count , denominatorCount:denominatorCount});

  };

  trimString=(str:string, len:number, addDots:boolean)=> {
    return (
      (str && str.length > len) ?
        (addDots ?
          str.substring(0, len - 3) :
          str.substring(0, len - 3) + '...') :
        str);
  };
  testAlertQuery= (obj: any)=> {

    if(obj.name.includes('Call')){
      (this.props.actions.getCallCentreData() as any).then((data: any ,data2: any) => {
        this.setState({ userCallCentreTriggered: data.data});
        this.setState({userCallCentreDuplicates: data2});
        let popUpHeader = `${obj.name} -- ${momentUtc(obj.createdAt).format('MM/DD/YYYY hh:mm A')}`;
        let popUpData = data;
        this.setState({ showPopUpCallCentre: true, popUpData: popUpData , popUpHeader: popUpHeader, alertId: obj.id,isCallCentreAlert:obj.isCallCentreAlert, isCdpDbAlert: obj.isCdpDbAlert, data: [],alertName:obj.name  });
      });
    }else{
      (this.props.actions.testAlertQuery(obj) as any).then((data:any)=> {
        let popUpHeader = `${obj.name} -- ${momentUtc(data.createdAt).format('MM/DD/YYYY hh:mm A')}`;
        let popUpData = this.IsJsonString(data.history) ? JSON.parse(data.history):data
        this.setState({ showPopUp: true, popUpData: popUpData, popUpHeader: popUpHeader, alertId: data.id,isCallCentreAlert:data.isCallCentreAlert, isCdpDbAlert: obj.isCdpDbAlert, data: [],alertName:obj.name  });
        this.fetchAlerts();
      });
    }

  };

  triggedPractice=(alertHistory:any)=>{
    let practiceName:string[] =[];
    const { practiceList } = this.props.usages.practices;
    if(alertHistory && typeof alertHistory === 'object' && Object.keys(alertHistory).length) {
        Object.keys(alertHistory).forEach((keys:string) => {
          if(alertHistory[keys] && alertHistory[keys].length) {
            if (keys === 'epzaragosa')
              keys = 'epzaragoza';
            const practice = practiceList.find((data: any) =>
              data&&data.practice&&data.practice.replace(' ', '').toLowerCase() === keys);
            if (practice) {
              practiceName.push(practice.practice);
            }
          }
        });
        return practiceName.join(', ');
      } else {
        return 'None';
      }
  };

  getAlertCounts=(id:number)=>{
    const {filters} = this.state;
    const {triggeredAlerts} = this.props.alerts;
    let count = 0;
    if(filters.practice && !filters.practice.length && filters['practice'][0]==='all') {
      return count = triggeredAlerts[id]? triggeredAlerts[id]['all']: 0;
    }

    filters.practice && filters.practice.map((practice:string) => {
      practice= practice.replace(' ', '').toLowerCase();
      count = count + triggeredAlerts[id]? triggeredAlerts[id][practice]: 0;
    });
    return count;
  };

  fetchAlertHistory= (e:any, id:number, header:any) =>{
    e.preventDefault();
    (this.props.actions.fetchAlertsHistory(id) as any).then((data:any)=>{
      let history = data && data.AlertHistory ? data.AlertHistory :[];
      this.setState({showPopUp: true, popUpData: history, popUpHeader: header});
    });
  };

  openPopUpModal =(e:any , data:any, header:any)=>{
    e.preventDefault();
    this.setState({showPopUp: true, popUpData: data, popUpHeader: header})
  };

  closePopUpModal =()=>{
    this.setState({ showPopUp: false });
    this.setState({ showPopUpCallCentre: false });
  };

  openRecentModal = (data:any, header:string, alertId:number, isCallCentreAlert:number, isCdpDbAlert : number, alertName:string,mainId:any)=>{
    if(alertName.includes('Call')){
        isCallCentreAlert = 1;
        const finalData:any = this.props.alerts.alerts;
        const result =  finalData && finalData.filter(data => data.id == parseInt(mainId));
        const alertHistoryData = result && result.length  > 0 && result[0].AlertHistory.length !== 0 &&  this.IsJsonString (result[0].AlertHistory[0].history) ?  JSON.parse(result[0].AlertHistory[0].history) : false;
        this.setState({showPopUpCallCentre: true, popUpData: result[0].AlertHistory.length !== 0 ? alertHistoryData : [], popUpHeader: header, alertId:alertId, isCallCentreAlert:isCallCentreAlert, isCdpDbAlert: isCdpDbAlert, alertName:alertName,mainId:mainId})
      }else{
        const finalData:any = this.state.data;
        const result =  finalData && finalData.filter(data => data.id == parseInt(mainId));
        const alertHistoryData = this.state.filters['prevMonth']===true?this.sortAlertHistoryData(data) :this.sortAlertHistoryData( result && result.length  > 0 && result[0].AlertHistory.length !== 0 && this.IsJsonString(result[0].AlertHistory[0].history) && JSON.parse(result[0].AlertHistory[0].history))
        this.setState({showPopUp: true, popUpData: result[0].AlertHistory.length !== 0 ? alertHistoryData : [], popUpHeader: header, alertId:alertId, isCallCentreAlert:isCallCentreAlert, isCdpDbAlert: isCdpDbAlert, alertName:alertName,mainId:mainId})
      }
  };

  sortAlertHistoryData=(alertHistoryData:any)=>{
    const sortedData = {};
    if(alertHistoryData &&  Object.keys(alertHistoryData).length){

      Object.keys(alertHistoryData)
        .sort()
        .forEach(key => {
          sortedData[key] = alertHistoryData[key];
        });

    }
    return sortedData;
  }

  sendEmail=()=>{
    const {popUpHeader, alertId,alertName,viewAllStatus,viewAllNoErrorsStatus} = this.state;

    this.props.actions.sendEmail({alertId:alertId,subject:popUpHeader,alertName:alertName,
      viewAllStatus:viewAllStatus,viewAllNoErrorsStatus:viewAllNoErrorsStatus});

    this.closePopUpModal();
  };

  onChangeTriggerFilter = (value:Boolean | any , filter: string) => {
const {data, originalData} = this.state;
let filterData = [];
    if(value === true){
      filterData = data.filter((data:any) => data.alertStatus === true);
    } else if(value === false){
      filterData = originalData;
    }
    this.setState({data: filterData, alertStatus: value});
  }

  viewAll = () => {
    const checkViewAllStatus = this.state.viewAllStatus;
    const checkViewAllNoErrorsStatus = this.state.viewAllNoErrorsStatus;
    if(checkViewAllStatus){
      this.setState({viewAllNoErrorsStatus: true,viewAllStatus:false})
    }else if(checkViewAllNoErrorsStatus){
      this.setState({viewAllStatus: false,viewAllNoErrorsStatus:false})
    }else{
      this.setState({viewAllStatus: true,viewAllNoErrorsStatus:false})
    }
  }
  convertUTCDateToLocaleDate = (dateInMilliseconds) => {
    const utcDate = new Date(dateInMilliseconds).toUTCString()
    const localDate = new Date(dateInMilliseconds)
    const initialHours = localDate.getUTCHours();

    const utcDateFormatted:any = momentUtc(utcDate).format("YYYY-MM-DD");

    const yearMonthDateArray = utcDateFormatted.split("-");
    let utcDateYYYYMMDD = new Date(yearMonthDateArray[0], yearMonthDateArray[1]-1, yearMonthDateArray[2]);

    utcDateYYYYMMDD.setHours(initialHours)

    const milliseconds = utcDateYYYYMMDD.getTime()

    return milliseconds;
  }


  render() {
    let {searchKeyword, showPopUp,showPopUpCallCentre, count,  data, popUpData,userCallCentreTriggered,denominatorCount, alertStatus, popUpHeader, filters, alertId,isCallCentreAlert, isCdpDbAlert, alertName,viewAllStatus,viewAllNoErrorsStatus} = this.state;
    const { isLoad } = this.props.alerts;
    const {practices, practiceList} = this.props.usages.practices;
    const {brands} = this.props.usages.brands;
    const {regions} = this.props.usages.regions;
    const {practiceOTDList} = this.props.usages.practicesOTD;
    const carrierList = this.props.usages.carrierList;
    const patientValidationStatus = this.props.usages.patientValidationStatus;
    const carrierPrimaryList = this.props.usages.carrierPrimaryList;
    const { groups } = this.props.usages.groups;

    return (
      <Template activeLink="alert" history={this.props.history}>
        { isLoad && <CountUpTimer />}
        <Card className="pageTitle">
          <Card.Content className="pb0">
            <h2 className="float-left">Alert
              <span className='ml10'> <span className='red-text'>{count}</span>/{denominatorCount}</span>
              <HelpMark helpPage={HELP_PAGE.CDP_ALERTS} application='Alerts'/>
            </h2>
            <div className="topFilters">
              <Checkbox className="mr10 mb10" label ='Triggered Alert' checked={alertStatus} onChange={(e, value)=>{this.onChangeFilter( value.checked, 'alertStatus')}}/>
              <Checkbox className="mr10 mb10" label ='Prev Month' checked={filters.prevMonth} onChange={(e, value)=>{this.onChangeFilter( value.checked, 'prevMonth')}}/>
              <Dropdown selection
                        search
                        multiple
                        onChange={ (e, a) => this.onChangeFilter(a.value, 'region') }
                        placeholder='Select Region'
                        className="mr10 mb10"
                        value={filters.region}
                        required
                        options={regions} />
              <Dropdown selection
                        search
                        multiple
                        onChange={ (e, a) => this.onChangeFilter(a.value, 'practice') }
                        placeholder='Select Practice'
                        className="mr10 mb10"
                        value={filters.practice}
                        required
                        options={practices} />
              <Dropdown selection
                        multiple
                        onChange={ (e, a) => this.onChangeFilter(a.value, 'brand') }
                        placeholder='Select Brand'
                        className="mr10 mb10"
                        value={filters.brand}
                        required
                        options={brands} />
              <Dropdown selection
                        search
                        multiple
                        onChange={ (e, a) => this.onChangeFilter(a.value, 'department') }
                        placeholder='Select Department'
                        className="mr10 mb10"
                        name='department'
                        value={filters.department}
                        options={groups}/>
              <Input className="mb10 float-right" icon='search' name="search" placeholder="Search" value={searchKeyword} onChange={ this.onSearch } />
            </div>
          </Card.Content>
        </Card>
        <div className='alerts'>
          <Grid>
            <Grid.Row>
              <Grid.Column className="customPadding">
              {(data && data.length > 0) &&
                data.map((alert:any, key:number)=>{
                  let triggedPractices:string=this.triggedPractice(alert.AlertHistory &&  alert.AlertHistory.length && this.IsJsonString(alert.AlertHistory[0].history) ? (JSON.parse(alert.AlertHistory[0].history)) : '');
                  let time:number = ((moment().utcOffset('-06:00').valueOf() - moment().utcOffset('-06:00').startOf('day').valueOf())/1000) *1000;
                  let refreshTimeLeft: number=0;
                  let endTime: number=0;
                  let refreshTimeLeftUp: number=0;
                  if(time>=alert.startTime) {
                    endTime = ((moment().utcOffset('-06:00').endOf('day').valueOf() - moment().utcOffset('-06:00').valueOf()) / 1000) * 1000;
                  } else {
                    endTime = -time;
                  }
                  if(time>= alert.startTime && time <=alert.endTime) {
                    let frequency = 0;
                    if (alert.AlertHistory && alert.AlertHistory.length) {
                      frequency = (moment().utcOffset('-06:00').valueOf() - moment(alert.AlertHistory[0].createdAt).valueOf())/1000;
                      if((frequency) > (parseInt(alert.frequency) * 60)) {
                        frequency = parseInt(alert.frequency)
                      }
                    }
                    refreshTimeLeft = Math.abs((parseInt(alert.frequency) * 60) - Math.ceil((alert.AlertHistory && alert.AlertHistory.length ? frequency : 0)));
                    refreshTimeLeftUp =  frequency;
                  } else {
                    if(time < alert.startTime) {
                      refreshTimeLeft = Math.abs((alert.startTime - time)/1000);
                    }else if(time > alert.endTime) {
                      refreshTimeLeft = Math.abs((endTime + Math.ceil(parseInt(alert.startTime)))/1000);
                    }

                  }

                   refreshTimeLeftUp =refreshTimeLeft
                  return(
                    <div className="customGrid" key={key}>
                      <Card key={key} className={`alertCard ${alert.alertStatus === true ? 'borderRed': 'borderGreen'}`}>
                        <Card.Content>
                          <h3>{alert.name} </h3>
                          <label>DESCRIPTION</label>
                          <p>{this.trimString(alert.description, 30, false)} {(alert.description && alert.description.length >30) && <Popup
                            content={alert.description}
                            on='click'
                            position='left center'
                            pinned
                            trigger={<a>more</a>}
                          />}</p>
                          <label>TRIGGERED PRACTICE</label>
                          <p>{this.trimString(triggedPractices,  30, false)}{(triggedPractices && triggedPractices.length >30) &&<Popup
                            content={triggedPractices}
                            on='click'
                            position='left center'
                            pinned
                            trigger={<a>more</a>}
                          />}</p>

                          <label>LAST RUN DATE/TIME</label>
                          <p>{alert.AlertHistory && alert.AlertHistory.length ? alert.lastRunAtTimestamp !== null ?  moment( alert.lastRunAtTimestamp ).format('MM/DD/YYYY hh:mm A'): '':''}</p>
                          <label>TRIGGERED DATE/TIME</label>
                          <p>{alert.AlertHistory && alert.AlertHistory.length ? alert.AlertHistory[0].createdAtTimestamp !== null ?  moment(alert.AlertHistory[0].createdAtTimestamp).format('MM/DD/YYYY hh:mm A')  : '':''}</p>
                          <Grid>
                            <Grid.Row>
                              <Grid.Column width={6}>
                                <label>DATA</label>
                                <p><a href="#" onClick={()=>{
                                  this.openRecentModal(
                                    alert.AlertHistory&&alert.AlertHistory[0].history&&this.IsJsonString(alert.AlertHistory[0].history) && alert.AlertHistory.length ? JSON.parse(alert.AlertHistory[0].history): [],
                                  `${alert.name} -- ${alert.AlertHistory && alert.AlertHistory.length ? alert.AlertHistory[0].createdAtTimestamp !== null ?
                                    moment(alert.AlertHistory[0].createdAtTimestamp).format('MM/DD/YYYY hh:mm A'): '':''}`,
                                    alert.AlertHistory && alert.AlertHistory.length ? alert.AlertHistory[0].id: 0,
                                    isCallCentreAlert, alert.isCdpDbAlert,
                                  `${alert.name}`,`${alert.id}`,)
                                }}> click here</a></p>
                              </Grid.Column>
                              <Grid.Column width={10}>
                                <label className='red-text'>Triggered (last 7 days)</label>
                                <p className='red-text'>{this.getAlertCounts(alert.id)}</p>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Card.Content>
                        <Card.Content extra>
                          <Grid>
                            <Grid.Row columns={3}>
                              <Grid.Column className="text-center">
                                <Icon name="wrench" className='cursorPointer' onClick={(e)=>{this.openPopUpModal(e, {howToFix: alert.howToFix, fileLinks: alert.fileLinks}, 'How to Fix')}}/>
                              </Grid.Column>
                              <Grid.Column className="text-center">
                                <Icon name='bell' className='cursorPointer' onClick={(e)=>{this.openPopUpModal(e, alert.notification, 'Notification')}}/>
                              </Grid.Column>
                              <Grid.Column className="text-center">
                                <Icon name='history' className='cursorPointer' onClick={(e)=>{this.fetchAlertHistory(e, alert.id, `Alert History of ${alert.name}`)}}/>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Card.Content>
                        <Card.Content>
                          <Grid>
                            <Grid.Row columns={2}>
                              <Grid.Column>
                            <Button primary size="small" onClick={()=>{
                              this.testAlertQuery({
                                query:alert.query,
                                queryTab32:alert.queryTab32,
                                practice:alert.practice,
                                alertId: alert.id,
                                isCallCentreAlert:alert.isCallCentreAlert,
                                failure: alert.failure,
                                name: alert.name,
                                department:alert.department,
                                notification: alert.notification,
                                emailToRegionalManager: alert.emailToRegionalManager,
                                emailToPracticeManager: alert.emailToPracticeManager,
                                alertStatus: alert.alertStatus,
                                isOtherDB:alert.isOtherDB,
                                isCdpDbAlert:alert.isCdpDbAlert,
                              })}}>
                              Rerun
                            </Button>
                              </Grid.Column>
                              <Grid.Column className="alertText">
                                <label>NEXT Alert</label>
                                <Timer name={`refreshTime1-${alert.id}`} alertId={alert.id} refreshTimeLeftUp={refreshTimeLeftUp} refreshTimeLeft={refreshTimeLeft} refreshTimerEnd={() => this.refreshTimerEnd(alert)} />
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Card.Content>
                      </Card>
                    </div>
                  )
                })
              }
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {(!isLoad && showPopUp)&&(patientValidationStatus&&patientValidationStatus.length!==0) && <PopUpModal viewAllNoErrorsStatus={viewAllNoErrorsStatus} viewAllStatus={viewAllStatus}
                                                 viewAll={this.viewAll} alertName={alertName}  isCdpDbAlert={isCdpDbAlert}
                                                 alertId={alertId} isCallCentreAlert={isCallCentreAlert}
                                                 closeModal={this.closePopUpModal} data={popUpData}
                                                 callCentreData = {userCallCentreTriggered}
                                                 popUpHeader={popUpHeader}
                                                 sendEmail={this.sendEmail}
                                                 carrierList={carrierList}
                                                 practices={practices}
                                                 practiceList= { practiceList}
                                                 filters = { filters}
                                                 practicesOTD={practiceOTDList}
                                                 patientValidationStatus={patientValidationStatus}
                                                 carrierPrimaryList={carrierPrimaryList}
          />}

{(!isLoad && showPopUpCallCentre) && <PopUpModalCallCentre viewAllNoErrorsStatus={viewAllNoErrorsStatus} viewAllStatus={viewAllStatus}
                                                 viewAll={this.viewAll} alertName={alertName}
                                                 alertId={alertId} isCallCentreAlert={isCallCentreAlert}
                                                 closeModal={this.closePopUpModal} data={popUpData}
                                                 callCentreData = {userCallCentreTriggered}
                                                 popUpHeader={popUpHeader}
                                                 sendEmail={this.sendEmail}
                                                 carrierList={carrierList}
                                                 practices={practices}
                                                 practiceList= { practiceList}
                                                 filters = { filters}
                                                 practicesOTD={practiceOTDList}
                                                 patientValidationStatus={patientValidationStatus}
                                                 carrierPrimaryList={carrierPrimaryList}
          />}
        </div>
    </Template>
    );
  }
}
