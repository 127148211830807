import * as React from 'react';
import {Modal,Table} from "semantic-ui-react";
import {OpenIssuesActions} from '../../actions';
import {RouteComponentProps} from "react-router";
import {RootState} from "../../reducers";
const moment = require('moment');

export namespace OpenIssuesModal {
  export interface Props extends RouteComponentProps<void> {
    auth: RootState.AuthState,
    actions: OpenIssuesActions,
    hideOpenIssuesModal: any,
    openIssues: []
  }
}

export class OpenIssuesModal extends React.Component<OpenIssuesModal.Props, any> {
  static defaultProps: Partial<OpenIssuesModal.Props> = {};
  constructor(props: OpenIssuesModal.Props, context?: any) {
    super(props, context);
  }
  render (){
    const { hideOpenIssuesModal, openIssues } = this.props;

    return(
      <Modal open={true}
             onClose={hideOpenIssuesModal}
             centered={false}
             size='large'
             closeIcon>
        <Modal.Content>
          <h3 className='text-center mb20'>App Status Tracker</h3>
          <div className="table-responsive FreezeTable">
            <Table sortable striped size='small' unstackable className="customTable">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell content="Application"/>
                  <Table.HeaderCell content="Down Time Start"/>
                  <Table.HeaderCell content="What Is Down"/>
                  <Table.HeaderCell content="What to do in mean time"/>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {
                  openIssues.length > 0 ?
                    openIssues.map((item: any, key) =>
                      <Table.Row key={'data_' + key}>

                        {
                          <Table.Cell>{item.application}</Table.Cell>
                        }
                        {
                          <Table.Cell>{moment(item.downTimeStart).format('D MMMM YYYY HH:mm')}</Table.Cell>
                        }

                        {
                          <Table.Cell>{item.whatIsDown}</Table.Cell>
                        }
                        {
                          <Table.Cell>{item.whatToDoMeanTime}</Table.Cell>
                        }
                      </Table.Row>

                    ) :
                    <Table.Row>
                      <Table.Cell colSpan={13} className="text-center" content="No Data"/>
                    </Table.Row>
                }
              </Table.Body>
            </Table>
          </div>
        </Modal.Content>

      </Modal>
    )
  }
}
