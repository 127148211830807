import * as React from 'react';
import { Grid, Modal, Table, Divider, Accordion, Icon, Button,Popup } from 'semantic-ui-react';
import { ViewFileModal } from '../../components/Setup/viewFormModal';
import { ViewNotesModal } from '../../components/Setup/ViewNotesModal';
import { ViewPDFModal } from '../../components/Setup/ViewPDFModal';
import '../../styles/css/xray-viewer.css';
import Viewer from 'react-viewer';
import {RouteComponentProps} from "react-router";
import {RootState} from "../../reducers";
import {AlertActions} from "../../actions";
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import {omit} from "../../utils";
import Loading from "../../components/Loading";
import { momentUtc, primaryCategories } from '../../const';
import * as ReactDOMServer from 'react-dom/server';
// import { pickBy } from 'lodash';


export namespace PopUpModal {
  export interface Props extends RouteComponentProps<void> {
    actions: AlertActions;  }

  export interface Props{
    data: [] | any ,
    callCentreData: [] | any;
    filters:any,
    practiceList:any[];
    regionList:any[];
    popUpHeader: string,
    closeModal:()=>void,
    sendEmail:()=>void,
    practices: any[],
    regions: any[],
    practicesOTD: any[],
    alertId:number,
    isCallCentreAlert:number,
    isCdpDbAlert : number,
    alertName:string,
    viewAll:()=>void,
    viewAllStatus:boolean,
    viewAllNoErrorsStatus:boolean,
    carrierList:any[],
    carrierPrimaryList:[],
    patientValidationStatus:[],
    openXrayViewerModal:boolean,
    patientNumber:number,
    practice:string,
    xrayViewerData:any,
    key:any,
    visible: boolean,
    selected: any,
    images: any [],
    xrayFound: boolean,
  }
}
@(connect(
  (state: RootState): Pick<PopUpModal.Props, any> => {
    return { alerts: state.alerts, auth: state.auth, usages: state.usages };
  },
  (dispatch: Dispatch): Pick<PopUpModal.Props, 'actions'> => ({
    actions: bindActionCreators(omit(AlertActions, 'Type'), dispatch)
  })
) as any)

export class PopUpModal extends React.Component<PopUpModal.Props, any> {
  static defaultProps: Partial<PopUpModal.Props> = {

  };
  medataDataId = 'xray_metadata_viewer';
  constructor(props: PopUpModal.Props, context?: any) {
    super(props, context);
    this.state = {
      activeIndex: 0,
      openFileModal: false,
      openNotesModal:false,
      files:'',
      dataNotes:'',
      collapse: true,
      isCallCentreAlert:this.props.isCallCentreAlert,
      callCentreData:[],
      alertId:this.props.alertId,
      alertName:this.props.alertName,
      loader:false,
      pdfState:{pdfModal:false, pdfLinks:'', pdfLinksNotFound:false},
      openXrayViewerModal:false,
      practice:'',
      patientNumber:0,
      xrayViewerData:[],
      key:null,
      visible: false,
      selected: null,
      images: [],
      xrayFound: true,
    }
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex })
  };

  openFilesModal = (files)=>{
    this.setState({openFileModal: true, files: files.split(',')});
  };
  openNotesModal = (data:any)=>{
    this.setState({openNotesModal: true, dataNotes: data});
  };

  closeFilesModal = () => {
    this.setState({ openFileModal: false, files: []});
  };
  closeNotesModal = () => {
    this.setState({ openNotesModal: false, dataNotes: ''});
  };
  closePdfModal = () => {
    this.setState({pdfModal:false, pdfLinks:'', pdfLinksNotFound:false});
  };

  collapseNoDataItems = ()=> {
    this.setState({collapse: !this.state.collapse});
  };

  getBrand = (practiceList,key) => {

       if(practiceList.find(p => p.practice == key)) {
         return practiceList.find(p => p.practice == key).brand
       }else{
         console.log(key);
         return '';
       }
  }

  practiceArray = (practices: any) =>{
    let arr:any = [];
    practices.forEach(prElement => {
      arr.push(prElement.value);
    });
    return arr;
  }

  getPDFfiles=(carrierName: any, subscriberIDNew: any, filterDate: any) => {

    let obj:any = {
      carrierName:carrierName,
      subscriberID:subscriberIDNew,
      date:filterDate
    }

    const pdfModal:any  = true;
    this.setState({loader:true});
    (this.props.actions.getPdfFile(obj) as any).then((res:any) => {

      if(res && res.url && res.url.Body && res.url.Body.data){

        const fileData = new Uint8Array (res.url.Body.data);
        const file = new Blob(
          [fileData],
          {type: 'application/pdf'});
        const pdfLinks = URL.createObjectURL(file);
        this.setState({pdfModal:pdfModal, pdfLinks:pdfLinks,loader:false });
      } else {

        this.setState({pdfModal:pdfModal, pdfLinksNotFound:true,loader:false });
      }
    })
  }


  getMetaData = () => {
    if (!this.state.selected || !(this.state.selected&&this.state.selected.metadata)) return null;
    const metadata = this.state.selected.metadata;
    const list = Object.keys(metadata)
      .filter(m => !(m === 'full_name' || m === 'type'))
      .sort()
      .map((key) => {
        const name = key.replace('_', ' ').toLocaleUpperCase();
        return (<span className="col p-1"><small>{name}</small>:<br/> <b style={{ marginLeft: '.2em' }}>{metadata[key]}</b></span>)
      });
    return <p id={this.medataDataId} className="row xray-metadata-viewer">{list}</p>
  }

  onViewXrays = (practice:string,patNum:number) => {
    if(practice&&patNum){
      this.setState({loader:true,patientNumber:patNum,xrayFound: true});
      const patientNumber = Array(Math.max(5 - String(patNum).length + 1, 0)).join('0')
        + patNum;
      (this.props.actions.loadList('ndp-images-and-xrays', `${practice}/${patientNumber}/xrays`) as any
      ).then(images => {
        this.setState({ images: images.reverse(), loader: false  });
        if (this.state.images.length === 0){
          this.setState({ xrayFound: false });
          return
        }
        this.setState({ visible: true, selected: this.state.images[0],xrayFound: true});
        this.updateMetadata();
      });

    }

  }


  updateMetadata = () => {
    setTimeout(() => {
      let element = document.getElementById(this.medataDataId);
      if (element) element&&element.parentNode&&element.parentNode.removeChild(element);
      const metadata = this.getMetaData();
      if (!metadata) return;
      const html = ReactDOMServer.renderToStaticMarkup(metadata);
      document.body.insertAdjacentHTML('beforeend', html);
    }, 200);
  }



  closeViewer = () => {
    this.setState({ visible: false, selected: null });
    this.updateMetadata();
    window.history.replaceState(null, "", ' ');
  }

  imageViewerChange = (selected, i) => {
    this.setState({ selected });
    this.updateMetadata();
  }

  applyFilterAlertHistory(data: any,filters:any,practiceList:any) {
    if(filters && Object.keys(filters).length ) {
      Object.keys(filters).forEach( filter => {
        switch (filter) {
          case 'practice':
              if(filters[filter][0]!== 'all' && filters[filter][0]!== 0 && filters[filter]) {
                data = Object.keys(data)
                .filter(key => filters[filter].includes(key))
                .reduce((obj, key) => {
                  obj[key] = data[key];
                  return obj;
                }, {});
              }
            break;
          case 'brand':
              data = Object.keys(data)
                .filter(key => this.practiceArray(practiceList).includes(key))
                .reduce((obj, key) => {
                  obj[key] = data[key];
                  return obj;
                }, {});

              break;
          default:
            break;
        }
      });

    }
    return data;
  }

  queryResult = (data,alertName)=> {
    const item:JSX.Element[]=[];
    const itemEmpty:JSX.Element[]=[];
    let count:number = 0;
    const {practices,practicesOTD, carrierList,carrierPrimaryList,patientValidationStatus} = this.props;
    let practicesDBName = {};
    practices && practices.forEach((item: any) => {
      if(item.practice){
      const practiceName = item.practice.toLowerCase();
      const practiceDbNameNotSame = item.practiceDBName;
      if(item.practiceDBNameSame == 1){
        practicesDBName[practiceDbNameNotSame] = item.practiceDBName;
      }else{
        practicesDBName[practiceName] = item.practiceDBName;
      }
    }

    });
    let practicesDBNameOTD = {};
    practicesOTD && practicesOTD.forEach((item: any) => {
      const practiceName = item.practice.toLowerCase();
      practicesDBNameOTD[practiceName] = item.practiceDBName;
    });

    if (data && typeof data === 'object' && Object.keys(data).length > 0) {
      for (const keys in data) {
        if (data.hasOwnProperty(keys)) {
          const practiceName: string = practicesDBNameOTD[keys.toLowerCase()] ? practicesDBNameOTD[keys.toLowerCase()] : (practicesDBName[keys.toLowerCase()] ? practicesDBName[keys.toLowerCase()] : keys);
        if (data[keys] && data[keys].name) {
          item.push(<div><h2>Error in {practiceName} practice.</h2><p>Error Type :- {data[keys].name}</p><p>Error Message
            :- {data[keys].message}</p></div>);
        } else if (data[keys] && data[keys].length) {
          let finalKeyData = [];

          if(alertName === 'Billing in a Box -FDH' || alertName === 'Billing In a Box - Recare' || alertName === 'Billing in a box - Comprehensive'){

              let values:any = [];
              const aapdFilter = data[keys].reduce((a, e) => {
                a[e.PatNum] = ++a[e.PatNum] || 0;
                return a;
              }, {});
              let multipleAAPD = data[keys].filter(e => aapdFilter[e.PatNum]);
              data[keys].map((value, key) => {
                if(value.XPos || value.YPos || value.YPos === null || value.DateTimeSheet === null )
                {
                  const statusDetails = patientValidationStatus[practiceName.toLowerCase()];
                  //'Can\'t Find Member. Check Subscriber ID or Birthdate'
                  value.status = `NO IMAGE`;
                  if (value.SubscriberID && statusDetails && statusDetails.length > 0) {
                    let isStatusExists:any = statusDetails.find((statusItem: any) => statusItem.subscriberId === value.SubscriberID)
                    if (isStatusExists){

                      if (isStatusExists.eligible == 1) {
                        value.status = `OK`;
                        // PT  Have Active Insurance
                      }else if(isStatusExists.eligible==2){
                        value.status = `RED`;
                        // PT Does NOT Have Active Insurance

                      } else {
                        value.status = `NO`;
                        // PT Does NOT Have Active Insurance
                      }
                    }
                  }

                  const carName =value&&value['CarrierName']?value['CarrierName'].toLowerCase().trim():'';
                  const primaryName = primaryCategories[carrierPrimaryList[carName]];
                  let notes:any = value.Note ? value.Note : value.note ? value.note :'';
                  let DrNotes:any = '';
                  const LastName:any = value['Dr. Name'].toLowerCase();
                  const SigningDr:any =value['Signing Dr']? value['Signing Dr'].toLowerCase():'';
                  const SigningDrLastNameCheck:any = SigningDr !== '' ? SigningDr.slice(SigningDr.lastIndexOf(' ') + 1) : ''
                  const checkLast = LastName.slice(LastName.lastIndexOf(' ') + 1)
                  const checkAbbr = value['abbr']? value['abbr'].toLowerCase():'';
                  let notesMatchSmall:any = notes.toLowerCase();
                  const FDH:any =notesMatchSmall.includes("first dental home") || notesMatchSmall.includes("fdh")
                  const Comprehensive = notesMatchSmall.includes("comprehensive");
                  const RecareOrRecall = notesMatchSmall.includes("recare") || notesMatchSmall.includes("recall");
                  const Varnish:any = notesMatchSmall.includes("varnish");
                  const Prophy:any = notesMatchSmall.includes("prophy");
                  const DrLastName:any = notesMatchSmall.includes(checkLast) || (SigningDrLastNameCheck != '' && notesMatchSmall.includes(SigningDrLastNameCheck))||(checkAbbr != '' && checkAbbr.includes(checkLast));
                  if (!FDH&& !(alertName === 'Billing In a Box - Recare'||alertName === 'Billing in a box - Comprehensive')) {
                    DrNotes += 'No FDH';
                  }
                  if (!Comprehensive && (alertName === 'Billing in a box - Comprehensive')) {
                    DrNotes += ' No Comprehensive';
                  }
                  if (!RecareOrRecall && (alertName === 'Billing In a Box - Recare')) {
                    DrNotes += ' No Recare';
                  }
                  if (!Varnish) {
                    DrNotes += ' No Varnish';
                  }
                  if (!Prophy) {
                    DrNotes += ' No Prophy';
                  }
                  if (!DrLastName) {
                    DrNotes += ' Issue in DR. Last Name';
                  }
                  if (FDH&& Varnish && Prophy && DrLastName) {
                    DrNotes = 'OK';
                  }

                  if (DrNotes === 'OK') {
                    DrNotes = 'OK';
                  }
                  let AAPDCheck:any;
                  let AAPDStatus:any;
                  if(value.XPos === 53 || value.XPos === 52){
                    AAPDCheck='D0603';
                  } else if(value.YPos === 1039){
                    AAPDCheck='D0601';
                  } else {
                    AAPDCheck='OTHER';
                  }

                  const dataProcode = value.ProcCode ? (value.ProcCode).split(','): [];
                  const checkAPPD = dataProcode.filter(item => item === AAPDCheck);
                  const carrierIdenty:any = primaryName ? primaryName :(alertName === 'Billing in a box - Comprehensive'||alertName === 'Billing In a Box - Recare')? "PPO" : "Issue In Identity "
                  const finalData = { 'ProcDate':value['ProcDate'],
                    'PatNum':value['PatNum'],
                    'ProcCode':value['ProcCode'],
                    'Dr. Name':value['Dr. Name'],
                    'CarrierName':value['CarrierName'],
                    'SubscriberID':value['SubscriberID'] ? value['SubscriberID']:'',
                    'Carrier Identity':(`${carrierIdenty}`.toLowerCase().includes('ppo')||`${carrierIdenty}`.toLowerCase().includes('issue in identity'))?<label className="red-text" >{carrierIdenty}</label>:carrierIdenty ,
                    'Dr Notes':DrNotes,
                    'AAPD':`${carrierIdenty}`.toLowerCase().includes('ppo')?'Not Needed':'NO AAPD',
                    'Insurance Verification':value.status,
                    'View Dr Notes':notes,
                    'Signing Dr':value['Signing Dr'],
                    'Abbr':value['abbr']||value['Abbr'],
                  };

                  if(value.YPos || value['DateTimeSheet']){
                    const sheetDateCheck =  momentUtc(value['DateTimeSheet']).format('YYYY-MM-DD');
                    const procDateCheck =  momentUtc(value['ProcDate']).format('YYYY-MM-DD');
                    const sheetValue = momentUtc(sheetDateCheck).valueOf();
                    const procValue = momentUtc(procDateCheck).valueOf();
                    const multipleAAPDCheck:any = multipleAAPD.filter(e => e.PatNum === value['PatNum']);
                    if(sheetValue === procValue ){
                      {multipleAAPDCheck.length !== 0 ? AAPDStatus = 'Multiple AAPD' : checkAPPD.length > 0 ?
                        AAPDStatus = 'OK' : AAPDStatus = 'Wrong code'
                      }
                    } else {
                      AAPDStatus = `${finalData['Carrier Identity']}`.toLowerCase().includes('ppo')?'Not Needed':'NO AAPD';
                    }
                    Object.assign(finalData,{AAPD:AAPDStatus});
                  }
                  values.push(finalData);
                  values = values.filter((v,i,a)=>a.findIndex(t=>(t.PatNum === v.PatNum ))===i);
                }
              });
              finalKeyData = values;
          } else {
              finalKeyData = data[keys]
          }

          let dataValuesAll:any = finalKeyData;
          let dataValuesNo:any = finalKeyData.filter(item => !(item['AAPD'] === 'OK' &&
            item['Dr Notes'] === 'OK' && item['Insurance Verification'] === 'OK'));
          let dataValuesYes:any = finalKeyData.filter(item => (item['AAPD'] === 'OK' &&
            item['Dr Notes'] === 'OK' && item['Insurance Verification'] === 'OK'));
          const finalData:any = this.props.viewAllStatus ? dataValuesNo : this.props.viewAllNoErrorsStatus ? dataValuesYes : dataValuesAll ;
           count += this.props.viewAllStatus ? dataValuesNo.length : this.props.viewAllNoErrorsStatus ? dataValuesYes.length : dataValuesAll.length;

          { finalData && finalData.length > 0 ? item.push(<div key={keys}><h4>{keys}</h4>
            {(finalData && finalData.length) &&
          <div className="table-responsive">
            <Table unstackable className="customTable">
              <Table.Header>
                <Table.Row>
                  {
                    finalData && finalData[0] && Object.keys(finalData[0]).map(fieldName => {
                      if(fieldName === 'SubscriberID'){
                        return null;
                      }else{
                        return (
                          <>
                            <Table.HeaderCell>
                              <div>{fieldName}</div>
                            </Table.HeaderCell>
                            {alertName === 'Pano D0330 with to OS or Ortho Referral' &&fieldName === 'PatNum' &&
                              <Table.HeaderCell>
                                <div>Xray</div>
                              </Table.HeaderCell>
                            }
                          </>
                        )
                      }

                    })
                  }
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {finalData.map((value, key) => {
                //  let patNumValue = value['PatNum'];
                  let procDateValue = value['ProcDate'];
                  let subscriberID = value['SubscriberID'];
                  let carrierName = value['CarrierName'];
                  let isCarrierExist = carrierName && carrierList && carrierList.find(data => data.carrierName.toLowerCase().trim() === carrierName.toLowerCase().trim());

                  return (
                    <Table.Row key={key}>
                      {Object.keys(value).map(fieldName => {
                        let item = value[fieldName];
                          if(fieldName === 'SubscriberID'){
                            return null;
                          }else {
                            const dateExp = /^(\d{4})-(\d{1,2})-(\d{1,2})T00:00:00.000Z$/
                            if (fieldName.toLowerCase().includes('date') || dateExp.test(item)) {
                              item = momentUtc(item).format('YYYY-MM-DD');
                            }
                            // if () {
                            //   item = new Date(item).toLocaleDateString('en-CA');
                            // }
                            if (fieldName === 'Insurance Verification') {
                              return <Table.Cell>
                                {fieldName === 'Insurance Verification' && item === 'RED' ?
                                  <Icon name='font' color='red'
                                          onClick={() => this.getPDFfiles(isCarrierExist.modifyCarrierName, subscriberID, procDateValue)}/>
                                  : item === 'OK' ? <Icon name='check circle' color='green'
                                                                            onClick={() => this.getPDFfiles(isCarrierExist.modifyCarrierName, subscriberID, procDateValue)} /> :
                                    fieldName === 'Insurance Verification' && item === 'NO'?<Icon name='delete' color='red'
                                                                                                  onClick={() => this.getPDFfiles(isCarrierExist.modifyCarrierName, subscriberID, procDateValue)} />

                                       : fieldName === 'Insurance Verification' && item === 'NO IMAGE' ?
                                      <label className='red-text'>No Image Found</label> :<Icon name='question circle' color='yellow'/>
                                }
                              </Table.Cell>

                            } else if (fieldName === 'View Dr Notes') {
                              return <Table.Cell>
                                {fieldName === 'View Dr Notes' && item ?
                                  <Button primary onClick={() => this.openNotesModal(item)}>Dr Notes</Button> :
                                  <label className='red-text'>No Notes</label>}
                              </Table.Cell>

                            } else if (fieldName === 'AAPD' || fieldName === 'Dr Notes') {
                              return <Table.Cell>
                                {(item === 'OK')? item : <label className='red-text'>{item}</label>}
                              </Table.Cell>

                            }else if (alertName === 'Pano D0330 with to OS or Ortho Referral' && fieldName === 'PatNum'){

                               return  (
                                 <>
                                   <Table.Cell>
                                     {item}
                                   </Table.Cell>
                                   <Table.Cell>
                                     <p className='primaryColor cursorPointer mt-2' onClick={() => this.onViewXrays(practiceName,item)}>
                                       <a href='#xray-open'>
                                         {this.state.xrayFound || this.state.patientNumber !== item ? 'View Xrays' : 'No xray found for this patient'}
                                       </a></p>
                                   </Table.Cell>
                                 </>
                               );


                            } else if (fieldName === 'CarrierName') {
                              return <Table.Cell>
                                {fieldName === 'CarrierName' ? carrierName : ''} </Table.Cell>
                            } else if (alertName === 'Provider ABBR First and Last Name' && (fieldName === 'LName' || fieldName === 'FName')&& typeof item === 'object') {
                              return <Table.Cell> <label
                                className={item['property']!== undefined ? item['property'] : ''}>{item['text']!==undefined ? item['text'] : item.toString()}</label></Table.Cell>
                            } else if (fieldName === 'StateLicense' &&alertName === 'Provider State License') {
                              return <Table.Cell ><label className='red-text'>{item}</label> </Table.Cell>
                            }else if (alertName === 'Provider With Care but not on Schedule' && fieldName === 'PatNum') {
                              const displayText = item.length > 12 ? `${item.substring(0, 12)}...` : item;
                              return (
                                <Table.Cell>
                                  <Popup content={item} trigger={<span>{displayText}</span>} />
                                </Table.Cell>
                              );
                            } else {

                              return <Table.Cell>{item}</Table.Cell>
                            }

                          }
                        }
                        )}

                      {

                      }

                    </Table.Row>
                  )
                })
                }
              </Table.Body></Table><Divider/></div>}</div>) : ''}
        } else {
          itemEmpty.push(<div key={keys}><h4>{practiceName}</h4><p>No Result Found...</p><Divider/></div>);
        }
      }
      }
      return {item, itemEmpty, count};
    } else {

      item.push(<div key ='1'>{data}</div>);
      return {item, itemEmpty,count};
    }
  };


  sendEmail=()=>{
    this.props.sendEmail();
  };
  viewAll=()=>{
    this.props.viewAll();
  };


  triggeredStatus=(data)=>{
    let status = false;
    for (const keys in data) {
      if(data.hasOwnProperty(keys) && data[keys].length && !data[keys].name) {
        status= true;
      }
    }
      return (status? <span className="red-text"> -- Triggered</span> : '');
  };


  render() {
    const { data, filters,practices,closeModal, popUpHeader,alertName,viewAllStatus,viewAllNoErrorsStatus,isCallCentreAlert, isCdpDbAlert } = this.props;
    const { activeIndex, openFileModal, openNotesModal,dataNotes, files, collapse, pdfModal, pdfLinks,
      pdfLinksNotFound,loader } = this.state;

    let item: JSX.Element[] = [];
    let itemEmpty: JSX.Element[] = [];
    let queryResult :any = {count:0};
    let sendEmail;
    let viewAll:any = [];
if (popUpHeader === 'Query Result' || popUpHeader.indexOf('--')>=0 || popUpHeader=== 'Notification' || popUpHeader=== 'Query' || popUpHeader=== 'Description'||popUpHeader.toLowerCase().indexOf('missing values')>=0||popUpHeader.toLowerCase().includes('bcare and pay')||popUpHeader.toLowerCase().includes('indexing')) {

          if(data && Object.keys(data).length > 0 ){
                   let dataFiltered = isCdpDbAlert||popUpHeader.toLowerCase().includes('provider with care on the same day in more than on') || popUpHeader.toLowerCase().includes('missing values')||popUpHeader.toLowerCase().includes('bcare and pay')|| popUpHeader.toLowerCase().includes('open dental indexing')? data: this.applyFilterAlertHistory(data,filters,practices);

                     if(dataFiltered && Object.keys(dataFiltered).length > 0){

                       queryResult = this.queryResult(dataFiltered,alertName);
                       item = queryResult && queryResult.item;
                       itemEmpty = queryResult && queryResult.itemEmpty;
                       viewAll= alertName === 'Billing in a Box -FDH' ? viewAllStatus ? <Button primary className='float-right' onClick={this.viewAll}>Error</Button>: viewAllNoErrorsStatus ? <Button primary className='float-right' onClick={this.viewAll}>No Error</Button> : <Button primary className='float-right' onClick={this.viewAll}>View all</Button> :'';
                       sendEmail=(popUpHeader.indexOf('--')>=0)? <Button primary className='float-right' onClick={this.sendEmail}>Send Email</Button>:'';

                     }else{

                       itemEmpty.push(<div><p>No Result Found...</p></div>);

                     }
           }else{
                     itemEmpty.push(<div><p>No Result Found...</p></div>);
          }
   } else if (popUpHeader.indexOf('Alert History')>= 0 && isCallCentreAlert==0) {

      data.map(alert => {

        let dataFiltered = this.applyFilterAlertHistory(JSON.parse(alert.history),filters,practices);

        queryResult = this.queryResult(dataFiltered,alertName);
        let alertHistory: JSX.Element[] = queryResult && queryResult.item;

        itemEmpty = queryResult && queryResult.itemEmpty;
        item.push(
          <Accordion styled>
            <Accordion.Title
              active={activeIndex === alert.id}
              index={alert.id}
              onClick={this.handleClick}
            >
              <Icon name='dropdown'/>
              {momentUtc(alert.createdAt).format('MM/DD/YYYY hh:mm A')} {this.triggeredStatus(JSON.parse(alert.history)) || ''}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === alert.id}>
              {alertHistory}
            </Accordion.Content>
          </Accordion>)
      })
    } else if(popUpHeader === 'How to Fix' ){
      const videoFiles = data && data.fileLinks ? data.fileLinks.split(',') : [];
        item.push(<div><p>{data.howToFix}</p>
        <div className="cursorPointer">
          <div className="mb10">
            {videoFiles.length > 0 ? <span className="linkText">Watch Video</span> : <span>No Video found</span>}
          </div>
          <div className="clearfix"></div>
          {
            videoFiles.map((item: any) => {
              return (
                  <video className="videoThumbnail" onClick={() => this.openFilesModal(data.fileLinks)}>
                    <source src={item} type="video/mp4"/>
                    Your browser does not support the video tag.
                  </video>
              )
            })
          }
        </div></div>)
    } else {
      itemEmpty.push(<div><p>No Result Found...</p></div>);
    }
    return (
      <Modal
        open={true}
        onClose={closeModal}
        aria-labelledby="ModalHeader"
        centered
        closeIcon
        size="fullscreen"
        className="howToFix"
      >
        <Modal.Header>
          {popUpHeader}{<span  >    Count  :  {queryResult.count?queryResult.count:0}</span>} {sendEmail} {viewAll}
        </Modal.Header>
        <Modal.Content scrolling>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                {loader && <Loading />}
                {
                  item
                }
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {itemEmpty && itemEmpty.length > 0 && <Grid>
            <Grid.Row>
              <Grid.Column>
                <span className={'cursorPointer'} onClick={()=> this.collapseNoDataItems()}><Icon name={collapse ? 'caret right' : 'caret down'}/>
                  {collapse ? 'Show All Practices' : 'Hide Practices Below'}
                  </span>
              </Grid.Column>
            </Grid.Row>
            {!collapse && <Grid.Row className={'collapsed'}>
              <Grid.Column>
                {
                  itemEmpty
                }
              </Grid.Column>
            </Grid.Row> }
          </Grid> }
        </Modal.Content>

        { <Viewer
          images={this.state.images}
          defaultScale={1.8}
          onChange={this.imageViewerChange}
          onClose={this.closeViewer}
          visible={this.state.visible}
        />}
        {openFileModal && <ViewFileModal closeModal={this.closeFilesModal} files={files}/>}
        {openNotesModal && <ViewNotesModal closeModal={this.closeNotesModal} dataNotes={dataNotes}/> }
        {pdfModal && <ViewPDFModal pdfModal={pdfModal} pdfLinksNotFound={pdfLinksNotFound}
                                   pdfLinks={pdfLinks} closeModal={this.closePdfModal} /> }
      </Modal>
    );
  }
}
