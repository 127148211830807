import * as React from 'react';
import { Grid, Modal, Divider } from 'semantic-ui-react';

export namespace ViewFileModal {
  export interface Props{
    files: [] | any ,
    closeModal:()=>void
  }
}

export class ViewFileModal extends React.Component<ViewFileModal.Props, any> {
  static defaultProps: Partial<ViewFileModal.Props> = {

  };
  constructor(props: ViewFileModal.Props, context?: any) {
    super(props, context);

  }

  render() {
    const {files, closeModal} = this.props;
    return (
      <Modal
        open={true}
        onClose={closeModal}
        aria-labelledby="ModalHeader"
        centered
        closeIcon
        size="large"
      >
        <Modal.Header>
          Watch Video
        </Modal.Header>
        <Modal.Content scrolling>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                {
                  (files && files.length) ?
                    files.map((file:any, key:any) => {
                      return (<div key={key}>
                          <video className="helpVideo" controls>
                            <source src={file} type="video/mp4"/>
                            Your browser does not support the video tag.
                          </video>
                        <Divider />
                      </div>)
                    })
                    :
                    <div>No file found.</div>
                }
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}
